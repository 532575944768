import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { Grid } from "@mui/material";
import { TYPE_MOTORCYCLE } from "@/constants/index";
import MpiLabel from "@/components/Labels/MpiLabel";
import NumberOfDoorsComponentSelect from "@/FormComponents/SelectBoxes/NumberOfDoorsComponentSelect";
import SeatCountComponentRangeSlider from "@/FormComponents/RangeSlider/SeatCountComponentRangeSlider";

const SeatsDoorsVariant: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const hasMotorcycleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === "ty" && Array.isArray(value) && value[0] === TYPE_MOTORCYCLE);

  return (
    <>
      {!hasMotorcycleValue && (
        <Grid container spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <MpiLabel title="Sitze" />
            <SeatCountComponentRangeSlider {...searchParameters} />
          </Grid>

          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <MpiLabel title="Türen" />
            <NumberOfDoorsComponentSelect {...searchParameters} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SeatsDoorsVariant;
