import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_EMISSION_CATEGORY } from "@/constants/index";
import { SelectBoxElement } from "@/components/Forms/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { handleChangeSelectBoxValue } from "@/tools/tools";
import { ISelectData } from "@/interfaces/ISelectData";
import useStore from "@store";

const EmissionCategoryComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { facets } = useStore();
  const navigate = useNavigate();

  // Ensure searchObject is defined and has the required structure
  const searchObject = searchParameters.searchObject || ({} as ISelectData);
  const data = facets?.[FILTER_VEHICLE_EMISSION_CATEGORY];
  const searchParameter = searchObject[FILTER_VEHICLE_EMISSION_CATEGORY];

  return <SelectBoxElement name={FILTER_VEHICLE_EMISSION_CATEGORY} placeholder="Emissionsklasse wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, { ...searchParameters }, navigate)} />;
};

export default EmissionCategoryComponentSelect;
