import React, { useId } from "react";
import { handleChangeCheckBoxValue, setValue, convertDataToCheckBoxArray } from "@/tools/tools";
import { CheckboxElement } from "@/components/Forms/CheckboxElement";
import { Grid } from "@mui/material";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { ICheckboxProps } from "@/interfaces/ICheckboxProps";
import { FILTER_VEHICLE_WARRANTY } from "@/constants";
import useStore from "@store";

const WarrantyComponentCheckbox: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const id = useId();
  const { facets } = useStore();

  const data = facets?.[FILTER_VEHICLE_WARRANTY];
  const CheckBoxDataArray: ICheckboxProps[] = convertDataToCheckBoxArray(data, FILTER_VEHICLE_WARRANTY);

  // die Spaltenanzahl basierend auf der Bildschirmgröße

  return (
    <>
      {CheckBoxDataArray.map((checkBox, index) => (
        <Grid key={`${id}-${index}-${checkBox.name}`}>
          <CheckboxElement
            name={checkBox.name}
            checked={setValue(checkBox.label ?? "", checkBox.name ?? "", checkBox.data, searchParameters.searchObject)}
            onChange={(event) => {
              handleChangeCheckBoxValue(event, checkBox.label, checkBox.data, searchParameters.setSearchObject);
            }}
            type={checkBox.type}
            label={checkBox.label}
            data={checkBox.data}
            searchObject={searchParameters.searchObject}
          />
        </Grid>
      ))}
    </>
  );
};

export default WarrantyComponentCheckbox;
