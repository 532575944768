import React from "react";
import { Box, Button } from "@mui/material";
import { openNewWindow } from "../ExposeWindow";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";

const handleExposeeClick = (carData: any) => {
  openNewWindow(`${carData?.mm?.expose}&wltpLabelOnly=true`);
};

interface IButtonPKWLabel {
  carData: any;
}

const ButtonPKWLabel: React.FC<IButtonPKWLabel> = ({ carData }) => {
  return (
    <Button
      fullWidth
      variant="outlined"
      startIcon={<PrintOutlinedIcon />}
      onClick={() => {
        handleExposeeClick(carData);
      }}
      sx={{ "& .MuiButton-startIcon": { marginRight: { xs: "0px", sm: "8px" } } }}
    >
      <Box fontWeight={"bold"}>PKW-Label</Box>
    </Button>
  );
};

export default ButtonPKWLabel;
