import React from "react";
import IconButton from "@mui/material/IconButton";
import { Add, Remove } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";

const CircleIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  borderRadius: "50%",
  display: "inline-flex",
  verticalAlign: "sub",
  width: "22px",
  height: "22px",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark
  }
}));

interface IFilterMoreToggler {
  showAll: boolean;
  onToggle: (newShowAll: boolean) => void;
}

const FilterMoreToggler: React.FC<IFilterMoreToggler> = ({ showAll, onToggle }) => {
  const handleToggle = () => {
    onToggle(!showAll);
  };

  return (
    <Tooltip title={showAll ? "Zeige weniger" : "Zeige mehr"} arrow>
      <CircleIconButton onClick={handleToggle}>{showAll ? <Remove /> : <Add />}</CircleIconButton>
    </Tooltip>
  );
};

export default FilterMoreToggler;
