import React from "react";
import { createRoot } from "react-dom/client";
import ExposeeLoader from "./ExposeeLoader";
import { getBackend } from "../tools/backendApis";

export const openNewWindow = (vehicleExposee: string) => {
  const loaderWindow = window.open(getBackend(`${vehicleExposee}`), "_blank");
  loaderWindow.document.write("<html><head><title>Exposé</title></head><body>");
  loaderWindow.document.write('<div id="loader-root"></div></body></html>');
  loaderWindow.document.close();

  const root = createRoot(loaderWindow.document.getElementById("loader-root"));
  root.render(<ExposeeLoader cgvid={getBackend(`${vehicleExposee}`)} />);
};
