import React from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import { getListAttrMedium, isBike } from "@/tools/vehicleDataHandler";
import { shortingString } from "@/tools/tools";

interface Props {
  vehicle: IVehicleListItem;
}

const VehicleDataGridMedium: React.FC<Props> = ({ vehicle }) => {
  const theme = useTheme();
  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);

  if (customerConfiguration?.demo) {
    // im Demo-Modus Finanzierungsdaten setzen
    vehicle.pf = "239,- €";
    vehicle.pl = "139,- €";
  }

  const MAX_ITEMS = 3;
  const attributeData = getListAttrMedium(vehicle);

  const renderAttributeData = () =>
    attributeData.map(
      (item, index) =>
        item.value &&
        item.icon && (
          <Grid item xs={12} sm={4} key={index}>
            <Tooltip title={item.tooltip}>
              <Typography noWrap sx={{ display: "flex", p: 1, pl: 2 }}>
                {item.icon}
                {item.value}
              </Typography>
            </Tooltip>
          </Grid>
        )
    );

  const renderEquipmentHighlights = () => (
    <Grid container sx={{ mt: 0.5, mb: 1, alignItems: "center", justifyContent: "flex-start" }}>
      {vehicle.la && (
        <Typography
          sx={{
            bgcolor: theme.palette.grey[200],
            p: 0.5,
            pr: 1,
            mr: 1,
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            whiteSpace: "nowrap",
          }}
        >
          <CheckOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
          Leasing verfügbar
        </Typography>
      )}
      {vehicle.lEH &&
        vehicle.lEH.slice(0, MAX_ITEMS).map((element: string, index: number) => (
          <Typography
            sx={{
              bgcolor: theme.palette.grey[200],
              p: 0.5,
              pr: 1,
              mr: 1,
              mb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
            }}
            key={index}
          >
            <CheckOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
            {element}
          </Typography>
        ))}
    </Grid>
  );

  const renderDescription = () =>
    isBike(vehicle) &&
    vehicle.ds && (
      <Typography variant="subtitle1" component="div">
        {shortingString(vehicle.ds, 120)}
      </Typography>
    );

  return (
    <>
      <Grid container sx={{ mt: 0.5, bgcolor: theme.palette.grey[200] }}>
        {renderAttributeData()}
      </Grid>
      <Grid container sx={{ pt: 0.5, mb: 0.5 }}>
        {renderEquipmentHighlights()}
        {renderDescription()}
      </Grid>
    </>
  );
};

export default VehicleDataGridMedium;
