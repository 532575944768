// Bisher nur Europa drin
const countryCodes: { [key: string]: string } = {
  AL: "Albanien",
  AD: "Andorra",
  AM: "Armenien",
  AT: "Österreich",
  AZ: "Aserbaidschan",
  BY: "Belarus",
  BE: "Belgien",
  BA: "Bosnien und Herzegowina",
  BG: "Bulgarien",
  HR: "Kroatien",
  CY: "Zypern",
  CZ: "Tschechien",
  DK: "Dänemark",
  EE: "Estland",
  FI: "Finnland",
  FR: "Frankreich",
  GE: "Georgien",
  DE: "Deutschland",
  GR: "Griechenland",
  HU: "Ungarn",
  IS: "Island",
  IE: "Irland",
  IT: "Italien",
  KZ: "Kasachstan",
  XK: "Kosovo",
  LV: "Lettland",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MT: "Malta",
  MD: "Moldawien",
  MC: "Monaco",
  ME: "Montenegro",
  NL: "Niederlande",
  MK: "Nordmazedonien",
  NO: "Norwegen",
  PL: "Polen",
  PT: "Portugal",
  RO: "Rumänien",
  RU: "Russland",
  SM: "San Marino",
  RS: "Serbien",
  SK: "Slowakei",
  SI: "Slowenien",
  ES: "Spanien",
  SE: "Schweden",
  CH: "Schweiz",
  TR: "Türkei",
  UA: "Ukraine",
  GB: "Vereinigtes Königreich",
  VA: "Vatikanstadt"
};

export function getCountryByCode(code: string): string {
  const countryName = countryCodes[code.toUpperCase()];
  return countryName ? countryName : "";
}
