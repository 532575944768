import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { FILTER_VEHICLE_MODEL } from "../../redux/constants/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { SelectBoxElement } from "../../components/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { findBrandById, handleChangeSelectBoxValue } from "../../tools/tools";
import { IFacets } from "@Interface/IFacets";

const ModelComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const facets = useSelector((state: RootState) => state.ConfigurationReducer.facets as IFacets);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = facets?.[FILTER_VEHICLE_MODEL];
  const searchParameter = searchParameters.searchObject[FILTER_VEHICLE_MODEL];

  return <SelectBoxElement name={FILTER_VEHICLE_MODEL} placeholder="Modell wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, findBrandById(data, Number(id)), { ...searchParameters }, navigate, dispatch)} />;
};

export default ModelComponentSelect;
