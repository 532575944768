import React, { useEffect, useState } from "react";
import SearchButtonAreaComponent from "./SearchButtonAreaComponent";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MakeModelVariant from "./components/MakeModelVariantComponent";
import BasicDataComponent from "./components/BasicDataComponent";
import MotorComponent from "./components/MotorComponet";
import ExteriorColorComponent from "./components/ExteriorColorComponent";
import InsideColorComponent from "./components/InsideColorComponet";
import EnvironmentComponent from "./components/EnvironmentComponent";
import LocationComponent from "./components/LocationComponent";
import EquipmentComponent from "./components/EquipmentComponent";
import ScrollToTop from "../../components/ScrollToTop";
import OfferDetailsComponent from "./components/OfferDetailsComponent";
import { TYPE_MOTORCYCLE } from "../../redux/constants/index";
import BranchComponentSelect from "../../FormComponents/SelectBoxes/BranchComponentSelect";
import { Card, CardContent, CardHeader, Grid, Paper, Theme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { IFacets } from "@Interface/IFacets";
import VehicleConditionComponentCheckbox from "../../FormComponents/CheckBoxes/VehicleConditionComponentCheckbox";
import VehicleTypeComponentCheckbox from "../../FormComponents/CheckBoxes/VehicleTypeComponentCheckbox";
import VehicleBodyTypeComponentCheckbox from "../../FormComponents/CheckBoxes/VehicleBodyTypeComponentCheckbox";
import ManufacturerComponentCheckbox from "../../FormComponents/CheckBoxes/ManufacturerComponentCheckbox";
import ModelComponentCheckbox from "../../FormComponents/CheckBoxes/ModelComponentCheckbox";
import PriceComponentRangeSlider from "../../FormComponents/RangeSlider/PriceComponentRangeSlider";
import BikeColorComponentCheckbox from "../../FormComponents/CheckBoxes/BikeColorComponentCheckbox";
import BikeMotorPositionComponentCheckbox from "../../FormComponents/CheckBoxes/BikeMotorPositionComponentCheckbox";
import BikeBatteryPositionComponentCheckbox from "../../FormComponents/CheckBoxes/BikeBatteryPositionComponentCheckbox ";
import BikeBatteryCapacityWhComponentRangeSlider from "../../FormComponents/RangeSlider/BikeBatteryCapacityWhComponentRangeSlider";
import BikeBatteryManufacturerComponentCheckbox from "../../FormComponents/CheckBoxes/BikeBatteryManufacturerComponentCheckbox";
import BikeGearTypeComponentCheckbox from "../../FormComponents/CheckBoxes/BikeGearTypeComponentCheckbox";
import BikeFrameShapeComponentCheckbox from "../../FormComponents/CheckBoxes/BikeFrameShapeComponentCheckbox";
import BikeFrameMaterialComponentCheckbox from "../../FormComponents/CheckBoxes/BikeFrameMaterialComponentCheckbox";
import BikeWheelSizeComponentCheckbox from "../../FormComponents/CheckBoxes/BikeWheelSizeComponentCheckbox";
import BikeCategoryComponentCheckbox from "../../FormComponents/CheckBoxes/BikeCategoryComponentCheckbox";
import BikeNumberOfGearsComponentRangeSlider from "../../FormComponents/RangeSlider/BikeNumberOfGearsComponentRangeSlider";
import BikeWeightComponentRangeSlider from "../../FormComponents/RangeSlider/BikeWeightComponentRangeSlider";
import { useTranslation } from "react-i18next";
import BikeFrameHeightComponentRangeSlider from "@/FormComponents/RangeSlider/BikeFrameHeightComponentRangeSlider";

const EnlargedSearch: React.FC<ISearchParametersProps> = (searchParameters) => {
  const [containerWidth, setContainerWidth] = useState("100%");
  const [marginLeft, setMarginLeft] = useState("0");

  const facets = useSelector((state: RootState) => state.ConfigurationReducer.facets as IFacets);

  const hasMotorcycleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === "ty" && Array.isArray(value) && value[0] === TYPE_MOTORCYCLE);
  // Unterscheidung für VehicleType
  // mindesten ein BIKE vorhanden dann true

  let hasBike, hasPKW, hasLKW;
  if (facets?.ty) {
    const types = Object.keys(facets.ty);
    hasBike = types.some((typ) => typ.includes("e-Bike"));
    hasPKW = types.some((typ) => typ.includes("PKW"));
    hasLKW = types.some((typ) => typ.includes("LKW"));
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setContainerWidth("110%");
        setMarginLeft("-5%");
      } else {
        setContainerWidth("100%");
        setMarginLeft("0");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const containerStyle = (theme: Theme) => ({
    position: "sticky",
    margin: "auto",
    maxWidth: "110%",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
    display: "block",
    marginLeft: marginLeft,
    transition: "width 0.3s ease-in-out, margin-left 0.3s ease-in-out",
    zIndex: 1000,
    width: containerWidth,
    mb: 8,
    p: 2,

    [theme.breakpoints.up("xs")]: {
      top: "8px",
    },

    [theme.breakpoints.up("md")]: {
      top: "10px",
    },
  });

  // Todo GetLinkWithParams müssen noch angepasst werden
  // GetLinkWithParams(searchParameters.searchObject);

  const isVehicleChosen = facets && facets?.ty && Object.keys(facets).length > 1;

  const { t } = useTranslation();
  return (
    <div
      id="mpiEnlargedSearch"
      style={{
        maxWidth: "90%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Paper variant="outlined" sx={containerStyle}>
        <SearchButtonAreaComponent {...searchParameters} />
      </Paper>

      {/* Fahrzeugtyp */}
      <Card sx={{ mb: 1.5 }}>
        <CardHeader title={t(`common:filters.vehicleType` as any)} />
        <CardContent>
          <Grid container>
            <VehicleTypeComponentCheckbox {...searchParameters}></VehicleTypeComponentCheckbox>
          </Grid>
        </CardContent>
      </Card>

      {isVehicleChosen && (
        <>
          {/* Filale */}
          {/* {facets && facets?.loA && Object.keys(facets?.loA).length > 1 ? (
            <Card sx={{ mb: 1.5 }}>
              <CardHeader title={t(`common:filters.branch` as any)} />
              <CardContent>
                <BranchComponentSelect {...searchParameters} />
              </CardContent>
            </Card>
          ) : null} */}
          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Fahrzeugzustand" />
            <CardContent>
              <VehicleConditionComponentCheckbox {...searchParameters} showToggleButton={false}></VehicleConditionComponentCheckbox>
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            {/* TODO API MEHRAUSWAHL */}
            <CardHeader title="Aufbauart" />
            <CardContent>
              <Grid container>
                <VehicleBodyTypeComponentCheckbox {...searchParameters} showToggleButton={false} />
              </Grid>
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Marke, Modell, Variante" />
            <CardContent>
              <MakeModelVariant {...searchParameters} />
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Basisdaten" subheader="Preis, Erstzulassung, Kilometer, Leistung" />
            <CardContent>
              <BasicDataComponent {...searchParameters} />
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            {/* TODO API MEHRAUSWAHL */}
            <CardHeader title="Motor" />
            <CardContent>
              <MotorComponent {...searchParameters} />
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Standort" />
            <CardContent>
              <LocationComponent {...searchParameters} />
            </CardContent>
          </Card>

          {!hasMotorcycleValue && (
            <Card sx={{ mb: 1.5 }}>
              {/* TODO API MEHRAUSWAHL */}
              <CardHeader title="Innenausstattung" />
              <CardContent>
                <InsideColorComponent {...searchParameters} />
              </CardContent>
            </Card>
          )}

          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Ausstattung" />
            <CardContent>
              <EquipmentComponent {...searchParameters} />
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Angebotdetails" />
            <CardContent>
              <OfferDetailsComponent {...searchParameters} />
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            <CardHeader title="Umwelt" />
            <CardContent>
              <EnvironmentComponent {...searchParameters} />
            </CardContent>
          </Card>

          <Card sx={{ mb: 1.5 }}>
            {/* TODO API MEHRAUSWAHL */}
            <CardHeader title="Außenfarbe" />
            <CardContent>
              <ExteriorColorComponent {...searchParameters} />
            </CardContent>
          </Card>

          {/* Bike Filter */}
          {hasBike && (
            <>
              {/* Hersteller und Modelle ausgeblendet sonst doppelt
          <Card>
            <CardHeader title={t(`common:filters.manufacturer` as any)} />
            <CardContent>
              <ManufacturerComponentCheckbox {...searchParameters} showToggleButton={false} />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title={t(`common:filters.model` as any)} />
            <CardContent>
              <ModelComponentCheckbox {...searchParameters} showToggleButton={false} />
            </CardContent>
          </Card>
        */}

              <Card>
                <CardHeader title={t(`common:filters.price` as any)} />
                <CardContent>
                  <PriceComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>

              <Card>
                <CardHeader title={t(`common:filters.weight` as any)} />
                <CardContent>
                  <BikeWeightComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.numberOfGears` as any)} />
                <CardContent>
                  <BikeNumberOfGearsComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.category` as any)} />
                <CardContent>
                  <BikeCategoryComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.wheelSize` as any)} />
                <CardContent>
                  <BikeWheelSizeComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.frameMaterial` as any)} />
                <CardContent>
                  <BikeFrameMaterialComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.frameShape` as any)} />
                <CardContent>
                  <BikeFrameShapeComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.frameHeight` as any) + " (cm)"} />
                <CardContent>
                  <BikeFrameHeightComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>

              <Card>
                <CardHeader title={t(`common:filters.gearType` as any)} />
                <CardContent>
                  <BikeGearTypeComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.batteryManufacturer` as any)} />
                <CardContent>
                  <BikeBatteryManufacturerComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.batteryCapacity` as any)} />
                <CardContent>
                  <BikeBatteryCapacityWhComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>

              <Card>
                <CardHeader title={t(`common:filters.motorPosition` as any)} />
                <CardContent>
                  <BikeMotorPositionComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader title={t(`common:filters.batteryPosition` as any)} />
                <CardContent>
                  <BikeBatteryPositionComponentCheckbox {...searchParameters} showToggleButton={false} />
                </CardContent>
              </Card>
            </>
          )}
        </>
      )}
      <ScrollToTop />
    </div>
  );
};

export default EnlargedSearch;
