/* eslint-disable */
// @ts-nocheck
//TODO: Das muss evtl nochmal alles neu gemacht werden. Bis dahin ignoriere die Typescript Fehler. Es funktioniert trotzdem gerade so

import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import SliderInputElement from "@/components/Forms/SliderInputElement";
import SliderTextBoxElements from "@/components/Forms/SliderTextBoxElements";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { IMark } from "@/interfaces/ISliderPropsNeu";
import useStore from "@store";

const BikeBatteryCapacityWhComponentRangeSlider: React.FC<ISearchParametersProps> = ({ ...searchParameters }, locale) => {
  const { rg } = useStore();

  const formatValue = (value: number) => {
    return locale ? value.toLocaleString(locale) : value.toString();
  };

  const [unit] = useState("Wh");
  const [textBoxValues, setTextBoxValues] = useState<number[]>([]);
  const [sliderValues, setSliderValues] = useState<number[]>([]);
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (rg && rg.bcA && rg.bcA.length > 0) {
      // Initialisiere nur, wenn es noch nicht initialisiert wurde
      if (!isInitialized) {
        const initialIndexes = [0, rg.bcA.length - 1];
        setTextBoxValues([rg.bcA[initialIndexes[0]], rg.bcA[initialIndexes[1]]]);
        setSliderValues(initialIndexes);
        setInitialized(true);
      } else {
        // Hole die aktuellen Werte für bcwF und bcwT
        const currentSeF = searchParameters?.searchObject?.bcF;
        const currentSeT = searchParameters?.searchObject?.bcT;

        // Finde die Indizes für bcwF und bcwT im rg.bcwA Array
        const seFIndex = currentSeF !== undefined ? rg.bcA.findIndex((item) => item === currentSeF) : -1;
        const seTIndex = currentSeT !== undefined ? rg.bcA.findIndex((item) => item === currentSeT) : -1;

        // Aktualisiere textBoxValues und sliderValues basierend auf dem Vorhandensein von bcwF und bcwT
        const newTextBoxValues = [...textBoxValues];
        const newSliderValues = [...sliderValues];

        if (currentSeF !== undefined && seFIndex !== -1) {
          newTextBoxValues[0] = rg.bcA[seFIndex];
          newSliderValues[0] = seFIndex;
        } else if (currentSeF === undefined) {
          // Setze den ersten Wert zurück, wenn bcwF gelöscht wurde
          newTextBoxValues[0] = rg.bcA[0];
          newSliderValues[0] = 0;
        }

        if (currentSeT !== undefined && seTIndex !== -1) {
          newTextBoxValues[1] = rg.bcA[seTIndex];
          newSliderValues[1] = seTIndex;
        } else if (currentSeT === undefined) {
          // Setze den letzten Wert zurück, wenn bcwT gelöscht wurde
          newTextBoxValues[1] = rg.bcA[rg.bcA.length - 1];
          newSliderValues[1] = rg.bcA.length - 1;
        }

        // Aktualisiere die Zustände nur, wenn sich die Werte tatsächlich geändert haben
        if (textBoxValues[0] !== newTextBoxValues[0] || textBoxValues[1] !== newTextBoxValues[1]) {
          setTextBoxValues(newTextBoxValues);
        }
        if (sliderValues[0] !== newSliderValues[0] || sliderValues[1] !== newSliderValues[1]) {
          setSliderValues(newSliderValues);
        }
      }
    }
  }, [rg, searchParameters.searchObject, isInitialized, textBoxValues, sliderValues]);

  // die ToolTips am Slider
  let marksTT: IMark[];
  // die Beschriftungen unter dem Slider
  let marks: IMark[];

  if (!rg || !rg.bcA || rg.bcA.length === 0) {
    // Verwendung von CircularProgress, bis die rg verfügbar ist
    return <CircularProgress />;
  } else {
    // die ToolTips am Slider
    marksTT = rg.bcA.map((value, index) => ({
      value: index,
      label: `${formatValue(value)} ${unit}`
    }));
    // die Beschriftungen unter dem Slider
    marks = [marksTT[0], marksTT[rg.bcA.length - 1]];
  }

  const handleChange = (newValue: number[]) => {
    const newValues = [rg.bcA[newValue[0]], rg.bcA[newValue[1]]];
    // TextBoxen mit mit gewählten Werten setzen
    setTextBoxValues(newValues);
    // Direktes Aktualisieren der Slider-Position
    setSliderValues(newValue);
    // das SearchObject mit gewählten Werten setzen
    searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      bcF: newValues[0],
      bcT: newValues[1]
    }));
  };

  return (
    <Grid container>
      <SliderTextBoxElements unit={unit} textBoxValues={textBoxValues} locale={locale} />
      <SliderInputElement onChange={handleChange} data={sliderValues} marks={marks} marksToolTip={marksTT} />
    </Grid>
  );
};

export default BikeBatteryCapacityWhComponentRangeSlider;
