import { Grid, Typography } from "@mui/material";
import React from "react";

const ConsumptionProperty: React.FC<{ title: string; property: string | number; sxProperty?: object; sxTitle?: object; valueFontVariant?: any }> = ({ title, property, sxProperty = {}, sxTitle = {}, valueFontVariant = "h6" }) => {
  return (
    <Grid item container sx={{ display: "flex", alignItems: "center" }}>
      <Grid item xs={12} md={"auto"}>
        <Typography variant="h6" component="div" sx={{ ...sxTitle }}>
          <strong>{title}: &nbsp;&nbsp;</strong>
        </Typography>
      </Grid>
      <Grid item xs={12} md={"auto"} maxWidth={"100%"}>
        <Typography variant={valueFontVariant} component="div" sx={{ ...sxProperty }}>
          {property}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ConsumptionProperty;
