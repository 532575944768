import React, { useState } from "react";
import { Card, Typography, CardContent, Grid, Paper, Box, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import ImageCounterElement from "@/components/ImageCounterElement";
import VehicleNewElement from "../VehicleNewElement";
import VehicleDataGridMedium from "./VehicleDataGridMedium";
import PriceElement from "@/components/Labels/PriceElement";
import MpiLabel from "@/components/Labels/MpiLabel";
import Consumption from "@/views/VehicleDetail/Components/Consumption";
import LabelAvailable from "@/components/Labels/LabelAvailable";
import useHandleCardClick from "@/hooks/useHandleCardClick";
import ItemButtonSection from "./ItemButtonSection";
import { getPlaceholder } from "@/tools/vehicleDataHandler";
import { getBackend } from "@/tools/backendApis";
import { getNettoPriceString } from "@/tools/tools";
import useStore from "@store";

interface Props {
  vehicle: IVehicleListItem;
  customerConfiguration: ICustomerConfiguration;
}

const VehicleMedium: React.FC<Props> = ({ vehicle }) => {
  const { vat } = useStore();
  const theme = useTheme();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const handleCardClick = useHandleCardClick(vehicle);
  const placeholder = getPlaceholder(vehicle);

  const renderImageSection = () => (
    <Grid item md={3} xs={12} sx={{ position: "relative" }}>
      <img
        loading="lazy"
        style={{
          width: "100%",
          opacity: isImageLoaded ? 1 : 0
        }}
        src={vehicle.mm.u && vehicle.mm.u[0] ? getBackend(`${vehicle.mm.u[0]}`) : placeholder}
        alt="Fahrzeugbild #1"
        onLoad={() => setIsImageLoaded(true)}
      />
    </Grid>
  );

  const renderTitleSection = () => (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
        <Typography variant="h2" component="div" fontWeight="fontWeightBold" noWrap>
          {vehicle.h} {vehicle.m} {vehicle.na}
        </Typography>
        <MpiLabel title="Angebotsnummer:" sx={{ display: "inline", mr: 0.5 }} />
        <Typography variant="subtitle1" component="div" fontWeight="fontWeightBold" color="primary" sx={{ display: "inline" }}>
          {vehicle.on}
        </Typography>
      </Grid>
      <Grid item>
        <PriceElement title={getNettoPriceString(vehicle?.sp, 0, vehicle?.vat, vat)} price={vehicle.sp} priceColor={theme.palette.secondary.main} fontWeight="fontWeightBold" />
      </Grid>
    </Grid>
  );

  const renderConsumptionSection = () =>
    vehicle?.en?.co && (
      <Grid container sx={{ my: 1 }}>
        <Consumption carData={vehicle} />
      </Grid>
    );

  const renderLoadingIndicator = () => (
    <Box
      sx={{
        width: 1,
        minHeight: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <Paper variant="outlined" sx={{ position: "relative", width: 1, cursor: "pointer" }} onClick={handleCardClick}>
      {vehicle.new && <VehicleNewElement />}
      <Card key={vehicle.on}>
        <CardContent
          sx={{
            pt: 1,
            "&:last-child": {
              paddingBottom: 0
            }
          }}
        >
          <ImageCounterElement imageCount={vehicle.mm.n} />
          <Grid container>
            {!isImageLoaded && renderLoadingIndicator()}
            {renderImageSection()}
            <Grid item md={9} xs={12} sx={{ pl: { xs: 0, md: 3 } }}>
              {renderTitleSection()}
              <CardContent>
                <LabelAvailable carData={vehicle} />
              </CardContent>
              <VehicleDataGridMedium vehicle={vehicle} />
            </Grid>
            {renderConsumptionSection()}
            <ItemButtonSection />
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default VehicleMedium;
