import React, { useEffect, useState } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MpiLabel from "../../components/MpiLabel";
import { Autocomplete, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { getBackendApi } from "../../tools/backendApis";

const ZipCodeComponentTextBox: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const [zipCodeList, setZipcodeList] = useState<string[]>([]);
  const [inputZipCodeValue, setInputZipCodeValue] = useState("");
  const [selectedZipCode, setSelectedZipCode] = useState<string>("");
  const [perimeter, setPerimeter] = useState("100");

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const key = event.key;
    const isNumber = /^[0-9]$/i.test(key);

    if (!isNumber && key !== "Backspace" && key !== "Delete" && key !== "ArrowLeft" && key !== "ArrowRight" && key !== "ArrowUp" && key !== "ArrowDown") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (inputZipCodeValue && inputZipCodeValue.trim() !== "") {
      const fetchZipCodes = async (input: string) => {
        try {
          const response = await fetch(getBackendApi("/geo/DE/" + input));
          if (!response.ok) {
            throw new Error("Failed to fetch zip codes");
          }
          const data = await response.json();
          setZipcodeList(data || []);
        } catch (error) {
          console.log(error);
          setZipcodeList([]);
        }
      };

      fetchZipCodes(inputZipCodeValue);
    } else {
      setZipcodeList([]);
    }
  }, [inputZipCodeValue]);

  const handleChangePerimeter = (event: SelectChangeEvent) => {
    const newPerimeter = event.target.value as string;
    setPerimeter(newPerimeter);

    if (selectedZipCode && newPerimeter) {
      const newGd = selectedZipCode + "," + newPerimeter;
      void searchParameters.setSearchObject((prevSelectData) => ({
        ...prevSelectData,
        gd: newGd,
      }));
    }
  };
  return (
    <Grid container spacing={2}>
      {/* PLZ Field */}
      <Grid item xs={6}>
        <FormControl fullWidth>
          <MpiLabel title="PLZ" />
          <Autocomplete
            size="small"
            value={searchParameters.searchObject?.gd && zipCodeList.includes(selectedZipCode) ? selectedZipCode : null}
            inputValue={inputZipCodeValue}
            onInputChange={(event, newInputValue) => {
              setInputZipCodeValue(newInputValue);
              setSelectedZipCode(newInputValue);

              if (newInputValue.length === 5 && zipCodeList.includes(newInputValue)) {
                const newGd = newInputValue + "," + perimeter;
                void searchParameters.setSearchObject((prevSelectData) => ({
                  ...prevSelectData,
                  gd: newGd,
                }));
              }

              if (newInputValue.length === 0) {
                const newGd = newInputValue;
                void searchParameters.setSearchObject((prevSelectData) => ({
                  ...prevSelectData,
                  gd: newGd,
                }));
              }
            }}
            id="zipcodevalue"
            options={zipCodeList}
            renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, maxLength: 5 }} onKeyDown={handleKeyPress} />}
          />
        </FormControl>
      </Grid>

      {/* Perimeter Field */}
      {inputZipCodeValue.trim() !== "" && zipCodeList.includes(selectedZipCode) && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <MpiLabel title="Umkreis" />
            <Select size="small" value={perimeter} onChange={handleChangePerimeter}>
              <MenuItem value="10">10 km</MenuItem>
              <MenuItem value="25">25 km</MenuItem>
              <MenuItem value="50">50 km</MenuItem>
              <MenuItem value="100">100 km</MenuItem>
              <MenuItem value="200">200 km</MenuItem>
              <MenuItem value="500">500 km</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};
export default ZipCodeComponentTextBox;
