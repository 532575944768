import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { useParams } from "react-router-dom";
import { Alert, Box, Divider, Grid, Snackbar, Tab, Tabs, Typography } from "@mui/material";
import Equipment from "./Components/Equipment";
import QuickInformation from "./Components/QuickInformation";
import MoreInformation from "./Components/MoreInformation";
import VehicleLocation from "./Components/VehicleLocation";
import VehicleDescription from "./Components/VehicleDescription";
import { SET_SELECTED_CAR } from "../../redux/constants/index";
import ScrollToTop from "../../components/ScrollToTop";
import VehicleGallery from "./Components/VehicleGallery";
import VehicleRequestForm from "./Components/VehicleRequestForm";
import { useNavigate } from "react-router-dom";
import NewSearch from "../../components/NewSearch";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { getBackendApi } from "../../tools/backendApis";
import Consumption from "@/views/VehicleDetail/Components/Consumption";
import ListIcon from "@mui/icons-material/List";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HandymanIcon from "@mui/icons-material/Handyman";
import ButtonExpose from "../../components/Buttons/ButtonExpose";
import ButtonShare from "@/components/Buttons/ButtonShare";
import LabelAvailable from "@/components/Labels/LabelAvailable";
import { IVehicleDetailed } from "@Interface/IVehicleDetailed";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import BranchOpenings from "./Components/BranchOpenings";
import { validateDetailUrl } from "@/tools/vehicleDataHandler";
import MoreVehicles from "./Components/MoreVehicles";

import { isBike } from "@/tools/vehicleDataHandler";
import FinancingCalculator from "./Components/FinancingCalculator";
import { useMediaQuery } from "@mui/material";

export const VehicleDetail: React.FC<{ searchParameters: ISearchParametersProps }> = ({ ...searchParameters }) => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState("equipment");
  const [carData, setCarData] = useState({} as IVehicleDetailed);
  const selectedCarLink = useSelector((state: RootState) => state.ConfigurationReducer.selectedCarLink);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const params = useParams();
  const navigate = useNavigate();

  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);

  //style
  const styleTabs = { fontSize: { xs: "12px", md: "14px" } };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleNotFound = () => {
    navigate(customerConfiguration.vehicleList, {
      state: { from: "detail", reason: "not-found" },
    });
  };

  useEffect(() => {
    // Finde den ersten verfügbaren Tab
    const firstAvailableTab = tabs.find((tab) => tab.condition || tab.condition === undefined);
    if (firstAvailableTab) {
      setTabValue(firstAvailableTab.value);
    }
  }, [carData]);

  useEffect(() => {
    if (selectedCarLink !== undefined && selectedCarLink.length > 0) {
      fetch(selectedCarLink)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 404) {
              handleNotFound();
            } else {
              throw new Error(`Fehler: ${response.status}`);
            }
          }
        })
        .then((data: IVehicleDetailed) => {
          if (!validateDetailUrl(data, params)) {
            handleNotFound();
          }
          console.log("CURRENT: ", data);
          setCarData(data);
        })
        .catch((error) => {
          setError(error.message);
          setOpenSnackbar(true);
        });
    } else {
      // TODO das muss noch geändert werden, die Daten müssen vom Backend kommen
      const tempSelectedCarLink = getBackendApi(`/car/${vehicleId}`);
      dispatch({
        type: SET_SELECTED_CAR,
        payload: tempSelectedCarLink,
      });
    }
  }, [selectedCarLink, dispatch, setError]);

  const tabs = [
    { value: "equipment", icon: <HandymanIcon />, label: "Ausstattung", condition: !!carData.equipment },
    { value: "description", icon: <ListIcon />, label: "Beschreibung", condition: !!carData.desc || carData.descF },
    { value: "location", icon: <LocationOnIcon />, label: "Standort" },
    { value: "opening", icon: <AccessTimeIcon />, label: "Öffnungszeiten", condition: !!carData.Branch?.OpeningHours },
  ];

  const MQMinHeight = useMediaQuery("(min-height:650px)");

  if (Object.keys(carData).length > 0) {
    return (
      <>
        {error && (
          <Snackbar
            open={openSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setOpenSnackbar(false)}
            style={{
              position: "relative",
              top: 30,
              left: "50%",
              marginBottom: 60,
              transform: "translateX(-50%)",
            }}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}

        {!error && (
          <Grid container id="mpiVehicleDetail">
            <Grid item xs={12} id="header-navi">
              <NewSearch vehicleObject={searchParameters?.searchParameters?.vehicleObject} />
            </Grid>
            <Grid item xs={12} id="headline" sx={{ bgcolor: "primary.main", borderRadius: "2px" }}>
              <Typography variant="h1" component="div" align="center" color="white" sx={{ lineHeight: 2 }}>
                <strong>
                  {carData.ma} {carData.mo} {carData.na}
                </strong>
              </Typography>
            </Grid>
            <Grid container my={1} justifyContent="space-between" id="head-buttons">
              <Grid item xs="auto" display="flex" alignItems="center">
                <LabelAvailable carData={carData} />
              </Grid>

              <Grid item xs="auto" display="flex">
                <Grid container spacing={1}>
                  {carData?.mm?.expose && (
                    <Grid item xs="auto" display="flex" justifyContent="flex-end">
                      <ButtonExpose carData={carData} />
                    </Grid>
                  )}
                  <Grid item xs="auto" display="flex" justifyContent="flex-end">
                    <ButtonShare />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid id="UpperContainer" container spacing={2}>
              <Grid item xs={12} md={8} id="vehicle-gallery" position="relative">
                <VehicleGallery data={carData} />
              </Grid>

              <Grid container item xs={12} md={4} id="informations" spacing={2}>
                <Grid item xs={12} id="informations1" sx={{ m: 0, p: 0, height: { lg: "auto" }, minHeight: { lg: "250px" }, maxHeight: { lg: "100%" } }}>
                  <QuickInformation data={carData} />
                </Grid>
                <Grid item xs={12} id="informations2" sx={{ m: 0, p: 0, height: { lg: "auto" }, minHeight: { lg: "440px" }, maxHeight: { lg: "100%" } }}>
                  <MoreInformation data={carData} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item id="LeftSide" xs={12} md={8}>
                <Grid item xs={12} id="TabsDetails">
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                      {tabs.map((tab) => (
                        <Tab key={tab.value} icon={tab.icon} label={tab.label} value={tab.value} sx={styleTabs} disabled={tab.condition === false} />
                      ))}
                    </Tabs>
                  </Box>
                </Grid>

                <Grid container item xs={12} id="TabsContent">
                  {tabValue === "equipment" && <Equipment data={carData} />}
                  {tabValue === "description" && <VehicleDescription data={carData} />}
                  {tabValue === "location" && <VehicleLocation data={carData} />}
                  {tabValue === "opening" && <BranchOpenings data={carData} />}
                </Grid>
                {carData.Finanzierung?.anzahlung && (
                  <Grid item xs={12} mt={2}>
                    <FinancingCalculator data={carData} />
                  </Grid>
                )}
              </Grid>

              <Grid item id="RightSide" xs={12} md={4}>
                <Grid
                  item
                  xs={12}
                  id="Contact"
                  sx={{
                    position: MQMinHeight ? "sticky" : "relative",
                    top: "20px",
                  }}
                >
                  <Grid item sm={12}>
                    <VehicleRequestForm data={carData} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid id="Emission" item xs={12} mt={2}>
              {!isBike(carData) && carData.engine?.consumtion && <Consumption carData={carData} inDetailView />}
            </Grid>
            <Grid id="MoreVehiclesore" item xs={12} mt={5}>
              {carData.sv && carData.sv.length > 0 && <MoreVehicles data={carData.sv} />}
            </Grid>
            <Grid item xs={12} mt={3} id="Disclaimer">
              {carData.Finanzierung?.disclaimer2 && <Typography>** {carData.Finanzierung.disclaimer2}</Typography>}
              <Divider sx={{ p: 1 }} />
              <Typography>Bitte beachten Sie, dass bei allen Angaben zum Fahrzeug Irrtümer, Zwischenverkauf und Änderungen vorbehalten sind.</Typography>
              <ScrollToTop />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
  return null;
};

export default VehicleDetail;
