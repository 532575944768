import React, { FC } from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import QuickSearchWithTabs from "./QuickSearchWithTabs";
import { Box } from "@mui/material";
import useStore from "@store";
import { Helmet } from "react-helmet";

const QuickSearch: FC<ISearchParametersProps> = (searchParameters) => {
  const { customerConfiguration } = useStore();

  const helmetCanonical = `${window.location.origin}${customerConfiguration.quickSearch}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={helmetCanonical} />
      </Helmet>
      <Box id="mpiQuickSearch">
        <QuickSearchWithTabs {...searchParameters} />
      </Box>
    </>
  );
};

export default QuickSearch;
