import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { Grid, GridSize } from "@mui/material";
import { ICheckboxProps } from "@Interface/ICheckboxProps";
import { TYPE_MOTORCYCLE, FILTER_NON_SMOKING, FILTER_VEHICLE_CONDITION } from "../../../redux/constants/index";
import QualitySealComponentSelect from "../../../FormComponents/SelectBoxes/QualitySealComponentSelect";
import MpiLabel from "../../../components/MpiLabel";
import OfferNumberComponentTextBox from "../../../FormComponents/TextBoxes/OfferNumberComponentTextBox";
import OfferDetailsComponentCheckbox from "../../../FormComponents/CheckBoxes/OfferDetailsComponentCheckbox";

const OfferDetails: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const CheckBoxsesArrayOfferDetails = [
    {
      label: "Inserate mit Bildern",
      name: "im",
      checked: false,
      data: { "Inserate mit Bildern": [1, 0] },
    },
    {
      label: "nur neue Inserate",
      name: "new",
      checked: false,
      data: { "nur neue Inserate": [1, 0] },
    },
    {
      label: "Nichtraucher-Fahrzeug",
      name: "nsm",
      checked: false,
      data: { "Nichtraucher-Fahrzeug": [1, 0] },
    },
    {
      label: "Unfallfahrzeug",
      name: "acn",
      checked: false,
      data: { Unfallfahrzeug: [1, 0] },
    },
    {
      label: "EU-Import",
      name: "eu",
      checked: false,
      data: { "EU-Import": [1, 0] },
    },
    {
      label: "reduzierter Preis",
      name: "rp",
      checked: false,
      data: { "reduzierter Preis": [1, 0] },
    },
  ];

  const hasMotorcycleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === "ty" && Array.isArray(value) && value[0] === TYPE_MOTORCYCLE);

  const hasNewVehicleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === FILTER_VEHICLE_CONDITION && Array.isArray(value) && value[0] === 1);

  const data = () => {
    if (hasMotorcycleValue) {
      return CheckBoxsesArrayOfferDetails.filter((obj) => obj["name"] !== FILTER_NON_SMOKING) as ICheckboxProps[];
    } else {
      return CheckBoxsesArrayOfferDetails;
    }
  };

  const getGridSizes = (): Record<"xs" | "sm" | "md" | "lg" | "xl", GridSize> => ({
    xs: 12,
    sm: 6,
    md: 4,
    lg: 4,
    xl: 4,
  });

  return (
    <>
      <OfferDetailsComponentCheckbox {...searchParameters} data={data()} />

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {!hasNewVehicleValue && (
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <MpiLabel title="Qualitätssiegel" />
            <QualitySealComponentSelect {...searchParameters} />
          </Grid>
        )}
        <Grid item container {...getGridSizes()}>
          <OfferNumberComponentTextBox {...searchParameters} />
        </Grid>
      </Grid>
    </>
  );
};

export default OfferDetails;
