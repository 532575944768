import React from "react";
import { Chip, SxProps, Tooltip } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface ILabelAvailable {
  carData: any;
  sx?: SxProps;
}

const LabelAvailable: React.FC<ILabelAvailable> = ({ carData, sx }) => {
  const isAvailable = carData.av === "sofort verfügbar";

  return (
    <Tooltip title={carData.av} placement="top">
      <Chip
        avatar={
          isAvailable ? (
            <CheckCircleOutlineIcon
              sx={{
                color: "white !important"
              }}
            />
          ) : (
            <AccessTimeIcon
              sx={{
                color: "black !important"
              }}
            />
          )
        }
        label={carData.av}
        className={isAvailable ? "MuiChip-available" : ""}
        sx={{
          ...sx
        }}
      />
    </Tooltip>
  );
};

export default LabelAvailable;
