import React from "react";
import { IVehicleDetailed } from "@/interfaces/IVehicleDetailed";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";

const BranchOpenings: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  return (
    <Box>
      {Object.entries(data.Branch.OpeningHours).map(([section, hours]) => (
        <Card key={section} sx={{ mb: 2 }}>
          <CardContent>
            <Typography variant="h6" component="div" gutterBottom align="center" mb={2}>
              <strong>{section}</strong>
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              {Object.entries(hours).map(([day, time]) => (
                <Grid item xs={6} sm={4} key={day}>
                  <Typography variant="body1" align="center">
                    <strong>{day.toUpperCase()}:</strong> {time}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default BranchOpenings;
