import { SET_CUSTOMER_CONFIGURATION, SET_CUSTOMER_DOMAIN, SET_SEARCH_LINK, SET_SEARCH_OBJECT, SET_FACETS_OBJECT, SET_RANGES_OBJECT, SET_SEARCHRESULTS_OBJECT, SET_SELECTED_CAR, SET_ALLFACETS_OBJECT, SET_RG_OBJECT, SET_SEARCH_OBJECT_NAMES, SET_BEARER_TOKEN, SET_VAT } from "./constants/index";
import InitialValues from "../initials";
import { Action } from "redux";
import { ICustomerConfiguration } from "./api/interfaces/ICustomerConfiguration";
import { ISearchParametersProps } from "./api/interfaces/ISearchParametersProps";
import { IFacets } from "./api/interfaces/IFacets";
import { IRg } from "./api/interfaces/IRg";

// Typdefinitionen für die Payloads der Aktionen
interface ConfigurationActionPayload {
  customerConfiguration?: ICustomerConfiguration;
  customerDomain?: string;
  selectedCarLink?: string;
  searchLink?: string;
  searchObject?: ISearchParametersProps;
  facets?: IFacets;
  allFacets?: any;
  searchResult?: any;
  rg?: IRg;
  searchObjectNames?: { [key: string]: string };
  bearerToken?: string;
  vat?: number;
}

// Erweitern Sie die Action-Schnittstelle von Redux
export interface IConfigurationAction extends Action<string> {
  payload?: ConfigurationActionPayload;
}

const INIT_STATE = InitialValues();

const ConfigurationReducer = (state = INIT_STATE, action: IConfigurationAction) => {
  switch (action.type) {
    case SET_CUSTOMER_CONFIGURATION:
      return {
        ...state,
        customerConfiguration: {
          ...state.customerConfiguration,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_DOMAIN:
      return {
        ...state,
        customerDomain: action.payload,
      };

    case SET_SELECTED_CAR:
      return {
        ...state,
        selectedCarLink: action.payload,
      };

    case SET_SEARCH_LINK:
      return {
        ...state,
        searchLink: action.payload,
      };

    case SET_SEARCH_OBJECT:
      return {
        ...state,
        searchObject: action.payload,
      };

    case SET_FACETS_OBJECT:
      return {
        ...state,
        facets: action.payload,
      };

    case SET_RANGES_OBJECT:
      return {
        ...state,
        ranges: action.payload,
      };

    case SET_RG_OBJECT:
      return {
        ...state,
        rg: action.payload,
      };

    case SET_ALLFACETS_OBJECT:
      return {
        ...state,
        allFacets: action.payload,
      };

    case SET_SEARCHRESULTS_OBJECT:
      return {
        ...state,
        searchResult: action.payload,
      };

    case SET_SEARCH_OBJECT_NAMES:
      return {
        ...state,
        searchObjectNames: action.payload,
      };

    case SET_BEARER_TOKEN:
      localStorage.setItem("bearerToken", action.payload as string);
      return {
        ...state,
        bearerToken: action.payload,
      };

    case SET_VAT:
      return {
        ...state,
        vat: action.payload,
      };

    default:
      return state;
  }
};

export default ConfigurationReducer;
