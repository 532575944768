import React from "react";
import { Box, Grid, Divider } from "@mui/material";
import { Card, CardContent, Typography, Link } from "@mui/material";
import { Phone, Email, Fax } from "@mui/icons-material";
import { getCountryByCode } from "@/tools/countryCodes";
import LocationMap from "./LocationMap";
import ButtonGoogleMap from "@/components/Buttons/ButtonGoogleMap";
import { IVehicleDetailed } from "@/interfaces/IVehicleDetailed";
import logoPlaceholder from "@/assets/img/placeholder-logo.png";
import ContactPanel from "./ContactPanel";
import useStore from "@store";

const VehicleLocation: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  const { customerConfiguration } = useStore();

  return (
    <Card sx={{ width: "100%", p: 2 }}>
      <CardContent>
        {/* company infos */}
        <Grid container>
          <Grid id="LogoComp" item xs={12} sm={5} pb={3} sx={{ textAlign: "center" }}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <img width="250" height="auto" src={customerConfiguration?.logoUrl ? customerConfiguration?.logoUrl : logoPlaceholder} alt={`${data.Branch?.Alias} Logo`} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            container
            alignContent="center"
            sx={{
              pl: { xs: 0, sm: 4 },
              justifyContent: { xs: "center", md: "end" }
            }}
          >
            <Grid sx={{ textAlign: "left" }}>
              {data.Branch?.Phone?.number && (
                <Typography variant="body1" color="textSecondary" mt={1}>
                  <Link href={`tel:${data.Branch?.Phone?.prefix}${data.Branch?.Phone?.number}`} sx={{ textDecoration: "none", color: "primary" }}>
                    <Phone fontSize="small" sx={{ verticalAlign: "middle" }} /> {data.Branch?.Phone?.prefix} {data.Branch?.Phone?.number}
                  </Link>
                </Typography>
              )}

              {data.Branch?.Fax?.number && (
                <Typography variant="body1" color="textSecondary" mt={1}>
                  <Fax fontSize="small" sx={{ verticalAlign: "middle" }} /> {data.Branch?.Fax?.prefix} {data.Branch?.Fax?.number}
                </Typography>
              )}

              {data.Branch?.Mail && (
                <Typography variant="body1" color="textSecondary" mt={1}>
                  <Link href={`mailto:${data.Branch?.Mail}`} sx={{ textDecoration: "none", color: "primary" }}>
                    <Email fontSize="small" sx={{ verticalAlign: "middle" }} /> {data.Branch?.Mail}
                  </Link>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid id="Address" item xs={12} sm={12} mt={3} sx={{ textAlign: "center" }}>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Grid item xs={12} sx={{ maxWidth: "600px !important" }}>
                <Typography variant="h2" component="div" gutterBottom>
                  <strong> {data.Branch?.Alias}</strong>
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {data.Branch?.Street}
                  <br />
                  {data.Branch?.PostCode} {data.Branch?.City}
                  <br />
                  {getCountryByCode(data.Branch?.Country)}
                </Typography>
                <ButtonGoogleMap carData={data} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        {data?.Contacts && data.Contacts[0]?.Salutation && (
          <>
            <Grid container mt={1}>
              {data?.Contacts?.map((contact, index: number) => (
                <Grid item key={index} xs={12} sm={6} mt={1}>
                  <ContactPanel contact={contact} carData={data} />
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ my: 3 }} />
          </>
        )}

        <Grid id="LocationMap" container>
          <LocationMap data={data} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VehicleLocation;
