import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { FILTER_VEHICLE_FUEL_EFFICIENCY_RATING } from "../../redux/constants/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { SelectBoxElement } from "../../components/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { findBrandById, handleChangeSelectBoxValue } from "../../tools/tools";
import { IFacets } from "@Interface/IFacets";
import { ISelectData } from "@Interface/ISelectData";

const FuelEfficiencyRatingComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }, searchObject = {} as ISelectData) => {
  const facets = useSelector((state: RootState) => state.ConfigurationReducer.facets as IFacets);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = facets?.[FILTER_VEHICLE_FUEL_EFFICIENCY_RATING];
  const searchParameter = searchObject[FILTER_VEHICLE_FUEL_EFFICIENCY_RATING];

  return <SelectBoxElement name={FILTER_VEHICLE_FUEL_EFFICIENCY_RATING} placeholder="Kraftstoffeffizienzklasse wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, findBrandById(data, Number(id)), { ...searchParameters, searchObject }, navigate, dispatch)} />;
};

export default FuelEfficiencyRatingComponentSelect;
