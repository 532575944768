import React, { useState } from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FormControlLabel, Grid, Radio, RadioGroup, useTheme } from "@mui/material";
import PriceComponentRangeSlider from "@/FormComponents/RangeSlider/PriceComponentRangeSlider";
import MonthlyPaymentComponentRangeSlider from "@/FormComponents/RangeSlider/MonthlyPaymentComponentRangeSlider";
import PaymentMethodComponentCheckbox from "@/FormComponents/CheckBoxes/PaymentMethodComponentCheckbox";

const PaymentMethod: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const [value, setValue] = useState("Kaufpreis");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <RadioGroup
            sx={{
              display: "flex",
              flexDirection: "row"
            }}
            name="Basis"
            value={value}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="Kaufpreis"
              control={
                <Radio
                  sx={{
                    color: useTheme().palette.primary.main,
                    "&.Mui-checked": {
                      color: useTheme().palette.primary.main
                    }
                  }}
                />
              }
              label="Kaufpreis"
            />
            <FormControlLabel
              value="Monatsrate"
              control={
                <Radio
                  sx={{
                    color: useTheme().palette.primary.main,
                    "&.Mui-checked": {
                      color: useTheme().palette.primary.main
                    }
                  }}
                />
              }
              label="Monatsrate"
            />
          </RadioGroup>
        </Grid>
        {value === "Kaufpreis" ? (
          <>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <PriceComponentRangeSlider {...searchParameters} locale="de-DE" />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <PaymentMethodComponentCheckbox {...searchParameters} />
            </Grid>
          </>
        ) : (
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <MonthlyPaymentComponentRangeSlider {...searchParameters} locale="de-DE" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PaymentMethod;
