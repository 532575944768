import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type ToastOptionsWithPosition = {
  position?: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left";
  autoClose?: number;
  type?: string;
  message?: string;
};

function Notification({ type = "success", message = "", position = "top-right", autoClose = 3000 }: ToastOptionsWithPosition): null {
  useEffect(() => {
    if (type === "success" || type === "error") {
      toast[type](message, { position, autoClose });
    }
  }, [type, message, position, autoClose]);

  return null;
}

export default Notification;
