import React from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import PriceElement from "@/components/Labels/PriceElement";
import MpiLabel from "@/components/Labels/MpiLabel";
import { getBackend } from "@/tools/backendApis";
import { getListAttrLarge, getVehicleType, isBike } from "@/tools/vehicleDataHandler";
import { shortingString } from "@/tools/tools";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";
import { getNettoPriceString } from "@/tools/tools";
import useStore from "@store";

interface VehicleDataGridLargeProps {
  vehicle: IVehicleListItem;
}

const VehicleDataGridLarge: React.FC<VehicleDataGridLargeProps> = ({ vehicle }) => {
  const theme = useTheme();
  const { customerConfiguration, vat } = useStore();

  if (customerConfiguration?.demo) {
    vehicle.pf = 239;
    vehicle.pl = 139;
  }

  const MAX_ITEMS = 5;
  const attributeData = getListAttrLarge(vehicle);
  const isCar = vehicle.ty !== getVehicleType(vehicle);

  const renderAttributeItems = () =>
    attributeData.map(
      (item, index) =>
        item.value &&
        item.icon && (
          <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
            <Tooltip title={item.tooltip}>
              <Typography noWrap sx={{ display: "flex", p: 1, pl: 2 }}>
                {item.icon}
                {item.value}
              </Typography>
            </Tooltip>
          </Grid>
        )
    );

  const renderPriceItems = () => (
    <>
      {isCar && vehicle.pf && <PriceElement title="mtl. Finanzierungsrate**" price={vehicle.pf} priceColor={theme.palette.secondary.main} sx={{ ml: 2, mt: 2 }} />}
      {isCar && vehicle.pl && <PriceElement title="mtl. Leasing-Rate**" price={vehicle.pl} priceColor={theme.palette.secondary.main} sx={{ ml: 2, mt: 2 }} />}
      <PriceElement title={getNettoPriceString(vehicle?.sp, 0, vehicle?.vat, vat)} price={vehicle.sp} priceColor={theme.palette.secondary.main} sx={{ ml: 2, mt: 2 }} fontWeight="fontWeightBold" />
    </>
  );

  const renderBikeDescription = () =>
    vehicle.ds && (
      <Typography
        sx={{
          bgcolor: theme.palette.grey[200],
          p: 0.5,
          pr: 1,
          mr: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          whiteSpace: "nowrap"
        }}
      >
        <CheckOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
        {vehicle.ds}
      </Typography>
    );

  const renderCarHighlights = () =>
    vehicle.lEH &&
    vehicle.lEH.slice(0, MAX_ITEMS).map((element, index) => (
      <Typography
        sx={{
          bgcolor: theme.palette.grey[200],
          p: 0.5,
          pr: 1,
          mr: 1,
          mb: 1,
          display: "flex",
          justifyContent: "center",
          whiteSpace: "nowrap"
        }}
        key={index}
      >
        <CheckOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
        {element}
      </Typography>
    ));

  const renderQualitySeal = () =>
    vehicle.qs && (
      <Grid container sx={{ justifyContent: "flex-end" }}>
        <Grid sx={{ my: 1, display: "flex", justifyContent: "center" }}>
          <img src={getBackend(vehicle.qs)} alt="Qualitätssiegel" />
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container sx={{ mt: 1.5, bgcolor: theme.palette.grey[200] }}>
        {renderAttributeItems()}
      </Grid>
      <Grid container sx={{ mt: 1.5, pb: 1.5, bgcolor: theme.palette.grey[200] }}>
        {renderPriceItems()}
      </Grid>
      {isBike(vehicle) && (
        <Grid item xs={12} sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <MpiLabel title="Beschreibung" />
          <Grid container sx={{ alignItems: "flex-end" }}>
            {renderBikeDescription()}
          </Grid>
        </Grid>
      )}
      {!isBike(vehicle) && (
        <Grid item xs={12} sx={{ mt: 2, display: "flex", flexDirection: "column" }}>
          <MpiLabel title="Ausstattungshighlights" />
          <Grid container sx={{ alignItems: "flex-end" }}>
            {renderCarHighlights()}
          </Grid>
        </Grid>
      )}
      {isBike(vehicle) && vehicle.ds && (
        <Typography variant="subtitle1" component="div" mt={1}>
          {shortingString(vehicle.ds, 120)}
        </Typography>
      )}
      {renderQualitySeal()}
    </>
  );
};

export default VehicleDataGridLarge;
