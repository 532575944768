import React, { createContext, useState, ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";

type MenuContextType = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  isSmallOpen: boolean;
  toggleSmall: () => void;
  setViewOpen: (isViewOpen: number) => void;
  isViewOpen: number;
};

const defaultMenuContext: MenuContextType = {
  isMenuOpen: true,
  toggleMenu: () => {},
  isSmallOpen: true, // Standardwert, falls customerConfiguration undefiniert ist
  toggleSmall: () => {},
  setViewOpen: (isViewOpen: number) => {},
  isViewOpen: 1,
};

export const MenuContext = createContext<MenuContextType>(defaultMenuContext);

export const MenuProvider = ({ children }: { children: ReactNode }) => {
  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);

  const dispatch = useDispatch();

  // Standardwert auf true setzen, falls customerConfiguration undefiniert ist oder keine isSmallOpen-Eigenschaft hat
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSmallOpen, setIsSmallOpen] = useState(customerConfiguration?.isSmallOpen !== undefined ? customerConfiguration?.isSmallOpen : defaultMenuContext.isSmallOpen);
  const [isViewOpen, setViewOpen] = useState(1);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSmall = () => {
    setIsSmallOpen(false);
    dispatch({
      type: "SET_CUSTOMER_CONFIGURATION",
      payload: { isSmallOpen: true },
    });
  };

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        toggleMenu,
        isSmallOpen,
        toggleSmall,
        setViewOpen,
        isViewOpen,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
