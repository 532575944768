import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_BRANCH } from "@/constants/index";
import { SelectBoxElement } from "@/components/Forms/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { handleChangeSelectBoxValue } from "@/tools/tools";
import useStore from "@store";

const BranchComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { facets } = useStore();
  const navigate = useNavigate();

  const data = facets?.[FILTER_VEHICLE_BRANCH];
  const searchParameter = searchParameters.searchObject[FILTER_VEHICLE_BRANCH];

  return <SelectBoxElement name={FILTER_VEHICLE_BRANCH} placeholder="Filiale wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, { ...searchParameters }, navigate)} />;
};

export default BranchComponentSelect;
