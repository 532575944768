import { useMemo } from "react";
import { IEquipmentCategory } from "./interfaces/IEquipment";
import { IData } from "./interfaces/IData";
import { IAllData } from "./interfaces/IAllData";

const CachedDataObject = (data: IData, equipmentsCategory: IEquipmentCategory[]): IAllData => {
  const memoizedData = useMemo(() => {
    let count = 0;
    let cars: any[] = [];

    if (Object.keys(data).length > 0 && data.searchResult) {
      count = data?.searchResult.count;
    }

    if (Object.keys(data).length > 0 && data?.searchResult?.cars && data?.searchResult?.count) {
       cars = data?.searchResult.cars.map(car => ({ ...car }));
    }

    return { equipmentsCategory, count, cars } as IAllData;
  }, [data, equipmentsCategory]);

  return memoizedData;
};

export default CachedDataObject;
