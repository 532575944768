import React, { forwardRef } from "react";
import { Box, BoxProps } from "@mui/material";
import { Icon } from "@iconify/react";

interface Props extends BoxProps {
  icon: string;
}

const Iconify = forwardRef<SVGElement, Props>(function Iconify({ icon, width = 20, sx, ...other }, ref) {
  return <Box ref={ref} component={Icon} icon={icon} sx={{ width, height: width, fontSize: width, ...sx }} {...other} />;
});

export default Iconify;
