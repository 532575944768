import React from "react";
import { Button } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

interface IButtonExpose {
  carData: any;
}

const ButtonGoogleMap: React.FC<IButtonExpose> = ({ carData }) => {
  const url = `http://maps.google.com/maps?daddr=${encodeURIComponent(`${carData?.Branch?.Street}, ${carData?.Branch?.PostCode} ${carData?.Branch?.City}`)}&t=m&layer=1&doflg=ptk&om=0`;

  return (
    <Button
      fullWidth
      variant="contained"
      startIcon={<LocationOnOutlinedIcon />}
      sx={{ mt: 1 }}
      onClick={(e) => {
        e.stopPropagation();
        window.open(url, "_blank", "noopener,noreferrer");
      }}
    >
      Anfahrt
    </Button>
  );
};

export default ButtonGoogleMap;
