import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import ExteriorColorComponentCheckbox from "../../../FormComponents/CheckBoxes/ExteriorColorComponentCheckbox";
import MetallicComponentCheckbox from "../../../FormComponents/CheckBoxes/MetallicComponentCheckbox";
import { TYPE_E_BIKE } from "@/redux/constants";

const ExteriorColorComponent: React.FC<ISearchParametersProps> = ({ showToggleButton, ...searchParameters }) => {
  return (
    <>
      <ExteriorColorComponentCheckbox {...searchParameters} showToggleButton={showToggleButton} />
      {searchParameters?.searchObject?.ty !== TYPE_E_BIKE && <MetallicComponentCheckbox {...searchParameters} />}
    </>
  );
};

export default ExteriorColorComponent;
