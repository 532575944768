import React from "react";

import { Grid } from "@mui/material";
import { createGlobalStyle } from "styled-components";
const GlobalStyles = createGlobalStyle`
  .new-div,
  .image-count-div {
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: 99;
    color: #fff;
  }

  .new-span,
  .image-count-span {
    background-color: rgba(0, 0, 0, 0.7);
    vertical-align: baseline;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .new-div {
    right: -9px;
    top: -4px;
    line-height: 187.5%;
    font-weight: bold;
    text-transform: uppercase;
    &::before {
      border-color: #545454 transparent transparent transparent;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 0;
      position: absolute;
      right: 0;
      bottom: -8px;
      z-index: 10;
    }
    &::before,
    &::after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 0;
      position: absolute;
      right: 0;
      bottom: -8px;
      z-index: 10;
      border-color: rgba(0, 0, 0, 0.3) transparent transparent transparent;
    }
  }

  .new-span {
    width: 60px;
    font-size: 81.25%;
  }
`;
const VehicleNewElement: React.FC = () => {
  return (
    <Grid>
      <GlobalStyles />
      <div className="new-div">
        <div className="new-span">Neu</div>
      </div>
    </Grid>
  );
};

export default VehicleNewElement;
