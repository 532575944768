import React, { useState } from "react";
import { Box, Card, CardContent, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import VehicleDataGridLarge from "./VehicleDataGridLarge";
import ImageCounterElement from "@/components/ImageCounterElement";
import VehicleNewElement from "../VehicleNewElement";
import { getBackend } from "@/tools/backendApis";
import MpiLabel from "@/components/Labels/MpiLabel";
import Consumption from "@/views/VehicleDetail/Components/Consumption";
import LabelAvailable from "@/components/Labels/LabelAvailable";
import useHandleCardClick from "@/hooks/useHandleCardClick";
import ItemButtonSection from "../VehicleMedium/ItemButtonSection";
import { getPlaceholder, getVehicleType } from "@/tools/vehicleDataHandler";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";

interface Props {
  vehicle: IVehicleListItem;
}

const VehicleLarge: React.FC<Props> = ({ vehicle }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleCardClick = useHandleCardClick(vehicle);

  const placeholder = getPlaceholder(vehicle);
  const vehicleImages = vehicle.mm.u.slice(0, 4) || [];
  const isCar = vehicle.ty !== getVehicleType(vehicle);
  const getBackendImage = (url: string) => getBackend(`${url}`);

  const ImageLoader: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
    <img
      loading="lazy"
      style={{
        width: "100%",
        opacity: isImageLoaded ? 1 : 0,
        padding: isCar ? undefined : "10px"
      }}
      src={src}
      alt={alt}
      onLoad={() => setIsImageLoaded(true)}
    />
  );

  const renderImages = () =>
    vehicleImages.slice(1).map((url, index) => (
      <Grid item xs={6} sm={4} md={4} lg={4} key={index}>
        <ImageLoader src={getBackendImage(isCar ? url : url.replace("/S", "/L"))} alt={`Fahrzeugbild #${index + 1}`} />
      </Grid>
    ));

  return (
    <Paper variant="outlined" sx={{ position: "relative", width: "100%", cursor: "pointer" }}>
      {vehicle.new && <VehicleNewElement />}
      <Card key={vehicle.on} onClick={handleCardClick}>
        <CardContent sx={{ pt: 1, "&:last-child": { paddingBottom: 0 } }}>
          <Grid container>
            <Grid item xs={12} md={5} lg={5} sx={{ position: "relative" }}>
              <Grid container>
                {!isImageLoaded && (
                  <Box
                    sx={{
                      width: 1,
                      minHeight: "400px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <Grid container item xs={12} sx={{ m: 1 }}>
                  <ImageCounterElement imageCount={vehicle.mm.n} />
                  <Box sx={{ position: "relative" }}>
                    <ImageLoader src={getBackendImage(vehicleImages[0]) || placeholder} alt="Fahrzeugbild #1" />
                  </Box>

                  {renderImages()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} lg={7} xl={7} mt={1}>
              <Typography variant="h2" component="div" fontWeight="fontWeightBold" noWrap>
                {vehicle.h} {vehicle.m} {vehicle.na}
              </Typography>
              <MpiLabel title="Angebotsnummer:" sx={{ display: "inline", mr: 0.5 }} />
              <Typography variant="subtitle1" component="div" fontWeight="fontWeightBold" color="primary" sx={{ display: "inline" }}>
                {vehicle.on}
              </Typography>
              <CardContent>
                <LabelAvailable carData={vehicle} />
              </CardContent>
              <VehicleDataGridLarge vehicle={vehicle} />
            </Grid>
            {vehicle?.en?.co && (
              <Grid container sx={{ my: 1 }}>
                <Consumption carData={vehicle} />
              </Grid>
            )}
            <Grid item xs={12} px={1}>
              <ItemButtonSection />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default VehicleLarge;
