import React from "react";
import { Grid, Typography } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

interface Props {
  currentIndex?: number;
  imageCount: number;
  imageCounterOnly?: boolean;
}

const ImageCounterElement: React.FC<Props> = ({ currentIndex, imageCount, imageCounterOnly = false }) => {
  const imageCounterWith = imageCounterOnly ? 140 : 95;

  return (
    <Grid
      sx={{
        bgcolor: "common.black",
        color: "common.white",
        maxWidth: imageCounterWith,
        m: 1,
        px: 1,
        position: "absolute",
        zIndex: 999
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <CameraAltOutlinedIcon fontSize="small" sx={{ mr: 1 }} />

        {imageCounterOnly ? (
          <Typography variant="subtitle2" component="div" color="common.white">
            Bild {currentIndex} von {imageCount}
          </Typography>
        ) : (
          <Typography variant="body1" color="common.white">
            {imageCount}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ImageCounterElement;
