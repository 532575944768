import React, { useState, useId } from "react";
import { handleChangeCheckBoxValue, setValue } from "@/tools/tools";

import { ICheckboxProps } from "@/interfaces/ICheckboxProps";
import { CheckboxElement } from "@/components/Forms/CheckboxElement";
import { Grid, Button, useTheme } from "@mui/material";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { useTranslation } from "react-i18next";

interface CheckboxProps extends ISearchParametersProps {
  showToggleButton?: boolean;
  data: ICheckboxProps[];
}

const OfferDetailsComponentCheckbox: React.FC<CheckboxProps> = (searchParameters, data: ICheckboxProps[], showToggleButton = false) => {
  const theme = useTheme();
  const id = useId();

  const CheckBoxDataArray: ICheckboxProps[] = data;

  // CheckBoxDataArray basierend auf dem showMore Zustand angezeigt werden sollen
  const [showMore, setShowMore] = useState(false);
  const visibleCheckBoxDataArray = showToggleButton ? (showMore ? CheckBoxDataArray : CheckBoxDataArray.slice(0, 5)) : CheckBoxDataArray;

  // die Spaltenanzahl basierend auf der Bildschirmgröße
  const columns = !showToggleButton ? { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 } : { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

  const flexDirection = !showToggleButton ? "row" : "column";
  const { t } = useTranslation();
  return (
    <Grid container flexDirection={flexDirection}>
      {Array.isArray(visibleCheckBoxDataArray) &&
        visibleCheckBoxDataArray.map((checkBox, index) => (
          <Grid item {...columns} key={`${id}-${index}-${checkBox.name}`}>
            <CheckboxElement
              name={checkBox.name}
              checked={setValue(checkBox.label ?? "", checkBox.name ?? "", checkBox.data, searchParameters.searchObject)}
              onChange={(event) => {
                handleChangeCheckBoxValue(event, checkBox.label, checkBox.data, searchParameters.setSearchObject);
              }}
              type={checkBox.type}
              label={checkBox.label}
              data={checkBox.data}
              searchObject={searchParameters.searchObject}
              color={checkBox.color}
            />
          </Grid>
        ))}
      {showToggleButton && CheckBoxDataArray.length > 5 && (
        <Grid item xs={12}>
          <Button onClick={() => setShowMore(!showMore)} sx={{ mt: 2, mb: 2 }}>
            {showMore ? t("showLess" as any) : t("showMore" as any)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default OfferDetailsComponentCheckbox;
