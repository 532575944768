import React, { useState } from "react";
import { Button, TextField, Typography, Container, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStore from "@store";

const Login: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const { setBearerToken } = useStore();

  const handleLogin = async () => {
    try {
      const response = await fetch("https://www.testo.de/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password })
      });

      if (!response.ok) {
        throw new Error("Login failed");
      }

      const data = await response.json();
      const token = data.token;

      setBearerToken(token);

      navigate("/admin/config");
    } catch (error) {
      console.error("Error:", error);
      setBearerToken("");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: "16px" }}>
        <Typography variant="h5">Login</Typography>
        <TextField variant="outlined" margin="normal" required fullWidth id="username" label="Username" name="username" autoComplete="username" autoFocus value={username} onChange={(e) => setUsername(e.target.value)} />
        <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <Button fullWidth variant="contained" color="primary" onClick={handleLogin}>
          Login
        </Button>
      </Paper>
    </Container>
  );
};

export default Login;
