import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Grid } from "@mui/material";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { ISelectData } from "@Interface/ISelectData";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import FilterReset from "../../components/FilterReset";

import UndoIcon from "@mui/icons-material/Undo";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";

const SearchButtonAreaComponent: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);
  const [hasFilter, setHasFilter] = useState(false);
  const navigate = useNavigate();
  const [, /* searchClick */ setSearchclick] = useState(false);

  const searchHandleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setSearchclick(true);
    navigate(customerConfiguration.vehicleList);
  };

  const resetHandleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setSearchclick(true);
    const pathName = new URL(window.location.href).pathname;
    navigate(pathName);
    searchParameters.setSearchObject((prev) => (prev = {} as ISelectData));
  };

  function searchHasFilter(obj: { [key: string]: any }): boolean {
    const keys = Object.keys(obj);
    const filteredKeys = keys.filter((key) => key !== "sort" && key !== "sortd");
    return filteredKeys.length > 0;
  }

  useEffect(() => {
    setHasFilter(searchHasFilter(searchParameters?.searchObject));
  }, [searchParameters]);

  return (
    <>
      <Grid container spacing={1} sx={{ mb: 1, justifyContent: "space-between" }}>
        <Grid item xs={12} sm={"auto"}>
          <Button size="small" variant="outlined" onClick={resetHandleClick} startIcon={<UndoIcon />} fullWidth>
            Suche zurücksetzen
          </Button>
        </Grid>
        <Grid item xs={12} sm={"auto"}>
          <Button size="large" variant="contained" onClick={searchHandleClick} startIcon={<SearchOutlinedIcon />} fullWidth>
            {searchParameters.vehicleObject.count} Treffer
          </Button>
        </Grid>
      </Grid>
      {hasFilter && <FilterReset {...searchParameters}></FilterReset>}
    </>
  );
};

export default SearchButtonAreaComponent;
