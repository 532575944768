import React, { useState } from "react";
import { Box, Button, MenuItem } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TelegramIcon from "@mui/icons-material/Telegram";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import XIcon from "@mui/icons-material/X";
import { CustomMenu } from "../Menus/CustomMenu";

const ShareButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Share button functions
  const url = window.location.href;
  const handleMail = () => (window.location.href = `mailto:?body=Ich%20habe%20das%20hier%20gefunden:%20${url}`);
  const handleCopyLink = () => navigator.clipboard.writeText(url);
  const handleShare = (socialUrl: string) => {
    // TODO: Tracking hier rein
    window.open(socialUrl, "_blank");
  };

  // Menu configs
  const menuOpen = Boolean(anchorEl);
  const menuId = menuOpen ? "share-button-menu" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const shareOptions = [
    {
      title: "Link kopieren",
      icon: <ContentCopyIcon />,
      handle: handleCopyLink,
    },
    {
      title: "E-Mail",
      icon: <EmailIcon />,
      handle: handleMail,
    },
    {
      title: "Facebook",
      icon: <FacebookIcon />,
      handle: () => handleShare(`https://www.facebook.com/sharer/sharer.php?u=${url}`),
    },
    {
      title: "WhatsApp",
      icon: <WhatsAppIcon />,
      handle: () => handleShare(`https://wa.me/?text=${url}`),
    },
    {
      title: "Telegram",
      icon: <TelegramIcon />,
      handle: () => handleShare(`https://telegram.me/share/url?url=${url}`),
    },
    {
      title: "X",
      icon: <XIcon />,
      handle: () => handleShare(`https://x.com/intent/tweet?url=${url}`),
    },
  ];

  return (
    <>
      <Button aria-describedby={menuId} variant="contained" startIcon={<ShareIcon />} color="primary" onClick={handleClick} sx={{ "& .MuiButton-startIcon": { marginRight: { xs: "0px", sm: "8px" } } }}>
        <Box fontWeight={"bold"} sx={{ display: { xs: "none", sm: "block" } }}>
          Teilen
        </Box>
      </Button>
      <CustomMenu
        id={menuId}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {shareOptions.map((option) => (
          <MenuItem
            key={option.title}
            component="button"
            onClick={() => {
              option.handle();
              handleClose();
            }}
            sx={{ cursor: "pointer" }}
          >
            {option.icon}
            {option.title}
          </MenuItem>
        ))}
      </CustomMenu>
    </>
  );
};

export default ShareButton;
