import { IVehicleDetailed } from "@/interfaces/IVehicleDetailed";
import React, { useEffect, useState } from "react";

const LocationMap: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  const [latitude, setLatitude] = useState<null | number>(null);
  const [longitude, setLongitude] = useState<null | number>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const address = `${data?.Branch?.Street}, ${data?.Branch?.PostCode} ${data?.Branch?.City}`;

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(address)}&format=json&limit=1`);
        const data = await response.json();
        if (data.length > 0) {
          const { lat, lon } = data[0];
          setLatitude(parseFloat(lat));
          setLongitude(parseFloat(lon));
        }
      } catch (error) {
        console.error("Fehler beim Koordinatenaufruf:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoordinates();
  }, [address]);

  const mapUrl = `https://www.openstreetmap.org/export/embed.html?bbox=${longitude - 0.01},${latitude - 0.01},${longitude + 0.01},${latitude + 0.01}&layer=mapnik&marker=${latitude},${longitude}`;

  return <>{loading ? <div>Lade Karte...</div> : latitude === null || longitude === null ? <div>Die Adresse konnte nicht auf der Karte gefunden werden.</div> : <iframe title="OpenStreetMap" src={mapUrl} width="100%" height="450" style={{ border: 0 }} allowFullScreen></iframe>}</>;
};

export default LocationMap;
