import React, { useState } from "react";
import { Card, CardHeader, CardContent, Box } from "@mui/material";
import FilterMoreToggler from "./FilterMoreToggler";
import { IFacets } from "@/interfaces/IFacets";
import useStore from "@store";

interface FilterCardProps {
  title: string;
  Component: React.FC<any>;
  showToggleButton?: boolean;
  searchParameters: any;
  condition?: boolean;
  allFilter?: boolean;
  facetName?: keyof IFacets;
}

const FilterCard: React.FC<FilterCardProps> = ({ title, Component, searchParameters, showToggleButton, condition, allFilter = true, facetName }) => {
  if (condition !== undefined && !condition) return null;
  const [showAll, setShowAll] = useState<boolean>(false);
  const { facets } = useStore();

  //check if there are empty values. only show filter toggle if necessary
  let hasHiddenFacets = true;
  if (facetName) {
    const facet = facets?.[facetName];
    hasHiddenFacets = facet ? Object.values(facet).find((array) => array[1] === 0) !== undefined : false;
  }

  return (
    <Card sx={{ mt: 1 }}>
      <Box>
        <CardHeader titleTypographyProps={{ variant: "h2" }} title={title} sx={{ display: "inline-flex" }} />
        {allFilter && hasHiddenFacets && <FilterMoreToggler showAll={showAll} onToggle={(newShowAll) => setShowAll(newShowAll)} />}
      </Box>

      <CardContent sx={{ position: "relative" }}>
        <Component {...searchParameters} showToggleButton={showToggleButton} showAllFilter={showAll} />
      </CardContent>
    </Card>
  );
};

export default FilterCard;
