import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MpiLabel from "../../../components/MpiLabel";
import FuelComponentCheckbox from "../../../FormComponents/CheckBoxes/FuelComponentCheckbox";
import EngineGearboxComponentCheckbox from "../../../FormComponents/CheckBoxes/EngineGearboxComponentCheckbox";

const MotorComponent: React.FC<ISearchParametersProps> = (searchParameters) => {
  return (
    <>
      <MpiLabel title="Kraftstoffart" />

      <FuelComponentCheckbox {...searchParameters} showToggleButton={false} />

      <MpiLabel title="Getriebe" />

      <EngineGearboxComponentCheckbox {...searchParameters} />
    </>
  );
};

export default MotorComponent;
