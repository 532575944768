import React from "react";
import { Box, Button, Card, CardContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";

import useStore from "@store";
import { IFacets } from "@/interfaces/IFacets";
import { IEquipment } from "@/interfaces/IEquipment";

function findBrandById(data: Record<string, number[]>, targetId: number) {
  if (!data || typeof data !== "object" || Object.keys(data).length === 0) {
    return [];
  }

  const matchingBrands = [];
  for (const brand in data) {
    const [id, _count] = data[brand]; // Extrahiert die ID und ignoriert die Anzahl

    if (id === targetId) {
      matchingBrands.push(brand);
    }
  }

  return matchingBrands;
}

const FilterReset = (searchParameters: ISearchParametersProps) => {
  const { facets, equipments } = useStore();
  const updatedSearchObject = { ...searchParameters.searchObject };

  const kWtoPS = (value: number) => Math.round(value * 1.35962);

  const handleResetClick = (key: keyof ISearchParametersProps["searchObject"], item: any) => {
    if (Array.isArray(updatedSearchObject[key])) {
      const filteredArray = (updatedSearchObject[key] as Array<any>).filter((value) => value !== item);
      if (filteredArray.length === 0) {
        delete updatedSearchObject[key];
      } else {
        updatedSearchObject[key] = filteredArray;
      }
    } else {
      delete updatedSearchObject[key];
    }
    searchParameters.setSearchObject(updatedSearchObject);
  };

  const formatValue = (key: string, value: any): string => {
    if (value == null) return "";

    let formattedValue = value.toString();
    switch (key) {
      case "kmF":
      case "kmT":
        formattedValue = `${Number(value).toLocaleString("de-DE")} km`;
        break;
      case "spF":
      case "spT":
        formattedValue = `${Number(value).toLocaleString("de-DE")} €`;
        break;
      case "seF":
      case "seT":
        formattedValue = `${Number(value).toLocaleString("de-DE")} St.`;
        break;
      case "poF":
      case "poT":
        formattedValue = `${kWtoPS(Number(value))} ps / ${Number(value).toLocaleString("de-DE")} kw`;
        break;
      case "rgF":
      case "rgT":
        formattedValue = `${Number(value).toLocaleString("de-DE")} €`;
        break;
      case "wgF":
      case "wgT":
        formattedValue = `${Number(value).toLocaleString("de-DE")} Kg`;
        break;
      case "bcF":
      case "bcT":
        formattedValue = `${Number(value).toLocaleString("de-DE")} Wh`;
        break;
    }
    return formattedValue;
  };

  const keysToShowOnlyBrandName = ["st", "ty", "ma", "mo", "bo", "mt", "tr", "ci", "spo", "ai", "qs", "ec", "ef", "es", "doK", "co", "anr", "lo", "fh", "fm", "ws", "bk", "bc", "bm", "gt", "ng", "sf", "mp", "bp", "fs", "con", "mcn", "du", "wg"];

  return (
    <Box sx={{ overflowY: "auto", maxHeight: "256px" }}>
      {Object.entries(updatedSearchObject)
        .filter(([key]: [string, number[]]) => key !== "sort" && key !== "sortd")
        .flatMap(([key, value]: [string, number[]]) => {
          const values: Array<number> = Array.isArray(value) ? value : [value];
          return values.flatMap((item: number | number[] | string, index: number) => {
            let brandName = "";
            let displayValue = "";
            let isNegative = false;

            const resolvedKey = key as keyof IFacets;

            if (key === "doK" && typeof item !== "undefined") {
              brandName = findBrandById(facets[key], item as number)[0] || "";
            } else if (key === "ai" && typeof item !== "undefined") {
              let foundEquipment: IEquipment;
              for (const category of equipments) {
                foundEquipment = category.equipments?.find((equipment) => equipment.id === Math.abs(item as number));
                if (foundEquipment) {
                  brandName = foundEquipment.name;
                  break;
                }
              }
            } else if (resolvedKey in facets && typeof item !== "undefined") {
              brandName = findBrandById(facets[resolvedKey], item as number)[0] || "";
            }

            if (key === "ty") {
              switch (item) {
                case 1:
                  brandName = "PKW";
                  break;
                case 2:
                  brandName = "LKW";
                  break;
                case 6:
                  brandName = "e-Bike";
                  break;
              }
            }

            brandName = brandName || "";
            const formattedItemValue = formatValue(key, item);

            if (keysToShowOnlyBrandName.includes(key)) {
              displayValue = brandName;
            } else {
              let keyDisplayName = key;
              // Benutzerfreundliche Bezeichnungen und Formatierung für spezifische Schlüssel
              switch (key) {
                case "kmF":
                  keyDisplayName = "Kilometer von";
                  break;
                case "kmT":
                  keyDisplayName = "Kilometer bis";
                  break;
                case "irF":
                  keyDisplayName = "Erstzulassung von";
                  break;
                case "irT":
                  keyDisplayName = "Erstzulassung bis";
                  break;
                case "spF":
                  keyDisplayName = "Preis von";
                  break;
                case "spT":
                  keyDisplayName = "Preis bis";
                  break;
                case "seF":
                  keyDisplayName = "Sitze von";
                  break;
                case "seT":
                  keyDisplayName = "Sitze bis";
                  break;
                case "poF":
                  keyDisplayName = "Leistung von";
                  break;
                case "poT":
                  keyDisplayName = "Leistung bis";
                  break;
                case "rgF":
                  keyDisplayName = "Monatsrate von";
                  break;
                case "rgT":
                  keyDisplayName = "Monatsrate bis";
                  break;
                case "wgF":
                  keyDisplayName = "Gewicht von";
                  break;
                case "wgT":
                  keyDisplayName = "Gewicht bis";
                  break;
                case "ngF":
                  keyDisplayName = "Anzahl der Gänge von";
                  break;
                case "ngT":
                  keyDisplayName = "Anzahl der Gänge bis";
                  break;
                case "bcF":
                  keyDisplayName = "Batterie-Kapazität von";
                  break;
                case "bcT":
                  keyDisplayName = "Batterie-Kapazität bis";
                  break;
              }

              if (brandName && formattedItemValue) {
                displayValue = `${keyDisplayName} : ${formattedItemValue}`;
              } else if (!brandName && formattedItemValue) {
                displayValue = `${keyDisplayName} : ${formattedItemValue}`;
              }
            }

            if (!displayValue) return null;

            isNegative = (item as number) < 0;

            return (
              <Button
                size="small"
                variant="outlined"
                key={`${key}-${index}`}
                sx={{
                  mr: 1,
                  my: 0.5,
                  pl: 1,
                  color: isNegative ? "primary.dark" : "primary.light"
                }}
                onClick={() => handleResetClick(key, item)}
              >
                {isNegative && <span>Kein/e&nbsp;</span>}
                <span>{displayValue}</span>
                <CloseIcon sx={{ ml: 1 }} />
              </Button>
            );
          });
        })}
    </Box>
  );
};

export default FilterReset;
