import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_FUEL_EFFICIENCY_RATING } from "@/constants/index";
import { SelectBoxElement } from "@/components/Forms/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { handleChangeSelectBoxValue } from "@/tools/tools";
import { ISelectData } from "@/interfaces/ISelectData";
import useStore from "@store";

const FuelEfficiencyRatingComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }, searchObject = {} as ISelectData) => {
  const { facets } = useStore();
  const navigate = useNavigate();

  const data = facets?.[FILTER_VEHICLE_FUEL_EFFICIENCY_RATING];
  const searchParameter = searchObject[FILTER_VEHICLE_FUEL_EFFICIENCY_RATING];

  return <SelectBoxElement name={FILTER_VEHICLE_FUEL_EFFICIENCY_RATING} placeholder="Kraftstoffeffizienzklasse wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, { ...searchParameters, searchObject }, navigate)} />;
};

export default FuelEfficiencyRatingComponentSelect;
