import React, { useState, useId } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { convertDataToCheckBoxArray, handleChangeCheckBoxValue, setValue } from "../../tools/tools";
import { IFacets } from "@Interface/IFacets";
import { FILTER_VEHICLE_GEARBOX, FILTER_VEHICLE_GEARBOX_ALL } from "../../redux/constants";
import { ICheckboxProps } from "@Interface/ICheckboxProps";
import { CheckboxElement } from "../../components/CheckboxElement";
import { Grid, Button, useTheme } from "@mui/material";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { useTranslation } from "react-i18next";

// Erweitern der Props-Definition um den neuen Prop
interface CheckboxProps extends ISearchParametersProps {
  showToggleButton?: boolean;
}

const EngineGearboxComponentCheckbox: React.FC<CheckboxProps> = ({ showToggleButton, ...searchParameters }) => {
  const facets = useSelector((state: RootState) => state.ConfigurationReducer.facets as IFacets);
  const theme = useTheme();
  const id = useId();
  const data = facets?.[FILTER_VEHICLE_GEARBOX];
  const CheckBoxDataArray: ICheckboxProps[] = convertDataToCheckBoxArray(data, FILTER_VEHICLE_GEARBOX);
  // CheckBoxDataArray basierend auf dem showMore Zustand angezeigt werden sollen
  const [showMore, setShowMore] = useState(false);
  const visibleCheckBoxDataArray = showToggleButton ? (showMore ? CheckBoxDataArray : CheckBoxDataArray.slice(0, 5)) : CheckBoxDataArray;

  // die Spaltenanzahl basierend auf der Bildschirmgröße
  const columns = !showToggleButton ? { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 } : { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };

  const flexDirection = !showToggleButton ? "row" : "column";
  const { t } = useTranslation();
  return (
    <Grid container flexDirection={flexDirection}>
      {visibleCheckBoxDataArray.map((checkBox, index) => (
        <Grid item {...columns} key={`${id}-${index}-${checkBox.name}`}>
          <CheckboxElement
            name={checkBox.name}
            checked={setValue(checkBox.label ?? "", checkBox.name ?? "", checkBox.data, searchParameters.searchObject)}
            onChange={(event) => {
              handleChangeCheckBoxValue(event, checkBox.label, checkBox.data, searchParameters.setSearchObject);
            }}
            type={checkBox.type}
            label={checkBox.label}
            data={checkBox.data}
            searchObject={searchParameters.searchObject}
            color={checkBox.color}
          />
        </Grid>
      ))}
      {showToggleButton && CheckBoxDataArray.length > 5 && (
        <Grid item xs={12}>
          <Button onClick={() => setShowMore(!showMore)} sx={{ mt: 2, mb: 2 }}>
            {showMore ? t("showLess" as any) : t("showMore" as any)}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default EngineGearboxComponentCheckbox;
