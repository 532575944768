import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_EMISSION_STICKER } from "@/constants/index";
import { SelectBoxElement } from "@/components/Forms/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { handleChangeSelectBoxValue } from "@/tools/tools";
import { ISelectData } from "@/interfaces/ISelectData";
import useStore from "@store";

const EmissionStickerComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { facets } = useStore();
  const navigate = useNavigate();

  // Ensure searchObject is defined and has the required structure
  const searchObject = searchParameters.searchObject || ({} as ISelectData);
  const data = facets?.[FILTER_VEHICLE_EMISSION_STICKER];
  const searchParameter: number[] = searchObject[FILTER_VEHICLE_EMISSION_STICKER];

  return <SelectBoxElement name={FILTER_VEHICLE_EMISSION_STICKER} placeholder="Umweltplakette wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, { ...searchParameters }, navigate)} />;
};

export default EmissionStickerComponentSelect;
