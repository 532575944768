import React from "react";
import SearchButtonAreaComponent from "./SearchButtonAreaComponent";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import MakeModelVariant from "./components/MakeModelVariantComponent";
import PaymentMethod from "./components/PaymentMethodComponent";
import BasicDataComponent from "./components/BasicDataComponent";
import ExteriorColorComponentCheckbox from "@/FormComponents/CheckBoxes/ExteriorColorComponentCheckbox";
import MetallicComponentCheckbox from "@/FormComponents/CheckBoxes/MetallicComponentCheckbox";
import InsideColorComponent from "./components/InsideColorComponet";
import EnvironmentComponent from "./components/EnvironmentComponent";
import LocationComponent from "./components/LocationComponent";
import FuelComponentCheckbox from "@/FormComponents/CheckBoxes/FuelComponentCheckbox";
import EngineGearboxComponentCheckbox from "@/FormComponents/CheckBoxes/EngineGearboxComponentCheckbox";
import BranchComponentSelect from "@/FormComponents/SelectBoxes/BranchComponentSelect";
import EquipmentTabs from "@/views/DetailSearch/components/EquipmentComponentTabs";
import ScrollToTop from "@/components/ScrollToTop";
import OfferDetailsComponent from "./components/OfferDetailsComponent";
import { Box, Paper } from "@mui/material";
import VehicleConditionComponentCheckbox from "@/FormComponents/CheckBoxes/VehicleConditionComponentCheckbox";
import VehicleTypeComponentCheckbox from "@/FormComponents/CheckBoxes/VehicleTypeComponentCheckbox";
import VehicleBodyTypeComponentCheckbox from "@/FormComponents/CheckBoxes/VehicleBodyTypeComponentCheckbox";
import PriceComponentRangeSlider from "@/FormComponents/RangeSlider/PriceComponentRangeSlider";
import BikeMotorPositionComponentCheckbox from "@/FormComponents/CheckBoxes/BikeMotorPositionComponentCheckbox";
import BikeBatteryPositionComponentCheckbox from "@/FormComponents/CheckBoxes/BikeBatteryPositionComponentCheckbox ";
import BikeBatteryCapacityWhComponentRangeSlider from "@/FormComponents/RangeSlider/BikeBatteryCapacityWhComponentRangeSlider";
import BikeBatteryManufacturerComponentCheckbox from "@/FormComponents/CheckBoxes/BikeBatteryManufacturerComponentCheckbox";
import BikeGearTypeComponentCheckbox from "@/FormComponents/CheckBoxes/BikeGearTypeComponentCheckbox";
import BikeFrameShapeComponentCheckbox from "@/FormComponents/CheckBoxes/BikeFrameShapeComponentCheckbox";
import BikeFrameMaterialComponentCheckbox from "@/FormComponents/CheckBoxes/BikeFrameMaterialComponentCheckbox";
import BikeWheelSizeComponentCheckbox from "@/FormComponents/CheckBoxes/BikeWheelSizeComponentCheckbox";
import BikeCategoryComponentCheckbox from "@/FormComponents/CheckBoxes/BikeCategoryComponentCheckbox";
import BikeNumberOfGearsComponentRangeSlider from "@/FormComponents/RangeSlider/BikeNumberOfGearsComponentRangeSlider";
import BikeWeightComponentRangeSlider from "@/FormComponents/RangeSlider/BikeWeightComponentRangeSlider";
import { useTranslation } from "react-i18next";
import BikeFrameHeightComponentRangeSlider from "@/FormComponents/RangeSlider/BikeFrameHeightComponentRangeSlider";
import useStore from "@store";
import FilterCard from "@/components/Filter/FilterCard";
import { FILTER_VEHICLE_CONDITION, FILTER_VEHICLE_BODYTYPE, FILTER_VEHICLE_INTERIR_COLOR, FILTER_VEHICLE_EXTERIR_COLOR, FILTER_VEHICLE_METALLIC, FILTER_VEHICLE_FUEL, FILTER_VEHICLE_GEARBOX } from "@/constants";

import { Helmet } from "react-helmet";
import SeatsDoorsVariant from "./components/SeatsDoorsComponent";

const DetailSearch: React.FC<ISearchParametersProps> = (searchParameters) => {
  const { facets, customerConfiguration } = useStore();

  let hasBike, hasPKW, hasLKW, hasMotorcycle;
  if (facets?.ty) {
    const types = Object.keys(facets.ty);
    hasBike = types.some((typ) => typ.includes("e-Bike"));
    hasPKW = types.some((typ) => typ.includes("PKW"));
    hasLKW = types.some((typ) => typ.includes("LKW"));
    hasMotorcycle = types.some((typ) => typ.includes("Motorrad"));
  }

  console.log("facets: ", facets);
  console.log("searchParameters: ", searchParameters);

  const { t } = useTranslation();

  const helmetCanonical = `${window.location.origin}${customerConfiguration.detailSearch}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={helmetCanonical} />
      </Helmet>
      <div
        id="mpiEnlargedSearch"
        style={{
          maxWidth: "90%",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Paper sx={{ position: "sticky", width: "110%", top: 0, marginLeft: "-5%", maxWidth: "110%", boxShadow: Object.keys(searchParameters?.searchObject).length > 0 ? "0 6px 8px rgba(0, 0, 0, 0.1)" : "none", display: "block", transition: "width 0.3s ease-in-out, margin-left 0.3s ease-in-out", zIndex: 1000, mb: 2, p: 1 }}>
          <SearchButtonAreaComponent {...searchParameters} />
        </Paper>

        <FilterCard title={t(`common:filters.vehicleType` as any)} Component={VehicleTypeComponentCheckbox} searchParameters={searchParameters} allFilter={false} />

        {facets && facets?.ty && Object.keys(facets).length > 1 && (
          <>
            <FilterCard title="Filiale" Component={BranchComponentSelect} searchParameters={searchParameters} showToggleButton={false} allFilter={false} />

            <FilterCard title="Fahrzeugzustand" Component={VehicleConditionComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} facetName={FILTER_VEHICLE_CONDITION} />

            <FilterCard title="Marke, Modell, Variante" Component={MakeModelVariant} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title="Aufbauart" Component={VehicleBodyTypeComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} facetName={FILTER_VEHICLE_BODYTYPE} />

            <FilterCard title="Preise / Zahlungsart" Component={PaymentMethod} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title="Basisdaten" Component={BasicDataComponent} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title="Kraftstoffart" Component={FuelComponentCheckbox} searchParameters={searchParameters} facetName={FILTER_VEHICLE_FUEL} />

            <FilterCard title="Getriebe" Component={EngineGearboxComponentCheckbox} searchParameters={searchParameters} facetName={FILTER_VEHICLE_GEARBOX} />

            <FilterCard title="Außenfarbe" Component={ExteriorColorComponentCheckbox} searchParameters={searchParameters} facetName={FILTER_VEHICLE_EXTERIR_COLOR} />

            <FilterCard title="Lackart" Component={MetallicComponentCheckbox} searchParameters={searchParameters} facetName={FILTER_VEHICLE_METALLIC} />

            <FilterCard title="Ausstattung" Component={EquipmentTabs} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title="Innenausstattung" Component={InsideColorComponent} searchParameters={searchParameters} facetName={FILTER_VEHICLE_INTERIR_COLOR} />

            <FilterCard title="Sitze, Türen" Component={SeatsDoorsVariant} searchParameters={searchParameters} allFilter={false} condition={hasMotorcycle} />

            <FilterCard title="Standort" Component={LocationComponent} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title="Angebotsdetails" Component={OfferDetailsComponent} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title="Umwelt" Component={EnvironmentComponent} searchParameters={searchParameters} allFilter={false} />

            <FilterCard title={t(`common:filters.weight` as any)} Component={BikeWeightComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />

            <FilterCard title={t(`common:filters.numberOfGears` as any)} Component={BikeNumberOfGearsComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />

            <FilterCard title={t(`common:filters.category` as any)} Component={BikeCategoryComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.wheelSize` as any)} Component={BikeWheelSizeComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.frameMaterial` as any)} Component={BikeFrameMaterialComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.frameShape` as any)} Component={BikeFrameShapeComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.frameHeight` as any) + " (cm)"} Component={BikeFrameHeightComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />

            <FilterCard title={t(`common:filters.gearType` as any)} Component={BikeGearTypeComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.batteryManufacturer` as any)} Component={BikeBatteryManufacturerComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.batteryCapacity` as any)} Component={BikeBatteryCapacityWhComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />

            <FilterCard title={t(`common:filters.motorPosition` as any)} Component={BikeMotorPositionComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />

            <FilterCard title={t(`common:filters.batteryPosition` as any)} Component={BikeBatteryPositionComponentCheckbox} searchParameters={searchParameters} showToggleButton={false} condition={hasBike} />
          </>
        )}
        <ScrollToTop />
      </div>
    </>
  );
};

export default DetailSearch;
