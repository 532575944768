import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { Grid } from "@mui/material";
import EmissionStickerComponentSelect from "@/FormComponents/SelectBoxes/EmissionStickerComponentSelect";
import EmissionCategoryComponentSelect from "@/FormComponents/SelectBoxes/EmissionCategoryComponentSelect";
import FuelEfficiencyRatingComponentSelect from "@/FormComponents/SelectBoxes/FuelEfficiencyRatingComponentSelect";
import MpiLabel from "@/components/Labels/MpiLabel";

const Environment: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <MpiLabel title="Plakette" />
        <EmissionStickerComponentSelect {...searchParameters} />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <MpiLabel title="Emissionsklasse" />
        <EmissionCategoryComponentSelect {...searchParameters} />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <MpiLabel title="Emissionslabel" />
        <FuelEfficiencyRatingComponentSelect {...searchParameters} />
      </Grid>
    </Grid>
  );
};

export default Environment;
