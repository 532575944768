import React, { useId } from "react";
import { handleChangeCheckBoxValue, setValue } from "../../tools/tools";
import { CheckboxElement } from "../../components/CheckboxElement";
import { Grid, useTheme } from "@mui/material";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { FILTER_VEHICLE_METALLIC } from "../../redux/constants";

const MetallicComponentCheckbox: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const theme = useTheme();
  const id = useId();

  const data = [
    {
      label: "Metallic",
      name: FILTER_VEHICLE_METALLIC,
      checked: false,
      data: { Metallic: [1, 0] },
      type: "checkbox" as "checkbox" | "text" | "radio",
    },
  ];

  // die Spaltenanzahl basierend auf der Bildschirmgröße
  const columns = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 };
  const flexDirection = "row";

  return (
    <Grid container flexDirection={flexDirection}>
      {data.map((checkBox, index) => (
        <Grid item {...columns} key={`${id}-${index}-${checkBox.name}`}>
          <CheckboxElement
            name={checkBox.name}
            checked={setValue(checkBox.label ?? "", checkBox.name ?? "", checkBox.data, searchParameters.searchObject)}
            onChange={(event) => {
              handleChangeCheckBoxValue(event, checkBox.label, checkBox.data, searchParameters.setSearchObject);
            }}
            type={checkBox.type}
            label={checkBox.label}
            data={checkBox.data}
            searchObject={searchParameters.searchObject}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default MetallicComponentCheckbox;
