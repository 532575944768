import React, { FC, useState, useEffect, useMemo } from "react";
import ImageGallery from "react-image-gallery";
import { Button, Card, Grid, IconButton, CircularProgress, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "react-image-gallery/styles/css/image-gallery.css";
import { createGlobalStyle } from "styled-components";
import { Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon, PlayArrow as PlayArrowIcon, Pause as PauseIcon, ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import { getPlaceholder } from "@/tools/vehicleDataHandler";
import { getBackend } from "@/tools/backendApis";
import { IVehicleDetailed } from "@/interfaces/IVehicleDetailed";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";
import placeholder360In from "@/assets/img/placeholder-360-in.png";
import placeholder360Out from "@/assets/img/placeholder-360-out.png";
import placeholderVideo from "@/assets/img/placeholder-video.png";
import placeholderSpinner from "@/assets/img/placeholder-360-spinner.png";
import Exterior360View from "@/components/Externals/Exterior360View";
import { PanoViewer } from "@egjs/react-view360";

const GlobalStyles = createGlobalStyle`
  @media only screen and (min-width: 800px) {
    .image-gallery-image, .image-gallery-image iframe {
      height: 100%;
      min-height: 660px;
    }
  }
  @media only screen and (max-width: 799px) {
    .image-gallery-image, .image-gallery-image iframe {
      height: 100%;
      min-height: 400px;
    }
  }
  @media only screen and (max-width: 500px) {
    .image-gallery-image, .image-gallery-image iframe {
      height: 100%;
      min-height: 200px;
    }
  }

  .image-gallery-svg {
    background-color: #000;
    opacity: 0.3;
  }

  .image-gallery-thumbnail {
    cursor: pointer;
  }

  .image-gallery-thumbnail.active,
  .image-gallery-thumbnail:hover,
  .image-gallery-icon:hover .image-gallery-svg,
  .image-gallery-bullets .image-gallery-bullet.active,
  .image-gallery-bullets .image-gallery-bullet.active:hover,
  .image-gallery-bullets .image-gallery-bullet:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  .image-gallery-icon.image-gallery-fullscreen-button > .image-gallery-svg,
  .image-gallery-icon.image-gallery-play-button > .image-gallery-svg {
    position: relative;
    top: -100px;
    background: none;
  }

  .image-gallery-index {
    position: absolute !important;
    top: 10px !important;
    left: 10px !important;
    color: white !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
    text-align: center !important;
    padding: 5px !important;
    width: 5em !important;
    z-index: 100 !important;
    font-size: 16px !important;
  }

  .image-gallery-bullets .image-gallery-bullets-container {
    position: absolute;
    top: -4em !important;
  }
`;

const galleryOptions = {
  thumbnailPosition: "bottom",
  showPlayButton: true,
  showFullscreenButton: true,
  showBullets: false,
  showThumbnails: true
};

interface IGalleryItem {
  original: string;
  thumbnail: string;
  renderItem?: () => JSX.Element;
}

const getMediaFromVehicle = (data: any) => {
  const imagesArray = data?.mm?.u || [];
  const videoUrl = data?.mm?.video || "";
  const image360In = data?.mm?.["360in"] || "";
  const image360Out = data?.mm?.["360out"] || "";
  const twinnerUrl = "";
  const spinnerUrl = "";
  // const twinnerUrl = "https://spinner.photo-motion.com/66fe8b7c-e35e-426e-9088-31d0b3812d3c";
  // const spinnerUrl = "https://spinner.photo-motion.com/66fe8b7c-e35e-426e-9088-31d0b3812d3c";

  const items: IGalleryItem[] = [];

  //helper to add a media to the gallery item
  const addMediaItem = (original: string, thumbnail: string, renderItem?: () => JSX.Element) => {
    items.push({
      original: original,
      thumbnail: thumbnail,
      renderItem
    });
  };

  //twinner
  if (twinnerUrl) {
    addMediaItem(placeholderSpinner, placeholderSpinner, () => (
      <div className="image-gallery-image twinner-container">
        <iframe src={twinnerUrl} width="100%" height="100%" allowFullScreen />
      </div>
    ));
  }

  //spinner
  if (spinnerUrl) {
    addMediaItem(placeholderSpinner, placeholderSpinner, () => (
      <div className="image-gallery-image spinner-container">
        <iframe src={spinnerUrl} width="100%" height="100%" allowFullScreen />
      </div>
    ));
  }

  //youtube
  if (videoUrl) {
    const videoId = new URL(videoUrl).searchParams.get("v");
    if (videoId) {
      addMediaItem(placeholderVideo, placeholderVideo, () => (
        <div className="image-gallery-image video-container">
          <iframe width="100%" height="auto" src={`https://www.youtube-nocookie.com/embed/${videoId}?privacy-enhanced=true&samesite=none`} frameBorder="0" allowFullScreen title="Embedded youtube" sandbox="allow-scripts allow-same-origin allow-presentation" />
        </div>
      ));
    }
  }

  //normal images
  imagesArray.forEach((url: string) => {
    const originalUrl = getBackend(url).replace("format=l", "format=m");
    const thumbnailUrl = originalUrl.replace("format=m", "format=s");
    addMediaItem(originalUrl, thumbnailUrl);
  });

  //360 degree image (Exterior)
  if (image360Out.length > 0) {
    const image360OutWithUrls = image360Out.map((image: string) => getBackend(image));
    addMediaItem(placeholder360Out, placeholder360Out, () => <Exterior360View images={image360OutWithUrls} />);
  }

  //360 degree image (Interior)
  if (image360In) {
    const urlImage360In = getBackend(image360In);
    addMediaItem(placeholder360In, placeholder360In, () => <PanoViewer tag="div" image={urlImage360In} useZoom={false} cubemapConfig={{ tileConfig: { flipHorizontal: true, rotation: 0 } }} style={{ width: "100%", height: "500px" }} />);
  }

  return items;
};

const VehicleGallery: FC<{ data: IVehicleDetailed | IVehicleListItem }> = ({ data }) => {
  const [galleryItems, setGalleryItems] = useState<IGalleryItem[]>(() => getMediaFromVehicle(data));
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const placeholder = getPlaceholder(data);

  const memoizedGalleryItems = useMemo(() => getMediaFromVehicle(data), [data]);

  useEffect(() => {
    setGalleryItems(memoizedGalleryItems);
    setLoading(true);

    // create image objects to track loading state
    const imageElements = memoizedGalleryItems
      .map((item) => {
        if (!item.renderItem) {
          const img = new Image();
          img.src = item.original;
          return img;
        }
        return null;
      })
      .filter(Boolean);

    let loadedCount = 0;

    const handleImageLoad = () => {
      loadedCount++;
      if (loadedCount === imageElements.length) {
        setLoading(false);
      }
    };

    //add handler when not already loaded
    imageElements.forEach((img) => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener("load", handleImageLoad);
        img.addEventListener("error", handleImageLoad);
      }
    });

    //cleanuo
    return () => {
      imageElements.forEach((img) => {
        img.removeEventListener("load", handleImageLoad);
        img.removeEventListener("error", handleImageLoad);
      });
    };
  }, [memoizedGalleryItems]);

  const renderIconButton = (onClick: React.MouseEventHandler<HTMLButtonElement>, icon: JSX.Element, position: { top: string; right: string }) => (
    <IconButton
      color="primary"
      onClick={onClick}
      sx={{
        position: "absolute",
        top: position.top,
        right: position.right,
        zIndex: 100,
        p: 0
      }}
    >
      {icon}
    </IconButton>
  );

  const renderNavButton = (onClick: React.MouseEventHandler<HTMLElement>, disabled: boolean, isLeft: boolean) => (
    <Button
      className={`image-gallery-${isLeft ? "left" : "right"}-nav`}
      disabled={disabled}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      style={{
        position: "absolute",
        top: "50%",
        [isLeft ? "left" : "right"]: "0.5em",
        height: "100px",
        zIndex: 100,
        transform: "translateY(-50%)"
      }}
    >
      {isLeft ? <ArrowBackIosNewIcon fontSize="large" /> : <ArrowForwardIosIcon fontSize="large" />}
    </Button>
  );

  return (
    <>
      <GlobalStyles theme={theme} />
      <Card>
        <Grid sx={{ position: "relative" }}>
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000
              }}
            >
              <CircularProgress size={140} />
            </Box>
          )}
          <ImageGallery
            items={galleryItems}
            lazyLoad
            showIndex
            showBullets={galleryOptions.showBullets}
            infinite
            showThumbnails={galleryOptions.showThumbnails}
            showFullscreenButton={galleryOptions.showFullscreenButton}
            showPlayButton={galleryOptions.showPlayButton}
            renderLeftNav={(onClick, disabled) => renderNavButton(onClick, disabled, true)}
            renderRightNav={(onClick, disabled) => renderNavButton(onClick, disabled, false)}
            renderPlayPauseButton={(onClick, isPlaying) => renderIconButton(onClick, isPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />, { top: "0.5em", right: "2em" })}
            renderFullscreenButton={(onClick, isFullscreen) => renderIconButton(onClick, isFullscreen ? <FullscreenExitIcon fontSize="large" /> : <FullscreenIcon fontSize="large" />, { top: "0.5em", right: "0.5em" })}
          />
        </Grid>
      </Card>
      {galleryItems.length === 0 && (
        <Card
          sx={{
            height: "100%",
            minHeight: "723px",
            width: "100%",
            backgroundImage: `url(${placeholder})`,
            backgroundSize: "100% auto",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}
        />
      )}
    </>
  );
};

export default VehicleGallery;
