import { useDispatch } from "react-redux";
import { SET_CUSTOMER_DOMAIN, SET_SELECTED_CAR } from "./redux/constants/index";
import { IFacets } from "@Interface/IFacets";
import { IRanges } from "@Interface/IRanges";
import { ThemeOptions } from "@mui/material/styles";
import { ISelectData } from "@Interface/ISelectData";

export const GetCustomerDataFromApi = () => {
  const dispatch = useDispatch();
  const data = window.location.origin;

  dispatch({
    type: SET_CUSTOMER_DOMAIN,
    payload: data,
  });

  dispatch({
    type: SET_SELECTED_CAR,
    payload: "",
  });
};

const InitialValues = (): any => {
  return {
    customerConfiguration: {
      domain: "",
      name: "",
      language: "",
      demo: false,
      logoUrl: "",
      dsUrl: "",
      colorP: "",
      colorS: "",
      quickSearch: "",
      vehicleList: "",
      vehicleListFilter: "",
      detailSearch: "",
      vehicleSlider: "",
      vehicleDetail: "",
      scrollToTop: false,
      small: {
        visible: false,
        sliderWithPoints: false,
      },
      medium: {
        visible: false,
        sliderWithPoints: false,
      },
      large: {
        visible: false,
        sliderWithPoints: false,
      },
      isSmallOpen: false,
      resultPageAfterQuickSearch: "",
      resultPageAfterDetailSearch: "",
      width: "",
      style: {} as ThemeOptions,
    },
    customerDomain: "",
    searchLink: "",
    searchObject: {} as ISelectData,
    searchObjectNames: {},
    facets: {} as IFacets,
    ranges: {} as IRanges,
    searchResult: {},
    selectedCarLink: "",
    bearerToken: localStorage.getItem("bearerToken") || "",
  };
};

export default InitialValues;
