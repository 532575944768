import React, { useContext, useEffect } from "react";
import { Button, Card, CardContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { appendAIfNotPresent, findBrandById } from "../tools/tools";
import { DataArrayContext } from "../contexts/dataArrayContext";
import { t } from "i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { createQueryParams, parseQueryParams } from "@/tools/tools";

const FilterReset = (searchParameters: ISearchParametersProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const facets = useSelector((state: RootState) => state.ConfigurationReducer.facets);
  let updatedSearchObject = { ...searchParameters.searchObject };

  const addParameterToUrl = () => {
    const queryParams = createQueryParams(updatedSearchObject);
    navigate({ search: `?${queryParams}` });
  };
  useEffect(() => {
    // addParameterToUrl();
  }, [searchParameters.searchObject]);

  const dataArray = useContext(DataArrayContext);
  const kWtoPS = (value: number) => Math.round(value * 1.35962);

  const handleResetClick = (key: keyof ISearchParametersProps["searchObject"], item: any) => {
    if (Array.isArray(updatedSearchObject[key])) {
      const filteredArray = (updatedSearchObject[key] as Array<any>).filter((value) => value !== item);
      if (filteredArray.length === 0) {
        delete updatedSearchObject[key];
      } else {
        updatedSearchObject[key] = filteredArray;
      }
    } else {
      delete updatedSearchObject[key];
    }
    searchParameters.setSearchObject(updatedSearchObject);
  };

  const formatValue = (key: string, value: any): string => {
    // Null- oder undefined-Werte nicht verarbeiten
    if (value == null) return "";

    let formattedValue = value.toString();
    switch (key) {
      case "kmF":
      case "kmT":
        // Formatierung für Kilometer
        formattedValue = `${Number(value).toLocaleString("de-DE")} km`;
        break;
      case "spF":
      case "spT":
        // Formatierung für Preis
        formattedValue = `${Number(value).toLocaleString("de-DE")} €`;
        break;
      case "seF":
      case "seT":
        // Formatierung für Sitze
        formattedValue = `${Number(value).toLocaleString("de-DE")} St.`;
        break;
      case "poF":
      case "poT":
        // Formatierung für Leistung
        formattedValue = `${kWtoPS(Number(value))} ps / ${Number(value).toLocaleString("de-DE")} kw`;
        break;
      case "rgF":
      case "rgT":
        // Formatierung für Motasrate
        formattedValue = `${Number(value).toLocaleString("de-DE")} €`;
        break;
      case "wgF":
      case "wgT":
        // Formatierung für Gewicht
        formattedValue = `${Number(value).toLocaleString("de-DE")} Kg`;
        break;
      case "bcF":
      case "bcT":
        // Formatierung für Gewicht
        formattedValue = `${Number(value).toLocaleString("de-DE")} Wh`;
        break;
    }
    return formattedValue;
  };

  // Definieren Sie eine Liste von Schlüsseln, bei denen nur der Markenname angezeigt werden soll
  const keysToShowOnlyBrandName = ["st", "ty", "ma", "mo", "bo", "mt", "tr", "ci", "spo", "ai", "qs", "ec", "ef", "es", "doK", "co", "anr", "lo", "fh", "fm", "ws", "bk", "bc", "bm", "gt", "ng", "sf", "mp", "bp", "fs", "con", "mcn", "du", "wg"];
  return (
    <>
      <Card sx={{ mt: 0, mb: 0, p: 0, border: "none", display: { xs: "none", sm: "block" } }}>
        <CardContent sx={{ mb: 0, pb: "0 !important" }}>
          {Object.entries(updatedSearchObject)
            .filter(([key]: [string, number[]]) => key !== "sort" && key !== "sortd")
            .flatMap(([key, value]: [string, number[]]) => {
              const values: Array<number> = Array.isArray(value) ? value : [value];
              return values.flatMap((item: number | number[] | string, index: number) => {
                let brandName = "";
                let displayValue = "";

                // Finden Sie den Markennamen basierend auf dem Schlüssel und dem Item
                if ((key === "doK" || key === "ai") && typeof item !== "undefined") {
                  brandName = findBrandById(facets[key], item as number)[0] || "";

                  // Daten aus dataArray
                  if (!brandName) {
                    const tabsData = dataArray.equipmentsCategory?.flatMap((equitmens) => equitmens.equipments);
                    brandName = tabsData?.find((items) => items?.id === item)?.name ?? "";
                  }
                } else if (typeof item !== "undefined") {
                  brandName = findBrandById(facets[appendAIfNotPresent(key)], item as number)[0] || "";
                }

                // Wenn ty Type gefiltert wird. Neue Chip mit brandName anzeigen
                if (key === "ty") {
                  switch (item) {
                    case 1:
                      brandName = "PKW";
                      break;
                    case 2:
                      brandName = "LKW";
                      break;
                    case 6:
                      brandName = "e-Bike";
                      break;
                  }
                }

                // Überprüfen Sie, ob brandName ein gültiger Wert ist
                brandName = brandName || "";
                const formattedItemValue = formatValue(key, item);

                // Entscheiden, ob nur der Markenname oder die gesamte Kombination angezeigt werden soll
                if (keysToShowOnlyBrandName.includes(key)) {
                  displayValue = brandName;
                } else {
                  // Standardmäßig den Schlüssel als Anzeigename verwenden
                  let keyDisplayName = key;

                  // Benutzerfreundliche Bezeichnungen und Formatierung für spezifische Schlüssel
                  switch (key) {
                    case "kmF":
                      keyDisplayName = "Kilometer von";
                      break;
                    case "kmT":
                      keyDisplayName = "Kilometer bis";
                      break;
                    case "irF":
                      keyDisplayName = "Erstzulassung von";
                      break;
                    case "irT":
                      keyDisplayName = "Erstzulassung bis";
                      break;
                    case "spF":
                      keyDisplayName = "Preis von";
                      break;
                    case "spT":
                      keyDisplayName = "Preis bis";
                      break;
                    case "seF":
                      keyDisplayName = "Sitze von";
                      break;
                    case "seT":
                      keyDisplayName = "Sitze bis";
                      break;
                    case "poF":
                      keyDisplayName = "Leistung von";
                      break;
                    case "poT":
                      keyDisplayName = "Leistung bis";
                      break;

                    case "rgF":
                      keyDisplayName = "Monatsrate von";
                      break;
                    case "rgT":
                      keyDisplayName = "Monatsrate bis";
                      break;
                    case "wgF":
                      keyDisplayName = "Gewicht von";
                      break;
                    case "wgT":
                      keyDisplayName = "Gewicht bis";
                      break;
                    case "ngF":
                      keyDisplayName = "Anzahl der Gänge von";
                      break;
                    case "ngT":
                      keyDisplayName = "Anzahl der Gänge bis";
                      break;
                    case "bcF":
                      keyDisplayName = "Batterie-Kapazität von";
                      break;
                    case "bcT":
                      keyDisplayName = "Batterie-Kapazität bis";
                      break;
                  }

                  // Formatierung von Werten, falls vorhanden
                  if (brandName && formattedItemValue) {
                    displayValue = `${keyDisplayName} : ${formattedItemValue}`;
                  } else if (!brandName && formattedItemValue) {
                    displayValue = `${keyDisplayName} : ${formattedItemValue}`;
                  }
                }

                // Todo: Nach Bike Backend muus noch überprüft werden ob die alle Daten passen

                switch (key) {
                  // Checkboxen
                  case "cm":
                    displayValue = "Aussenfarbe: Metallic";
                    break;

                  case "co":
                    displayValue = "Aussenfarbe: " + displayValue;
                    break;

                  case "ci":
                    displayValue = "Innenfarbe: " + displayValue;
                    break;

                  case "spo":
                    displayValue = "Innenmaterial: " + displayValue;
                    break;

                  case "ma":
                    displayValue = "Hersteller: " + displayValue;
                    break;

                  // Bike
                  case "fh":
                    displayValue = "Rahmenhöhe: " + displayValue;
                    break;
                  case "fm":
                    displayValue = "Rahmen-Material: " + t(`${displayValue}` as any);
                    break;
                  case "ws":
                    displayValue = "Radgröße: " + t(`${displayValue}` as any);
                    break;
                  case "bk":
                    displayValue = "Kategorie: " + t(`${displayValue}` as any);
                    break;
                  case "bc":
                    displayValue = "Batterie-Kapazität: " + displayValue + " Wh";
                    break;
                  case "bm":
                    displayValue = ("Batterie-Hersteller: " + displayValue) as any;
                    break;
                  case "gt":
                    displayValue = "Gangschaltung: " + t(`${displayValue}` as any);
                    break;
                  case "ng":
                    displayValue = "Anzahl der Gänge: " + displayValue;
                    break;
                  case "sf":
                    displayValue = "passend für: " + displayValue;
                    break;
                  case "mp":
                    displayValue = "Motorposition: " + t(`${displayValue}` as any);
                    break;
                  case "bp":
                    displayValue = "Batterie-Position: " + t(`${displayValue}` as any);
                    break;
                  case "fs":
                    displayValue = "Rahmenform: " + t(`${displayValue}` as any);
                    break;
                  case "con":
                    displayValue = "Zustand: " + displayValue;
                    break;
                  case "mcn":
                    displayValue = "Herstellerfarbname: " + displayValue;
                    break;
                  case "du":
                    displayValue = "unreparierter Schaden";
                    break;
                  case "wg":
                    displayValue = "Gewicht: " + displayValue;
                    break;

                  // Textboxen
                  case "anr":
                    if ((item as number) > 0) {
                      displayValue = "Angebotsnummer: " + item;
                    }
                    break;

                  case "moV":
                    if (item.toString().length > 0) {
                      displayValue = "Variante: " + item;
                    }
                    break;

                  case "gd":
                    if (item.toString().length > 0) {
                      displayValue = "PLZ / Umkreis: " + item + " km";
                    }
                    break;

                  // ANGEBOTDETAILS Checkboxen
                  case "im":
                    if ((item as number) > 0) {
                      displayValue = "Inserate mit Bildern";
                    }
                    break;

                  case "new":
                    if ((item as number) > 0) {
                      displayValue = "nur neue Inserate";
                    }
                    break;

                  case "nsm":
                    if ((item as number) > 0) {
                      displayValue = "Nichtraucher-Fahrzeug";
                    }
                    break;

                  case "acn":
                    if ((item as number) > 0) {
                      displayValue = "Unfallfahrzeug";
                    }
                    break;

                  case "eu":
                    if ((item as number) > 0) {
                      displayValue = "EU-Import";
                    }
                    break;

                  case "rp":
                    if ((item as number) > 0) {
                      displayValue = "reduzierter Preis";
                    }
                    break;

                  default:
                    // Logik für nicht abgedeckte Schlüssel oder Standardverhalten
                    break;
                }

                // Überspringen der Erstellung des Buttons, wenn displayValue leer ist
                if (!displayValue) return null;

                return (
                  <Button size="small" variant="outlined" key={`${key}-${index}`} sx={{ mr: 1, mb: 1, pl: 1 }} onClick={() => handleResetClick(key, item)}>
                    {displayValue}
                    <CloseIcon sx={{ ml: 1 }} />
                  </Button>
                );
              });
            })}
        </CardContent>
      </Card>
    </>
  );
};

export default FilterReset;
