/* eslint-disable */
// @ts-nocheck
//TODO: Das muss evtl nochmal alles neu gemacht werden. Bis dahin ignoriere die Typescript Fehler. Es funktioniert trotzdem gerade so

import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import SliderInputElement from "@/components/Forms/SliderInputElement";
import SliderTextBoxElements from "@/components/Forms/SliderTextBoxElements";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { IMark } from "@/interfaces/ISliderPropsNeu";
import useStore from "@store";

const FirstRegistrationYearComponentRangeSlider: React.FC<ISearchParametersProps> = ({ ...searchParameters }, locale) => {
  const { rg } = useStore();

  const [unit] = useState("");
  const [textBoxValues, setTextBoxValues] = useState<number[]>([]);
  const [sliderValues, setSliderValues] = useState<number[]>([]);
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (rg && rg.irA && rg.irA.length > 0) {
      // Initialisiere nur, wenn es noch nicht initialisiert wurde
      if (!isInitialized) {
        const initialIndexes = [0, rg.irA.length - 1];
        setTextBoxValues([rg.irA[initialIndexes[0]], rg.irA[initialIndexes[1]]]);
        setSliderValues(initialIndexes);
        setInitialized(true);
      } else {
        // Hole die aktuellen Werte für irF und irT
        const currentSeF = searchParameters.searchObject.irF;
        const currentSeT = searchParameters.searchObject.irT;

        // Finde die Indizes für irF und irT im rg.irA Array
        const seFIndex = currentSeF !== undefined ? rg.irA.findIndex((item) => item === currentSeF) : -1;
        const seTIndex = currentSeT !== undefined ? rg.irA.findIndex((item) => item === currentSeT) : -1;

        // Aktualisiere textBoxValues und sliderValues basierend auf dem Vorhandensein von irF und irT
        const newTextBoxValues = [...textBoxValues];
        const newSliderValues = [...sliderValues];

        if (currentSeF !== undefined && seFIndex !== -1) {
          newTextBoxValues[0] = rg.irA[seFIndex];
          newSliderValues[0] = seFIndex;
        } else if (currentSeF === undefined) {
          // Setze den ersten Wert zurück, wenn irF gelöscht wurde
          newTextBoxValues[0] = rg.irA[0];
          newSliderValues[0] = 0;
        }

        if (currentSeT !== undefined && seTIndex !== -1) {
          newTextBoxValues[1] = rg.irA[seTIndex];
          newSliderValues[1] = seTIndex;
        } else if (currentSeT === undefined) {
          // Setze den letzten Wert zurück, wenn irT gelöscht wurde
          newTextBoxValues[1] = rg.irA[rg.irA.length - 1];
          newSliderValues[1] = rg.irA.length - 1;
        }

        // Aktualisiere die Zustände nur, wenn sich die Werte tatsächlich geändert haben
        if (textBoxValues[0] !== newTextBoxValues[0] || textBoxValues[1] !== newTextBoxValues[1]) {
          setTextBoxValues(newTextBoxValues);
        }
        if (sliderValues[0] !== newSliderValues[0] || sliderValues[1] !== newSliderValues[1]) {
          setSliderValues(newSliderValues);
        }
      }
    }
  }, [rg, searchParameters.searchObject, isInitialized, textBoxValues, sliderValues]);

  // die ToolTips am Slider
  let marksTT: IMark[];
  // die Beschriftungen unter dem Slider
  let marks: IMark[];

  if (!rg || !rg.irA || rg.irA.length === 0) {
    // Verwendung von CircularProgress, bis die rg verfügbar ist
    return <CircularProgress />;
  } else {
    // die ToolTips am Slider
    marksTT = rg.irA.map((value, index) => ({
      value: index,
      label: `${value} ${unit}`
    }));
    // die Beschriftungen unter dem Slider
    marks = [marksTT[0], marksTT[rg.irA.length - 1]];
  }

  const handleChange = (newValue: number[]) => {
    setSliderValues(newValue);
    const newValues = newValue.map((index) => rg.irA[index]);
    searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      irF: newValues[0],
      irT: newValues[1]
    }));
  };

  return (
    <Grid container>
      <SliderTextBoxElements unit={unit} textBoxValues={textBoxValues} locale={locale} noformat={true} />
      <SliderInputElement onChange={handleChange} data={sliderValues} marks={marks} marksToolTip={marksTT} />
    </Grid>
  );
};

export default FirstRegistrationYearComponentRangeSlider;
