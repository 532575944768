import React from "react";
import { Grid, Typography } from "@mui/material";
import ConsumptionProperty from "./ConsumptionProperty";
import { IVerbrauch } from "@/interfaces/IVehicleDetailed";

const VehicleConsumption: React.FC<{ data: IVerbrauch; title: string }> = ({ data, title }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} container direction="column" alignItems="left" sx={{ maxWidth: "600px", mt: 3 }}>
        <Typography variant="h6" component="div" align="left">
          {title}:
        </Typography>

        {data?.wc && <ConsumptionProperty title="gewichtet, kombiniert" property={data?.wc} />}
        {data?.c && <ConsumptionProperty title="kombiniert" property={data?.c} />}
        {data?.i && <ConsumptionProperty title="Innenstadt" property={data?.i} />}
        {data?.p && <ConsumptionProperty title="Stadtrand" property={data?.p} />}
        {data?.r && <ConsumptionProperty title="Landstraße" property={data?.r} />}
        {data?.h && <ConsumptionProperty title="Autobahn" property={data?.h} />}
      </Grid>
    </Grid>
  );
};

export default VehicleConsumption;
