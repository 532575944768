import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ISelectData } from "@/interfaces/ISelectData";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import FilterReset from "@/components/Filter/FilterReset";
import useStore from "@store";

import UndoIcon from "@mui/icons-material/Undo";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const SearchButtonAreaComponent: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { customerConfiguration } = useStore();
  const [hasFilter, setHasFilter] = useState(false);
  const navigate = useNavigate();
  const [, /* searchClick */ setSearchclick] = useState(false);

  const searchHandleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setSearchclick(true);
    navigate(customerConfiguration.vehicleList);
  };

  const resetHandleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    setSearchclick(true);
    const pathName = new URL(window.location.href).pathname;
    navigate(pathName);
    searchParameters.setSearchObject((prev) => (prev = {} as ISelectData));
  };

  function searchHasFilter(obj: { [key: string]: any }): boolean {
    const keys = Object.keys(obj);
    const filteredKeys = keys.filter((key) => key !== "sort" && key !== "sortd");
    return filteredKeys.length > 0;
  }

  useEffect(() => {
    setHasFilter(searchHasFilter(searchParameters?.searchObject));
  }, [searchParameters]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={8} md={10} sx={{ display: { xs: "none", sm: "block" } }}>
          {hasFilter && <FilterReset {...searchParameters}></FilterReset>}
        </Grid>

        <Grid container item xs={12} sm={4} md={2} alignContent={"center"} justifyContent={"center"}>
          <Grid item xs={12}>
            <Button variant="contained" onClick={searchHandleClick} startIcon={<SearchOutlinedIcon />} fullWidth sx={{ height: "40px" }}>
              {searchParameters.vehicleObject.count} Treffer
            </Button>
          </Grid>
          {hasFilter && (
            <Grid item xs={12} mt={1} sx={{ display: { xs: "none", sm: "block" } }}>
              <Button variant="outlined" onClick={resetHandleClick} startIcon={<UndoIcon />} fullWidth sx={{ height: "30px" }}>
                Zurücksetzen
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SearchButtonAreaComponent;
