import React from "react";
import { Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, Box, Link } from "@mui/material";
import { Phone, Email } from "@mui/icons-material";
import FaxIcon from "@mui/icons-material/Fax";
import { IContact } from "@Interface/IContact";
import { IVehicleDetailed } from "@Interface/IVehicleDetailed";
import avatarPlaceholder from "@/assets/img/placeholder-avatar.png";
import { formatPhoneNumber } from "@/tools/tools";

const ContactPanel: React.FC<{ contact: IContact; carData: IVehicleDetailed; showDetails?: boolean }> = ({ contact, carData, showDetails = true }) => {
  const boxAttr = {
    sx: { display: "flex", alignItems: "center", mt: 1 },
  };

  const iconAttr = {
    sx: { mr: 1, fontSize: "20px" },
  };

  const emailSubject = `Angebotsnummer ${carData.on}: ${carData.ma} ${carData.na}`;

  return (
    <List sx={{ py: 0 }}>
      <ListItem alignItems="flex-start" sx={{ py: 0 }}>
        <ListItemAvatar>
          <Avatar alt={`${contact.FirstName} ${contact.LastName}`} src={contact.Image ? contact.Image : avatarPlaceholder} />
        </ListItemAvatar>
        <ListItemText
          primary={`${contact.Salutation} ${contact.FirstName} ${contact.LastName}`}
          secondary={
            <>
              <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                {contact.Position}
              </Typography>

              {showDetails && contact.Phone?.number && (
                <Box component="span" {...boxAttr}>
                  <Phone {...iconAttr} />
                  <Link href={`tel:${contact.Phone?.prefix}${contact.Phone?.number}`} underline="none" color="primary">
                    {contact.Phone?.prefix} {formatPhoneNumber(contact.Phone?.number)}
                  </Link>
                </Box>
              )}
              {showDetails && contact.Fax?.number && (
                <Box component="span" {...boxAttr}>
                  <FaxIcon {...iconAttr} />
                  {contact.Fax?.prefix} {formatPhoneNumber(contact.Fax?.number)}
                </Box>
              )}
              {showDetails && contact.Email && (
                <Box component="span" {...boxAttr}>
                  <Email {...iconAttr} />
                  <Link href={`mailto:${contact.Email}?subject=${emailSubject}`} underline="none" color="primary">
                    {contact.Email}
                  </Link>
                </Box>
              )}
            </>
          }
        />
      </ListItem>
    </List>
  );
};

export default ContactPanel;
