import React, { useEffect, useState, Suspense } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import SetLinkParamsToSearchObject from "../redux/api/SetLinkParamsToSearchObject";
import "react-toastify/dist/ReactToastify.css";
import { getBackendApi } from "../tools/backendApis";
import { GetFacettenFromApi } from "../redux/api/GetFacettenFromApi";
import { DataArrayContext } from "../contexts/dataArrayContext";
import GetDataObject from "../redux/api/DataObject";
import { SET_SEARCH_LINK } from "../redux/constants/index";
import { ISelectData } from "@Interface/ISelectData";
import { IEquipmentCategory } from "@Interface/IEquipment";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { IData } from "@Interface/IData";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { IAllData } from "@Interface/IAllData";
import NotFoundPage from "./NotFoundPage";
import { SET_VAT } from "@/redux/constants/index";
import { createQueryParams, parseQueryParams } from "@/tools/tools";

//views
import QuickSearch from "@/views/QuickSearch/QuickSearch";
import VehicleList from "@/views/VehiclesList/VehicleList";
import VehicleDetail from "@/views/VehicleDetail/VehicleDetail";
import EnlargedSearch from "@/views/EnlargedSearch/EnlargedSearchComponent";
import VehicleSlider from "@/views/VehicleSlider/VehicleSlider";
import Configurator from "@/views/Admin/Configurator";
import Login from "@/views/Admin/Login";

interface MainContainerProps {
  equipments: IEquipmentCategory[];
  customerConfig: ICustomerConfiguration;
}

const MainContainer: React.FC<MainContainerProps> = ({ equipments, customerConfig }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [width] = useState(customerConfig.width);
  const [searchObject, setSearchObject] = useState<ISelectData>(
    // parseQueryParams(location.search) ||
    {
      sort: "new",
      sortd: "DESC",
    } as ISelectData
  );

  useEffect(() => {
    dispatch({ type: SET_SEARCH_LINK, payload: location });
  }, [dispatch, location, customerConfig.domain, customerConfig.width]);

  const [onlyFacetten, setOnlyFacetten] = useState(true);

  const url = getBackendApi("/");
  const pathName = location.pathname;
  useEffect(() => {
    setOnlyFacetten(pathName === customerConfig.quickSearch || pathName === customerConfig.detailSearch);
  }, [pathName, customerConfig]);

  const data: IData = GetFacettenFromApi(searchObject, dispatch, url, onlyFacetten);
  const alldata: IAllData = GetDataObject(data, equipments);

  const commonSearchParameters: ISearchParametersProps = {
    previousSelectData: {} as ISelectData,
    searchObject: searchObject,
    setSearchObject: setSearchObject,
    vehicleObject: { count: alldata.count, cars: alldata.cars },
  };

  //set current vat value
  useEffect(() => {
    if (data && data.searchResult && Object.keys(data).length) {
      dispatch({ type: SET_VAT, payload: data.searchResult.cV });
    }
  }, [dispatch, data]);

  if (Object.entries(searchObject).length === 0) {
    SetLinkParamsToSearchObject(searchObject, location);
  }

  return (
    <>
      <style>
        {`
          #mpi-carzilla-script-container .App {
            max-width: ${width};
          }
        `}
      </style>
      <DataArrayContext.Provider value={alldata as any}>
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route path={customerConfig.quickSearch} element={<QuickSearch {...commonSearchParameters} />} />
            <Route path={`${customerConfig.vehicleDetail}/:vehicleTy/:vehicleId/:vehicleH?/:vehicleM?/:vehicleNa?/:vehicleEn?/:vehicleTr?`} element={<VehicleDetail searchParameters={{ ...commonSearchParameters }} />} />
            <Route path={customerConfig.vehicleList} element={<VehicleList {...commonSearchParameters} />} />
            <Route path={customerConfig.detailSearch} element={<EnlargedSearch {...commonSearchParameters} />} />
            <Route path={customerConfig.vehicleSlider} element={<VehicleSlider {...commonSearchParameters} width={width} />} />
            <Route path={"admin/config"} element={<Configurator customerConfig={customerConfig} />} />
            <Route path={"admin/login"} element={<Login />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
        <ToastContainer />
      </DataArrayContext.Provider>
    </>
  );
};

export default MainContainer;
