import React, { useState, useEffect } from "react";
import { IVehicleDetailed } from "@/interfaces/IVehicleDetailed";
import { Typography, Table, TableBody, TableCell, TableRow, Slider, Card, Grid } from "@mui/material";
import { formatNumber, formatPrice } from "@/tools/tools";
import { getFinancingData } from "@/tools/vehicleDataHandler";

const FinancingCalculator: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  const initialFinancingData = getFinancingData(data);

  const [financing, setFinancing] = useState(initialFinancingData);

  const calculateFinancing = () => {
    const z = financing.EffectiveRate / 100;
    const y = (financing.BalloonPayment * 100 * ((24 * z) / (24 + 11 * z))) / 1200;
    const x = financing.PurchasePrice - financing.Deposit - financing.BalloonPayment;
    const w = (x * (Math.pow(1 + z, 1 / 12) - 1)) / (1 - Math.pow(1 + z, -financing.Duration / 12));

    const monthlyInstalment = w + y;
    const netOfTransactionCosts = financing.PurchasePrice - financing.Deposit;
    const totalCreditAmount = financing.Duration * (w + y) + financing.BalloonPayment;

    setFinancing((prev) => ({
      ...prev,
      MonthlyPayment: monthlyInstalment,
      NetCredit: netOfTransactionCosts,
      GrossCredit: totalCreditAmount
    }));
  };

  useEffect(() => {
    calculateFinancing();
  }, [financing.Deposit, financing.Duration]);

  const maxDeposit = financing.PurchasePrice - financing.BalloonPayment;

  const renderNormalRow = (name: string, value: string) => {
    return (
      <TableRow>
        <TableCell>
          <Typography variant="body1" component="div">
            {name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" component="div">
            {value}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Card sx={{ overflow: "unset" }}>
      <Grid
        item
        xs={12}
        id="FinanceHeadline"
        sx={{
          bgcolor: "primary.main",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px"
        }}
      >
        <Typography variant="h2" component="div" align="center" color="white" sx={{ lineHeight: 1.5 }}>
          <strong>Finanzierung</strong>
        </Typography>
      </Grid>
      <Table>
        <TableBody sx={{ px: 5 }}>
          {renderNormalRow("Barzahlungspreis", formatPrice(financing.PurchasePrice))}
          <TableRow>
            <TableCell>
              <Typography variant="body1" component="div">
                Anzahlung
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" component="div">
                {formatPrice(financing.Deposit)}
              </Typography>
              <Slider
                value={financing.Deposit}
                min={0}
                max={maxDeposit}
                step={500}
                onChange={(e, value) =>
                  setFinancing((prev) => ({
                    ...prev,
                    Deposit: value as number
                  }))
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body1" component="div">
                Laufzeit
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" component="div">
                {formatNumber(financing.Duration)} Monate
              </Typography>
              <Slider
                value={financing.Duration}
                min={12}
                max={60}
                step={12}
                onChange={(e, value) =>
                  setFinancing((prev) => ({
                    ...prev,
                    Duration: value as number
                  }))
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h2" component="div" sx={{ fontWeight: "bold" }}>
                Monatliche Rate
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h1" component="div" sx={{ fontWeight: "bold" }} color="primary.main">
                {formatPrice(financing.MonthlyPayment)}
              </Typography>
            </TableCell>
          </TableRow>
          {renderNormalRow("Schlussrate", formatPrice(financing.BalloonPayment))}
          {renderNormalRow("Nettokredit", formatPrice(financing.NetCredit))}
          {renderNormalRow("Effektivzins", formatNumber(financing.EffectiveRate, 2) + " %")}
          {renderNormalRow("Zinssatz gebunden p.a.", formatNumber(financing.RatePA, 2) + " %")}
          {renderNormalRow("Bruttokredit", formatPrice(financing.GrossCredit))}
          <TableRow>
            <TableCell>
              <Typography variant="body1" component="div">
                Bank
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" component="div">
                {data.Finanzierung.anbieter.n}
                <br />
                {data.Finanzierung.anbieter.s}
                <br />
                {data.Finanzierung.anbieter.o}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid item xs={12} id="FinanceSubline" mt={2} sx={{ borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
        <Typography variant="subtitle2" component="div" align="center" color="black" sx={{ lineHeight: 2 }}>
          Wichtiger Hinweis: Diese Berechnung gilt als Muster und stellt somit kein Angebot dar. Wir übernehmen keine Gewähr für das Ergebnis der Berechnung.
        </Typography>
      </Grid>
    </Card>
  );
};

export default FinancingCalculator;
