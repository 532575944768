import React, { useState } from "react";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import PriceElement from "../../../components/PriceElement";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Divider, Paper, Tooltip, Typography, Grid } from "@mui/material";
import ImageCounterElement from "../../../components/ImageCounterElement";
import { getBackend } from "../../../tools/backendApis";
import Consumption from "@/views/VehicleDetail/Components/Consumption";
import LabelAvailable from "@/components/Labels/LabelAvailable";
import useHandleCardClick from "@/hooks/useHandleCardClick";
import { getListAttrSmall, getVehicleType, getPlaceholder } from "@/tools/vehicleDataHandler";
import { getNettoPriceString } from "@/tools/tools";

const VehicleListItem: React.FC<{ vehicle: IVehicleListItem }> = ({ vehicle }) => {
  const theme = useTheme();
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Todo Im Moment bikeImagesNumberArray und function BikeImageLinkWithSize wird nicht benutzt
  // const bikeImagesNumberArray: number[] = vehicle.imageListArrayCsv ? vehicle.imageListArrayCsv.split(",").map(Number) : [];

  // const imgUrl = "https://mpi.carzilla.de/images/v3/";
  /*
  function BikeImageLinkWithSize(imageId: number, format: string) {
    return imgUrl + imageId + "/" + format;
  }
*/
  // console.log("vehicle:", vehicle, bikeImagesNumberArray);

  const handleCardClick = useHandleCardClick(vehicle);
  const attributeData = getListAttrSmall(vehicle);
  const placeholder = getPlaceholder(vehicle);

  const renderAttributeData = () =>
    attributeData.map(
      (item, index) =>
        item.value &&
        item.icon && (
          <Grid item xs={6} key={index}>
            <Tooltip title={item.tooltip}>
              <Typography noWrap sx={{ display: "flex" }}>
                {item.icon}
                {item.value}
              </Typography>
            </Tooltip>
          </Grid>
        )
    );

  const renderPriceAndButton = () => (
    <Grid container sx={{ alignItems: "center", height: "100px" }}>
      <Grid item xs={6}>
        <PriceElement title={getNettoPriceString(vehicle?.sp, 0, vehicle?.vat)} price={vehicle.sp} priceColor={theme.palette.secondary.main} fontWeight="fontWeightBold" />
      </Grid>
      <Grid item xs={6}>
        <CardActions>
          <Button variant="contained" fullWidth>
            zum Angebot
          </Button>
        </CardActions>
      </Grid>
    </Grid>
  );

  const renderFinancingAndConsumption = () => (
    <>
      <Grid>
        <CardContent sx={{ p: 1, height: "70px", display: "flex", alignContent: "center" }}>
          <Grid container sx={{ display: "flex", alignContent: "center" }}>
            {vehicle.pf && (
              <Grid item xs={6}>
                <PriceElement title="mtl. Finanzierungsrate" price={vehicle.pf} />
              </Grid>
            )}
            {vehicle.pl && (
              <Grid item xs={6}>
                <PriceElement title="mtl. Leasing-Rate" price={vehicle.pl} />
              </Grid>
            )}
            {!vehicle.pf && !vehicle.pl && <Typography variant="body1">Fragen Sie nach unseren Finanzierungs- / Leasingangeboten.</Typography>}
          </Grid>
        </CardContent>
        <Divider />
        {vehicle?.en?.co && (
          <Grid item xs={12}>
            <Consumption carData={vehicle} size="small" />
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <Grid item xs={12} md={6} lg={4} sx={{ height: "auto" }} spacing={2}>
      <Paper
        variant="outlined"
        id={`${vehicle.li.split("/").pop()}`}
        sx={{
          position: "relative",
          cursor: "pointer",
          height: "100%",
        }}
      >
        <Card key={vehicle.on} component="div" onClick={handleCardClick} sx={{ height: "100%" }}>
          <Grid container sx={{ height: "240px", position: "relative", overflow: "hidden" }}>
            <Box sx={{ position: "absolute", right: "5px", bottom: "5px", opacity: 0.9 }}>
              <LabelAvailable carData={vehicle} />
            </Box>

            {isImageLoading && (
              <Box
                sx={{
                  width: 1,
                  minHeight: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Grid item width={"100%"}>
              <ImageCounterElement imageCount={vehicle.mm.n} />
              <CardMedia component="img" src={vehicle.mm.u && vehicle.mm.u[0] ? getBackend(`${vehicle.mm.u[0].replace("&format=l", "&format=m").replace("&format=l", "&format=m")}`) : placeholder} alt="Fahrzeugbild" loading="lazy" onLoad={() => setIsImageLoading(false)} onError={() => setIsImageLoading(false)} />
            </Grid>
          </Grid>

          <CardContent sx={{ bgcolor: theme.palette.grey[200] }}>
            <Typography noWrap variant="h2" component="div" fontWeight="fontWeightBold" sx={{ mb: 0.3 }}>
              {vehicle.h} {vehicle.m}
            </Typography>
            <Typography noWrap variant="subtitle1" component="div">
              {vehicle.na} &nbsp;
            </Typography>
          </CardContent>

          <CardContent sx={{ p: 1, minHeight: "110px" }}>
            <Grid sx={{ display: "flex", alignItems: "center" }}>
              <Grid container spacing={1}>
                {renderAttributeData()}
              </Grid>
            </Grid>
          </CardContent>

          <CardContent sx={{ bgcolor: theme.palette.grey[200], height: "70px", py: "0 !important", display: "flex", alignItems: "center" }}>{renderPriceAndButton()}</CardContent>

          {vehicle.ty !== getVehicleType(vehicle) && renderFinancingAndConsumption()}
        </Card>
      </Paper>
    </Grid>
  );
};

export default VehicleListItem;
