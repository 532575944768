import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ConsumptionNote = ({}) => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12} px={2} mt={3}>
        <Typography variant="caption">
          Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem{" "}
          <a href="https://www.dat.de/leitfaden/LeitfadenCO2.pdf" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}>
            'Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen'
          </a>{" "}
          entnommen werden, der an allen Verkaufsstellen und bei der Deutschen Automobil Treuhand GmbH unter{" "}
          <a href="http://www.dat.de/" target="_blank" rel="noreferrer" style={{ color: theme.palette.primary.main }}>
            www.dat.de
          </a>{" "}
          unentgeltlich erhältlich ist.
        </Typography>
      </Grid>
      <Grid item xs={12} px={2} mt={3}>
        <Typography variant="caption">¹ Auf der Grundlage der gemessenen CO2-Emissionen unter Be­rück­sicht­ig­ung der Masse des Fahrzeugs ermittelt.</Typography>
      </Grid>
    </Grid>
  );
};

export default ConsumptionNote;
