import React, { useContext, useState } from "react";
import { Divider, FormControl, Grid, IconButton, MenuItem, Pagination, PaginationItem, Select, Tooltip } from "@mui/material";
import { MenuContext } from "@/contexts/menuContext";
import Sort from "./Sort";

import { useTheme } from "@mui/material/styles";
import GridViewIcon from "@mui/icons-material/GridViewOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ViewAgendaIcon from "@mui/icons-material/ViewAgendaOutlined";
import ViewHeadlineOutlinedIcon from "@mui/icons-material/ViewHeadlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import useStore from "@store";

const PaginationSortingContainer: React.FC<ISearchParametersProps> = ({ reduced = false, ...searchParameters }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { toggleMenu, toggleSmall, isViewOpen, setViewOpen } = useContext(MenuContext);

  const { customerConfiguration } = useStore();

  const colorGray = useTheme().palette.grey[500];

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end"
      }}
    >
      {!reduced && (
        <>
          <IconButton color="primary" onClick={toggleMenu}>
            <Tooltip title="Fahrzeugfilter">
              <FilterAltOutlinedIcon />
            </Tooltip>
          </IconButton>

          <Divider orientation="vertical" flexItem />
        </>
      )}
      <Grid item sx={{ flexGrow: 1 }}>
        <Pagination showFirstButton showLastButton count={searchParameters.vehicleObject?.count ? Math.floor((searchParameters.vehicleObject.count + Number(searchParameters.perPage) - 1) / Number(searchParameters.perPage)) : 1} size="medium" variant="outlined" shape="rounded" page={searchParameters.page} onChange={searchParameters.handleChangePage} renderItem={(item) => <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />} />
      </Grid>

      {!reduced && (
        <Grid item sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Divider orientation="vertical" flexItem />
          <FormControl size="small" sx={{ pl: 1, pr: 1 }}>
            <Tooltip title="Fzg. pro Seite" placement="left" open={tooltipOpen}>
              <Select variant="outlined" sx={{ mt: 0, height: "2.0em" }} value={searchParameters.perPage.toString()} onChange={searchParameters.handleChangePerPage} onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)} onClick={() => setTooltipOpen(false)} onOpen={() => setTooltipOpen(false)}>
                <MenuItem value="12">12</MenuItem>
                <MenuItem value="24">24</MenuItem>
                <MenuItem value="36">36</MenuItem>
                <MenuItem value="100">100</MenuItem>
              </Select>
            </Tooltip>
          </FormControl>
          <Divider orientation="vertical" flexItem />
          {/* wegen Typescript die Color so komisch gesetzt */}
          {customerConfiguration.small.visible && (
            <IconButton
              color="primary"
              onClick={() => {
                setViewOpen(1);
                toggleSmall();
              }}
              sx={{ color: isViewOpen !== 1 ? colorGray : "primary" }}
            >
              <Tooltip title="Kachelansicht">
                <GridViewIcon />
              </Tooltip>
            </IconButton>
          )}
          {customerConfiguration.large.visible && (
            <IconButton
              color="primary"
              onClick={() => {
                setViewOpen(2);
                toggleSmall();
              }}
              sx={{ color: isViewOpen !== 2 ? colorGray : "primary" }}
            >
              <Tooltip title="Tabellenansicht">
                <ViewAgendaIcon />
              </Tooltip>
            </IconButton>
          )}
          {customerConfiguration.medium.visible && (
            <IconButton
              color="primary"
              onClick={() => {
                setViewOpen(3);
                toggleSmall();
              }}
              sx={{ color: isViewOpen !== 3 ? colorGray : "primary" }}
            >
              <Tooltip title="Listenansicht">
                <ViewHeadlineOutlinedIcon />
              </Tooltip>
            </IconButton>
          )}
          <Divider orientation="vertical" flexItem />
          <Sort {...searchParameters} />
        </Grid>
      )}
    </Grid>
  );
};

export default PaginationSortingContainer;
