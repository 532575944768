import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { Grid } from "@mui/material";
import MpiLabel from "../../../components/MpiLabel";
import InteriorColorComponentCheckbox from "../../../FormComponents/CheckBoxes/InteriorColorComponentCheckbox";
import InteriorMaterialComponentCheckbox from "../../../FormComponents/CheckBoxes/InteriorMaterialComponentCheckbox";

const InsideColorComponent: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  return (
    <>
      <MpiLabel title="Farbe der Innenausstattung" />
      <Grid container sx={{ mb: 2 }}>
        <InteriorColorComponentCheckbox {...searchParameters} showToggleButton={false} />
      </Grid>

      <MpiLabel title="Material der Innenausstattung" />
      <Grid container>
        <InteriorMaterialComponentCheckbox {...searchParameters} showToggleButton={false} />
      </Grid>
    </>
  );
};

export default InsideColorComponent;
