import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ConsumptionNote = () => {
  const theme = useTheme();

  const links = [
    {
      href: "https://www.dat.de/leitfaden/LeitfadenCO2.pdf",
      text: "Leitfaden über den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer Personenkraftwagen"
    },
    {
      href: "http://www.dat.de/",
      text: "www.dat.de"
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12} px={2} mt={3}>
        <Typography variant="caption">
          Weitere Informationen zum offiziellen Kraftstoffverbrauch und den offiziellen spezifischen CO2-Emissionen neuer Personenkraftwagen können dem{" "}
          <Link href={links[0].href} target="_blank" rel="noreferrer" sx={{ color: theme.palette.primary.main }}>
            {links[0].text}
          </Link>{" "}
          entnommen werden, der an allen Verkaufsstellen und bei der Deutschen Automobil Treuhand GmbH unter{" "}
          <Link href={links[1].href} target="_blank" rel="noreferrer" sx={{ color: theme.palette.primary.main }}>
            {links[1].text}
          </Link>{" "}
          unentgeltlich erhältlich ist.
        </Typography>
      </Grid>
      <Grid item xs={12} px={2} mt={3}>
        <Typography variant="caption">¹ Auf der Grundlage der gemessenen CO2-Emissionen unter Berücksichtigung der Masse des Fahrzeugs ermittelt.</Typography>
      </Grid>
    </Grid>
  );
};

export default ConsumptionNote;
