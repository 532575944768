import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { Grid } from "@mui/material";
import CountryComponentSelect from "../../../FormComponents/SelectBoxes/CountryComponentSelect";
import ZipCodeComponentTextBox from "../../../FormComponents/TextBoxes/ZipCodeComponentTextBox";

const ZipCodeAutocomplete: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <CountryComponentSelect {...searchParameters} />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        <ZipCodeComponentTextBox {...searchParameters} />
      </Grid>
    </Grid>
  );
};

export default ZipCodeAutocomplete;
