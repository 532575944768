import { Location } from "react-router-dom";
import { ISelectData } from "./interfaces/ISelectData";

const SetLinkParamsToSearchObject = (searchObject: ISelectData, location: Location) => {
  const queryParams = new URLSearchParams(location.search);

  if (Object.entries(searchObject).length === 0) {
    for (const param of queryParams.entries()) {
      const key = param[0];
      const values = param[1].includes(", ") ? param[1].split(", ") : [param[1]];

      if (key.trim() === "Umkreis") {
        if (values && values[0] !== undefined) {
          searchObject["gd"] = values[0];
        }
      }
      if (key.trim() === "Angebotsnummer") {
        if (values && values[0] !== undefined) {
          searchObject["anr"] = values[0];
        }
      }

      if (key.trim() === "Variante") {
        if (values && values[0] !== undefined) {
          searchObject["moV"] = values[0];
        }
      }

      if (key.trim() === "Preis von") {
        if (values && values[0] !== undefined) {
          searchObject["spF"] = Number(values[0]);
        }
      }
      if (key.trim() === "Preis bis") {
        if (values && values[0] !== undefined) {
          searchObject["spT"] = Number(values[0]);
        }
      }

      if (key.trim() === "Kilometer von") {
        if (values && values[0] !== undefined) {
          searchObject["kmF"] = Number(values[0]);
        }
      }
      if (key.trim() === "Kilometer bis") {
        if (values && values[0] !== undefined) {
          searchObject["kmT"] = Number(values[0]);
        }
      }

      if (key.trim() === "Leistung von") {
        if (values && values[0] !== undefined) {
          searchObject["poF"] = Number(values[0]);
        }
      }
      if (key.trim() === "Leistung bis") {
        if (values && values[0] !== undefined) {
          searchObject["poT"] = Number(values[0]);
        }
      }

      if (key.trim() === "EZ von") {
        if (values && values[0] !== undefined) {
          searchObject["irF"] = Number(values[0]);
        }
      }
      if (key.trim() === "EZ bis") {
        if (values && values[0] !== undefined) {
          searchObject["irT"] = Number(values[0]);
        }
      }
      /*
      for (let value of values) {
        for (let category of categorieNames) {
          if (category.name === value) {
            // Es gibt ein Model mit dem Namen Diesel desswegen auch die sonder Behandlung

            if (category.name === "mo:16429" && value === "Diesel") {
            } else {
              let categoryKey = category.name.split(":")[0];

              if (!searchObject[categoryKey]) {
                searchObject[categoryKey] = [];
              }
              let numberValue = category.name.split(":")[1];
              searchObject[categoryKey].push(parseInt(numberValue));
            }
          }
        }
 
      }
       */
    }
  }

  return searchObject;
};

export default SetLinkParamsToSearchObject;
