import React, { useState } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MpiLabel from "../../components/MpiLabel";
import { FormControl, TextField } from "@mui/material";

const VariantComponentTextBox: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const [, setVariantValue] = useState("");

  const handleVariantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVariantValue(event.target.value);

    void searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      moV: event.target.value,
    }));
  };

  return (
    <FormControl fullWidth>
      <MpiLabel title="Variante" />
      <TextField fullWidth size="small" id="Variante" placeholder="z.B. GTI ..." multiline variant="outlined" value={searchParameters.searchObject?.moV || ""} onChange={handleVariantChange} />
    </FormControl>
  );
};

export default VariantComponentTextBox;
