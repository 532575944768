import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Container from "@mui/material/Container";
import { Card, CardContent, CardHeader, Drawer, Grid, SelectChangeEvent, Typography, Snackbar, Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import VehicleSmall from "../VehicleSmall/VehicleSmall";
import PaginationSortingContainer from "../../components/PaginationSortingContainer";
import ScrollToTop from "../../components/ScrollToTop";
import FilterReset from "../../components/FilterReset";
import useContainerWidth from "../../hooks/useContainerWidth";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import { MenuContext } from "../../contexts/menuContext";
import VehicleMedium from "../VehicleMedium/VehicleMedium";
import VehicleLarge from "../VehicleLarge/VehicleLarge";
import { usePagination } from "../../contexts/paginationContext";
import BranchComponentSelect from "../../FormComponents/SelectBoxes/BranchComponentSelect";
import { getGridSize1920 } from "../../tools/tools";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { IFacets } from "@Interface/IFacets";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MileageComponentRangeSlider from "../../FormComponents/RangeSlider/MileageComponentRangeSlider";
import PriceComponentRangeSlider from "../../FormComponents/RangeSlider/PriceComponentRangeSlider";
import PerformanceComponentRangeSlider from "../../FormComponents/RangeSlider/PerformanceComponentRangeSlider";
import VehicleTypeComponentCheckbox from "../../FormComponents/CheckBoxes/VehicleTypeComponentCheckbox";
import VehicleConditionComponentCheckbox from "../../FormComponents/CheckBoxes/VehicleConditionComponentCheckbox";
import ManufacturerComponentCheckbox from "../../FormComponents/CheckBoxes/ManufacturerComponentCheckbox";
import ModelComponentCheckbox from "../../FormComponents/CheckBoxes/ModelComponentCheckbox";
import FuelComponentCheckbox from "../../FormComponents/CheckBoxes/FuelComponentCheckbox";
import EngineGearboxComponentCheckbox from "../../FormComponents/CheckBoxes/EngineGearboxComponentCheckbox";
import EquipmentComponentCheckbox from "../../FormComponents/CheckBoxes/EquipmentComponentCheckbox";
import BikeCategoryComponentCheckbox from "../../FormComponents/CheckBoxes/BikeCategoryComponentCheckbox";
import BikeWheelSizeComponentCheckbox from "../../FormComponents/CheckBoxes/BikeWheelSizeComponentCheckbox";
import BikeGearTypeComponentCheckbox from "../../FormComponents/CheckBoxes/BikeGearTypeComponentCheckbox";
import BikeBatteryManufacturerComponentCheckbox from "../../FormComponents/CheckBoxes/BikeBatteryManufacturerComponentCheckbox";
import BikeFrameMaterialComponentCheckbox from "../../FormComponents/CheckBoxes/BikeFrameMaterialComponentCheckbox";
import BikeFrameShapeComponentCheckbox from "../../FormComponents/CheckBoxes/BikeFrameShapeComponentCheckbox";
import BikeColorComponentCheckbox from "../../FormComponents/CheckBoxes/BikeColorComponentCheckbox";
import BikeMotorPositionComponentCheckbox from "../../FormComponents/CheckBoxes/BikeMotorPositionComponentCheckbox";
import BikeWeightComponentRangeSlider from "../../FormComponents/RangeSlider/BikeWeightComponentRangeSlider";
import BikeNumberOfGearsComponentRangeSlider from "../../FormComponents/RangeSlider/BikeNumberOfGearsComponentRangeSlider";
import BikeBatteryCapacityWhComponentRangeSlider from "../../FormComponents/RangeSlider/BikeBatteryCapacityWhComponentRangeSlider";
import BikeBatteryPositionComponentCheckbox from "../../FormComponents/CheckBoxes/BikeBatteryPositionComponentCheckbox ";
import ConsumptionNote from "@/components/ConsumptionNote";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BikeFrameHeightComponentRangeSlider from "@/FormComponents/RangeSlider/BikeFrameHeightComponentRangeSlider";
import ExteriorColorComponent from "../EnlargedSearch/components/ExteriorColorComponent";

const VehicleList: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { isMenuOpen, toggleMenu, isSmallOpen, isViewOpen } = useContext(MenuContext);
  const [vehicles, setVehicles] = useState<IVehicleListItem[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);
  const facets = useSelector((state: RootState) => state.ConfigurationReducer.facets as IFacets);
  const { page, setPage, perPage, setPerPage } = usePagination();
  const { containerRef, width } = useContainerWidth();

  const location = useLocation();
  const state = location.state as { from?: string; reason?: string };
  const { t } = useTranslation();

  useEffect(() => {
    if (state?.reason === "not-found") {
      setOpenSnackbar(true);
    }
  }, [state]);

  // console.log("facets", facets);

  let hasBike, hasPKW, hasLKW;
  if (facets?.ty) {
    const types = Object.keys(facets.ty);
    hasBike = types.some((typ) => typ.includes("e-Bike"));
    hasPKW = types.some((typ) => typ.includes("PKW"));
    hasLKW = types.some((typ) => typ.includes("LKW"));
  }

  const gridSize = getGridSize1920(width, {
    xl: 2,
    lg: 3,
    md: 4,
    sm: 6,
    xs: 12,
  });

  useEffect(() => {
    if (searchParameters.vehicleObject?.cars && typeof searchParameters.vehicleObject.count === "number") {
      const tempVehicles: IVehicleListItem[] = [];
      const startIndex = (page - 1) * Number(perPage);
      const endIndex = Math.min(startIndex + Number(perPage), searchParameters.vehicleObject.cars.length);
      for (let index = startIndex; index < endIndex; index++) {
        const element = searchParameters.vehicleObject.cars[index];
        tempVehicles.push(element);
      }
      setVehicles(tempVehicles);
    } else {
      setVehicles([]);
    }
  }, [searchParameters.searchObject, page, perPage, searchParameters.vehicleObject.cars, searchParameters.vehicleObject.count]);

  function searchHasFilter(obj: { [key: string]: any }): boolean {
    const keys = Object.keys(obj);
    const filteredKeys = keys.filter((key) => key !== "sort" && key !== "sortd");
    return filteredKeys.length > 0;
  }

  useEffect(() => {
    setHasFilter(searchHasFilter(searchParameters?.searchObject));
  }, [searchParameters]);

  const handleChangePerPage = (event: SelectChangeEvent<string>) => {
    setPerPage(parseInt(event.target.value as string, 10));
    setPage(1); // Zurücksetzen auf Seite 1 bei Änderung der Anzahl pro Seite
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    setPage(newPage);
  };

  const titel = () => {
    if (searchParameters.vehicleObject && typeof searchParameters.vehicleObject.count === "number") {
      const count = searchParameters.vehicleObject.count;
      return (
        <span>
          <strong>{count}</strong> {count === 1 ? "Fahrzeug gefunden" : "Fahrzeuge gefunden"}
        </span>
      );
    } else {
      return <span>Keine Fahrzeuge gefunden</span>;
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    navigate(customerConfiguration.vehicleList);
  };

  const isVehicleChosen = facets && facets?.ty && Object.keys(facets).length > 1;

  return (
    <Container sx={{ maxWidth: customerConfiguration.width, p: 0 }}>
      {/* Snackbar oben auf der Seite */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(0,0,0,0.75)",
            color: "white",
            boxShadow: 3,
          },
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: "100%" }}>
          <Typography>Das gewünschte Fahrzeug wurde bereits verkauft, aber Sie können sich gerne unsere anderen Angebote anschauen.</Typography>
        </Alert>
      </Snackbar>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 1,
          justifyContent: "center",
        }}
      >
        <PaginationSortingContainer perPage={perPage} page={page} handleChangePerPage={handleChangePerPage} handleChangePage={handleChangePage} {...searchParameters} />
      </Grid>
      <Typography
        sx={{
          bgcolor: "primary.main",
          color: "white",
          p: 1.3,
          my: 1,
          textTransform: "uppercase",
        }}
      >
        {titel()}
      </Typography>

      {hasFilter && <FilterReset {...searchParameters} />}
      <Grid container spacing={2}>
        {vehicles.length > 0 &&
          vehicles.map((vehicle) => (
            <React.Fragment key={vehicle.li}>
              {1 === isViewOpen && customerConfiguration.small.visible && <VehicleSmall vehicle={vehicle} />}
              {2 === isViewOpen && customerConfiguration.large.visible && (
                <Grid item sx={{ m: 0.5 }} ref={containerRef} xl={!isSmallOpen && containerRef?.current?.offsetWidth > 1920 ? 6 : 12} lg={isSmallOpen ? gridSize : 12} md={isSmallOpen ? gridSize : 12} sm={isSmallOpen ? gridSize : 12} xs={12} display="flex">
                  <VehicleLarge vehicle={vehicle} customerConfiguration={customerConfiguration} />
                </Grid>
              )}
              {3 === isViewOpen && customerConfiguration.medium.visible && (
                <Grid item sx={{ m: 0.5 }} ref={containerRef} xl={!isSmallOpen && containerRef?.current?.offsetWidth > 1920 ? 6 : 12} lg={isSmallOpen ? gridSize : 12} md={isSmallOpen ? gridSize : 12} sm={isSmallOpen ? gridSize : 12} xs={12} display="flex">
                  <VehicleMedium vehicle={vehicle} customerConfiguration={customerConfiguration} />
                </Grid>
              )}
            </React.Fragment>
          ))}
      </Grid>
      <ScrollToTop />
      <Drawer
        PaperProps={{
          sx: {
            minWidth: "290px",
            maxWidth: "85%",
          },
        }}
        anchor="left"
        open={isMenuOpen}
        onClose={toggleMenu}
      >
        <Grid
          item
          sx={{
            margin: 2,
            display: "flex",
            flexDirection: "column",
            maxWidth: "320px",
            "& > *": {
              marginBottom: "1em",
            },
            "& > :last-child": {
              mb: 0,
            },
          }}
        >
          <Card>
            <CardHeader titleTypographyProps={{ variant: "h2" }} title="Fahrzeugtyp" />
            <CardContent>
              <VehicleTypeComponentCheckbox {...searchParameters} showToggleButton={true} />
            </CardContent>
          </Card>

          {isVehicleChosen && (
            <>
              {/* {facets && facets?.loA && Object.keys(facets?.loA).length > 1 ? (
            <Card>
              <CardHeader titleTypographyProps={{ variant: "h2" }} title="Filiale" />
              <CardContent>
                <BranchComponentSelect {...searchParameters}></BranchComponentSelect>
              </CardContent>
            </Card>
          ) : null} */}
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Fahrzeugzustand" />
                <CardContent>
                  <VehicleConditionComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Hersteller" />
                <CardContent>
                  <ManufacturerComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              {facets && facets?.mo && Object.keys(facets?.mo).length >= 1 ? (
                <Card>
                  <CardHeader titleTypographyProps={{ variant: "h2" }} title="Modell" />
                  <CardContent>
                    <ModelComponentCheckbox {...searchParameters} showToggleButton={true} />
                  </CardContent>
                </Card>
              ) : null}

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Preis" />
                <CardContent>
                  <PriceComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Kilometerstand in [km]" />
                <CardContent>
                  <MileageComponentRangeSlider {...searchParameters} />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Leistung" />
                <CardContent>
                  <PerformanceComponentRangeSlider {...searchParameters} />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Kraftstoffart" />
                <CardContent>
                  <FuelComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Getriebeart" />
                <CardContent>
                  <EngineGearboxComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Ausstattungen" />
                <CardContent>
                  <EquipmentComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.category` as any)} />
                <CardContent>
                  <BikeCategoryComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.weight` as any)} />
                <CardContent>
                  <BikeWeightComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.wheelSize` as any)} />
                <CardContent>
                  <BikeWheelSizeComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.frameMaterial` as any)} />
                <CardContent>
                  <BikeFrameMaterialComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.frameShape` as any)} />
                <CardContent>
                  <BikeFrameShapeComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.frameHeight` as any) + " (cm)"} />
                <CardContent>
                  <BikeFrameHeightComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.gearType` as any)} />
                <CardContent>
                  <BikeGearTypeComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.numberOfGears` as any)} />
                <CardContent>
                  <BikeNumberOfGearsComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>

              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.batteryManufacturer` as any)} />
                <CardContent>
                  <BikeBatteryManufacturerComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.batteryCapacity` as any)} />
                <CardContent>
                  <BikeBatteryCapacityWhComponentRangeSlider {...searchParameters} locale="de-DE" />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.batteryPosition` as any)} />
                <CardContent>
                  <BikeBatteryPositionComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card sx={{ mb: 1.5 }}>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title="Außenfarbe" />
                <CardContent>
                  <ExteriorColorComponent {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
              <Card>
                <CardHeader titleTypographyProps={{ variant: "h2" }} title={t(`common:filters.motorPosition` as any)} />
                <CardContent>
                  <BikeMotorPositionComponentCheckbox {...searchParameters} showToggleButton={true} />
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
      </Drawer>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          mt: 1,
          justifyContent: "center",
        }}
      >
        <PaginationSortingContainer perPage={perPage} page={page} handleChangePerPage={handleChangePerPage} handleChangePage={handleChangePage} {...searchParameters} />
      </Grid>
      <ConsumptionNote />
    </Container>
  );
};

export default VehicleList;
