import React, { FC } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import QuickSearchWithTabs from "./QuickSearchWithTabs";
import { Box } from "@mui/material";

const QuickSearch: FC<ISearchParametersProps> = (searchParameters) => {
  return (
    <Box id="mpiQuickSearch">
      <QuickSearchWithTabs {...searchParameters} />
    </Box>
  );
};

export default QuickSearch;
