import React, { FC, useEffect, useRef, useState, memo, useCallback, useMemo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { debounce } from "lodash";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import VehicleSmall from "../VehiclesList/VehicleSmall/VehicleSmall";
import { Button, Grid } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useStore from "@store";
import { Helmet } from "react-helmet";

const VehicleSmallMemo = memo(VehicleSmall);

const VehicleSlider: FC<ISearchParametersProps> = ({ ...searchParameters }, width) => {
  const { customerConfiguration } = useStore();
  const sliderContainerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const cars = searchParameters.vehicleObject?.cars;
  const itemWidth = 310;
  const arrowSpace = 30;

  const updateContainerWidth = useCallback(() => {
    if (sliderContainerRef.current) {
      const currentWidth = sliderContainerRef.current.offsetWidth;
      setContainerWidth(currentWidth - (currentWidth === 1200 ? arrowSpace : 50));
    }
  }, []);

  useEffect(() => {
    if (cars) {
      cars.forEach((vehicle) => {
        const img = new Image();
        //ToDo: Testen ob Bilder da sind
        vehicle.mm.u ? (img.src = vehicle.mm?.u[0]) : (img.src = "");
      });
    }

    updateContainerWidth();
    const debounceResize = debounce(updateContainerWidth, 100);
    window.addEventListener("resize", debounceResize);

    return () => window.removeEventListener("resize", debounceResize);
  }, [cars, updateContainerWidth]);

  const berechnung = useCallback(() => {
    return Math.floor(containerWidth / itemWidth);
  }, [containerWidth, itemWidth]);

  const responsiveObject = useCallback(() => {
    const items = berechnung();
    return {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1400 },
        items: items > 8 ? 8 : items,
        partialVisibilityGutter: 0
      },
      desktop: {
        breakpoint: { max: 1400, min: 1024 },
        items: items > 4 ? 4 : items,
        partialVisibilityGutter: 0
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: items > 2 ? 2 : items,
        partialVisibilityGutter: 0
      },
      mobile: {
        breakpoint: { max: 464, min: 360 },
        items: items > 1 ? 1 : items,
        partialVisibilityGutter: 0
      }
    };
  }, [berechnung]);

  const containerStyle = useMemo(() => {
    return `calc(${width} - 100px)`;
  }, [width]);

  const ButtonGroup = ({ next, previous }: any) => {
    return (
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          position: "sticky",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto auto auto -5%",
          width: "110%",
          height: "50vh",
          pointerEvents: "none"
        }}
      >
        <Grid item>
          <Button onClick={() => previous()}>
            <ArrowBackIosNewIcon fontSize="large" sx={{ height: "5em", pointerEvents: "all" }} />
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => next()}>
            <ArrowForwardIosIcon fontSize="large" sx={{ height: "5em", pointerEvents: "all" }} />
          </Button>
        </Grid>
      </Grid>
    );
  };

  const helmetCanonical = `${window.location.origin}${customerConfiguration.vehicleSlider}`;
  return (
    <>
      <Helmet>
        <link rel="canonical" href={helmetCanonical} />
      </Helmet>

      <Grid
        item
        sx={{
          width: containerStyle,
          position: "relative",
          height: 1,
          px: "5%"
        }}
        className="gd-carousel-wrapper"
        ref={sliderContainerRef}
      >
        <style>
          {`
          .react-multi-carousel-track li{
          height: 100%
          }
        `}
        </style>
        {cars && (
          <Carousel renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />} additionalTransfrom={0} arrows={false} autoPlaySpeed={3000} autoPlay={true} centerMode={false} className="gd-carousel" containerClass="container-with-dots" dotListClass="" draggable focusOnSelect={false} infinite itemClass="" keyBoardControl minimumTouchDrag={80} pauseOnHover renderArrowsWhenDisabled={false} renderDotsOutside={false} responsive={responsiveObject()} rewind={true} rewindWithAnimation={true} rtl={false} shouldResetAutoplay showDots={false} sliderClass="" slidesToSlide={1} swipeable>
            {cars.map((vehicle, index) => (
              <Grid
                key={vehicle.on + vehicle.na}
                sx={{
                  ml: 1,
                  mr: 1
                }}
              >
                <VehicleSmallMemo key={vehicle.anr || index} vehicle={vehicle} />
              </Grid>
            ))}
          </Carousel>
        )}
      </Grid>
    </>
  );
};

export default VehicleSlider;
