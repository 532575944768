import React, { useContext, useEffect, useState } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { DataArrayContext } from "../../../contexts/dataArrayContext";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { IEquipment, IEquipmentCategory } from "@Interface/IEquipment";
import { CheckboxElement } from "../../../components/CheckboxElement";

import { FILTER_VEHICLE_TYPE, TYPE_PKW, TYPE_LKW, TYPE_WOHNMOBIL, TYPE_MOTORCYCLE, TYPE_PKW_BIN, TYPE_WOHNMOBIL_BIN, TYPE_MOTORRAD_BIN, TYPE_LKW_BIN } from "../../../redux/constants/index";
import { ISelectData } from "@Interface/ISelectData";

const EquipmentAccordion: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const dataArray = useContext(DataArrayContext);
  const [equipmentCategories, setEquipments] = useState<IEquipmentCategory[]>([]);

  const [equipmentCategoryMostWanted, setEquipmentMostWanted] = useState<IEquipmentCategory>({} as IEquipmentCategory);

  const [typeBin, setTypeBin] = useState(0);

  useEffect(() => {
    const updatedEquipments = dataArray.equipmentsCategory.filter((child) => child.id !== -1);
    const equipmentWithIdMinus1 = dataArray.equipmentsCategory.find((child) => child.id === -1);
    setEquipments(updatedEquipments);
    setEquipmentMostWanted(equipmentWithIdMinus1);
  }, [dataArray]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const theme = useTheme();

  const colorGray = useTheme().palette.grey[500];

  const handleEquipmentFilterClick = (event: React.ChangeEvent<HTMLInputElement>, label: string, data: Record<string, number[]>) => {
    const { checked } = event.target;
    const name = "ai";
    const keyToAccess = label;
    const values = data[keyToAccess];

    searchParameters.setSearchObject((prevSelectData) => {
      if (checked) {
        return {
          ...prevSelectData,
          [name]: prevSelectData[name] ? [...prevSelectData[name], ...values] : [...values],
        };
      } else {
        if (typeof prevSelectData === "object" && prevSelectData.hasOwnProperty(name)) {
          if (prevSelectData[name].length > 0) {
            return {
              ...prevSelectData,
              [name]: prevSelectData[name].filter((value) => !values.includes(value)),
            };
          }
        } else {
          return {
            ...prevSelectData,
            [name]: checked ? values : undefined,
          };
        }
      }
    });
  };

  const setValue = (data: Record<string, number[]>) => {
    if (searchParameters.searchObject && searchParameters.searchObject.ai && searchParameters.searchObject.ai.length > 0 && Array.isArray(searchParameters.searchObject.ai)) {
      const keyToAccess = "ai";
      const values = data[`${keyToAccess}`];

      return searchParameters.searchObject.ai.includes(values[0]);
    }
    return false;
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // TODO: ab Zeile 133 - 192 ist das code doppelt

  const searchObjectValue = (searchObject: ISelectData) => {
    for (const [key, value] of Object.entries(searchObject)) {
      if (key === FILTER_VEHICLE_TYPE && Array.isArray(value) && typeof value[0] === "number") {
        return value[0];
      }
    }
    return null;
  };

  useEffect(() => {
    const containsValue = searchObjectValue(searchParameters.searchObject);
    switch (containsValue) {
      case TYPE_PKW:
        setTypeBin(TYPE_PKW_BIN);
        break;
      case TYPE_LKW:
        setTypeBin(TYPE_LKW_BIN);
        break;
      case TYPE_WOHNMOBIL:
        setTypeBin(TYPE_WOHNMOBIL_BIN);
        break;
      case TYPE_MOTORCYCLE:
        setTypeBin(TYPE_MOTORRAD_BIN);
        break;
      default:
        setTypeBin(TYPE_PKW_BIN + TYPE_LKW_BIN + TYPE_WOHNMOBIL_BIN + TYPE_MOTORRAD_BIN);
        break;
    }
  }, [searchParameters.searchObject, typeBin]);

  function filterTyp(equipment: IEquipment) {
    return 0 !== (equipment.class & typeBin);
  }

  const filterEmpty = (equipmentCategory: IEquipmentCategory) => {
    if (equipmentCategory && equipmentCategory.equipments) {
      const filteredEquipments = equipmentCategory.equipments.filter(filterTyp);
      if (filteredEquipments.length > 0) {
        return { ...equipmentCategory, equipments: filteredEquipments };
      }
    }
    return null;
  };
  let filteredEquipmentsMostWanted: IEquipment[] = [];

  if (equipmentCategoryMostWanted && equipmentCategoryMostWanted.equipments && equipmentCategoryMostWanted.equipments.length > 0) {
    filteredEquipmentsMostWanted = equipmentCategoryMostWanted.equipments.filter(filterTyp);
  }

  const filteredEquipmentCategories = equipmentCategories?.map(filterEmpty).filter((category) => category !== null);
  return (
    <>
      {equipmentCategoryMostWanted && equipmentCategoryMostWanted.equipments && equipmentCategoryMostWanted.equipments.length > 0 && (
        <>
          <Typography variant="body1" color={colorGray}>
            {equipmentCategoryMostWanted.name}
          </Typography>
          <Grid key={equipmentCategoryMostWanted.id} container spacing={1}>
            {filteredEquipmentsMostWanted.map((equipment) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={`${equipment.name}${equipment.id}`}>
                <CheckboxElement
                  key={`${equipment.name}${equipment.id}`}
                  name={equipment.name}
                  checked={setValue({ ai: [equipment.id, 1] })}
                  onChange={(event) =>
                    handleEquipmentFilterClick(event, equipment.name, {
                      [equipment.name]: [equipment.id],
                    })
                  }
                  label={equipment.name}
                  data={{ ai: [equipment.id, 1] }}
                />
              </Grid>
            ))}
          </Grid>
          <Divider sx={{ m: 1 }} />
        </>
      )}

      {filteredEquipmentCategories ? (
        <>
          <Grid sx={{ padding: 1 }}>
            {filteredEquipmentCategories.map((category, index) => (
              <Accordion key={category.id} expanded={expanded === category.name} onChange={handleChange(category.name)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="action" />}
                  id="panel1bh-header"
                  sx={{
                    // TODO: das sollte ins Thema
                    bgcolor: expanded === category.name ? "primary.main" : "common.white",
                    color: expanded === category.name ? "common.white" : "#000000",
                  }}
                >
                  <Typography>{category.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    {category.equipments &&
                      category.equipments.map((equipment) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={`${equipment.name}${equipment.id}`}>
                          <CheckboxElement key={`${equipment.name}${equipment.id}`} name={equipment.name} className={""} checked={setValue({ ai: [equipment.id, 1] })} onChange={(event) => handleEquipmentFilterClick(event, equipment.name, { [equipment.name]: [equipment.id] })} label={equipment.name} data={{ ai: [equipment.id, 1] }} bgColor={theme.palette.primary.main} />
                        </Grid>
                      ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </>
      ) : null}
    </>
  );
};

export default EquipmentAccordion;
