import React from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import PaymentMethod from "./PaymentMethodComponent";
import { Grid } from "@mui/material";
import { FILTER_VEHICLE_CONDITION } from "../../../redux/constants/index";
import FirstRegistrationYearComponentRangeSlider from "../../../FormComponents/RangeSlider/FirstRegistrationYearComponentRangeSlider";
import MpiLabel from "../../../components/MpiLabel";
import PerformanceComponentRangeSlider from "../../../FormComponents/RangeSlider/PerformanceComponentRangeSlider";

const BasicData: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const hasNewVehicleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === FILTER_VEHICLE_CONDITION && Array.isArray(value) && value[0] === 1);

  return (
    <>
      <PaymentMethod {...searchParameters}></PaymentMethod>
      <Grid container spacing={2}>
        {!hasNewVehicleValue && (
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <MpiLabel title="Erstzulassung" />
            <FirstRegistrationYearComponentRangeSlider {...searchParameters} />
          </Grid>
        )}
        {/* ToDo: SwitchSliderInputElement muss noch neu gemacht werden */}
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <MpiLabel title="Leistung" />
          <PerformanceComponentRangeSlider {...searchParameters} />
        </Grid>
      </Grid>
    </>
  );
};

export default BasicData;
