import React from "react";
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import { IVehicleDetailed } from "@Interface/IVehicleDetailed";
import { getListAttrVehicleDetailsMoreinformation } from "@/tools/vehicleDataHandler";
import { shortingString } from "@/tools/tools";
import { getBackend } from "@/tools/backendApis";

const MoreInformation: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  const attributeData = getListAttrVehicleDetailsMoreinformation(data);
  const allEmpty = attributeData.every((attr) => !attr.value);

  return (
    <>
      {!allEmpty && (
        <Card sx={{ height: "100%", py: 1 }}>
          <CardContent>
            <Grid container spacing={2} sx={{ height: "100%", display: "flex" }}>
              {attributeData.map(
                (item, index) =>
                  item.value &&
                  item.label && (
                    <Grid item xs={12} sm={12} md={12} lg={12} key={index} py={1}>
                      <Grid item container spacing={1}>
                        <Grid item xs={5} sm={5} sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body1" fontWeight="bold">
                            {item.label}
                          </Typography>
                        </Grid>
                        <Tooltip title={item.tooltip}>
                          <Grid item xs={7} sm={7} sx={{ display: "flex", alignItems: "center" }}>
                            {item.isImg ? (
                              <img src={getBackend(item.value)} width="100" alt="Siegel" />
                            ) : (
                              <Typography variant="body1" overflow="hidden">
                                {shortingString(item.value, 44)}
                              </Typography>
                            )}
                          </Grid>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default MoreInformation;
