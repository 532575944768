import React, { useContext, useEffect, useState } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { DataArrayContext } from "../../../contexts/dataArrayContext";
import { Box, Grid, Tab, Tabs, useTheme } from "@mui/material";
import { IEquipmentCategory, IEquipment } from "@Interface/IEquipment";
import { CheckboxElement } from "../../../components/CheckboxElement";
import { FILTER_VEHICLE_TYPE, TYPE_PKW, TYPE_LKW, TYPE_WOHNMOBIL, TYPE_MOTORCYCLE, TYPE_PKW_BIN, TYPE_WOHNMOBIL_BIN, TYPE_MOTORRAD_BIN, TYPE_LKW_BIN } from "../../../redux/constants/index";
import { ISelectData } from "@Interface/ISelectData";
import MpiLabel from "../../../components/MpiLabel";

const EquipmentTabs: React.FC<ISearchParametersProps> = ({ ...searchParameters }, searchObject = {} as ISelectData) => {
  const dataArray = useContext(DataArrayContext) as { equipmentsCategory: IEquipmentCategory[] };
  const [equipmentCategories, setEquipments] = useState<IEquipmentCategory[]>([]);
  const [equipmentCategoryMostWanted, setEquipmentMostWanted] = useState<IEquipmentCategory>({} as IEquipmentCategory);
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const [typeBin, setTypeBin] = useState(0);

  const columns = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 };
  const flexDirection = "row";

  useEffect(() => {
    if (dataArray.equipmentsCategory) {
      // console.log("dataArray.equipmentsCategory", dataArray.equipmentsCategory);
      const updatedEquipments = dataArray.equipmentsCategory.filter((child: IEquipmentCategory) => child.id !== -1);
      const equipmentWithIdMinus1 = dataArray.equipmentsCategory.find((child: IEquipmentCategory) => child.id === -1);
      setEquipments(updatedEquipments || []);
      setEquipmentMostWanted(equipmentWithIdMinus1 || ({} as IEquipmentCategory));
    }
  }, [dataArray]);

  const handleEquipmentFilterClick = (event: React.ChangeEvent<HTMLInputElement>, label: string, data: Record<string, number[]>) => {
    const { checked } = event.target;
    const name = "ai";
    const keyToAccess = label;
    const values = data[keyToAccess];

    searchParameters.setSearchObject((prevSelectData: ISelectData) => {
      const updatedSelectData = { ...prevSelectData };

      if (checked) {
        updatedSelectData[name] = prevSelectData[name] ? [...prevSelectData[name], values[0]] : [values[0]];
      } else {
        updatedSelectData[name] = prevSelectData[name].filter((value) => value !== values[0]);
      }

      return updatedSelectData;
    });
  };

  const setValue = (data: Record<string, number[]>) => {
    if (searchParameters.searchObject && searchParameters.searchObject.ai && searchParameters.searchObject.ai.length > 0 && Array.isArray(searchParameters.searchObject.ai)) {
      const keyToAccess = "ai";
      const values = data[`${keyToAccess}`];
      return searchParameters.searchObject.ai.includes(values[0]);
    }
    return false;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const searchObjectValue = (searchObject: ISelectData) => {
    for (const [key, value] of Object.entries(searchObject)) {
      if (key === FILTER_VEHICLE_TYPE && Array.isArray(value) && typeof value[0] === "number") {
        return value[0];
      }
    }
    return null;
  };

  useEffect(() => {
    const containsValue = searchObjectValue(searchObject);
    switch (containsValue) {
      case TYPE_PKW:
        setTypeBin(TYPE_PKW_BIN);
        break;
      case TYPE_LKW:
        setTypeBin(TYPE_LKW_BIN);
        break;
      case TYPE_WOHNMOBIL:
        setTypeBin(TYPE_WOHNMOBIL_BIN);
        break;
      case TYPE_MOTORCYCLE:
        setTypeBin(TYPE_MOTORRAD_BIN);
        break;
      default:
        setTypeBin(TYPE_PKW_BIN + TYPE_LKW_BIN + TYPE_WOHNMOBIL_BIN + TYPE_MOTORRAD_BIN);
        break;
    }
  }, [searchObject, typeBin]);

  function filterTyp(equipment: IEquipment) {
    return 0 !== (equipment.class & typeBin);
  }

  const filterEmpty = (equipmentCategory: IEquipmentCategory) => {
    if (equipmentCategory && equipmentCategory.equipments) {
      const filteredEquipments = equipmentCategory.equipments.filter(filterTyp);

      if (filteredEquipments.length > 0) {
        return { ...equipmentCategory, equipments: filteredEquipments };
      }
    }
    return null;
  };

  let filteredEquipmentsMostWanted: IEquipment[] = [];
  if (equipmentCategoryMostWanted.equipments?.length > 0) {
    filteredEquipmentsMostWanted = equipmentCategoryMostWanted.equipments.filter(filterTyp);
  }

  const filteredEquipmentCategories = equipmentCategories.map(filterEmpty).filter((category) => category !== null) as IEquipmentCategory[];

  return (
    <>
      {/* meistgesuchte Ausstattungen */}
      {equipmentCategoryMostWanted && equipmentCategoryMostWanted.equipments && equipmentCategoryMostWanted.equipments.length > 0 && (
        <>
          <MpiLabel title={equipmentCategoryMostWanted.name} />
          <Grid key={equipmentCategoryMostWanted.id} container>
            {filteredEquipmentsMostWanted.map((equipment) => (
              <Grid {...columns} flexDirection={flexDirection} item key={`${equipment.name}${equipment.id}`}>
                <CheckboxElement
                  name={equipment.name}
                  className=""
                  checked={setValue({ ai: [equipment.id, 1] })}
                  onChange={(event) =>
                    handleEquipmentFilterClick(event, equipment.name, {
                      [equipment.name]: [equipment.id],
                    })
                  }
                  label={equipment.name}
                  data={{ ai: [equipment.id, 1] }}
                />
              </Grid>
            ))}
          </Grid>

          <Grid sx={{ borderBottom: "1px solid #ddd", margin: "35px 5px 20px 5px" }}></Grid>
        </>
      )}
      {/* Tabs Ausstattungen */}
      {filteredEquipmentCategories.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Tabs value={currentTab} onChange={handleChange} variant="standard" className="equipment-tabs">
            {filteredEquipmentCategories.map((category, index) => (
              <Tab
                label={category.name}
                key={index}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: `${theme.palette.primary.main}`,
                    color: "secondary.main",
                  },
                  mb: 1,
                }}
              />
            ))}
          </Tabs>
          {filteredEquipmentCategories.map((category, index) => (
            <div key={category.id} hidden={currentTab !== index}>
              <Grid
                item
                container
                sx={{
                  padding: "3px",
                }}
              >
                {category.equipments &&
                  category.equipments.map((equipment) => (
                    <Grid {...columns} flexDirection={flexDirection} item key={`${equipment.name}${equipment.id}`}>
                      <CheckboxElement
                        key={`${equipment.name}${equipment.id}`}
                        name={equipment.name}
                        checked={setValue({ ai: [equipment.id, 1] })}
                        onChange={(event) =>
                          handleEquipmentFilterClick(event, equipment.name, {
                            [equipment.name]: [equipment.id],
                          })
                        }
                        label={equipment.name}
                        data={{ ai: [equipment.id, 1] }}
                        bgColor={theme.palette.primary.main}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          ))}
        </Box>
      )}
    </>
  );
};

export default EquipmentTabs;
