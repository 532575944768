import React from "react";
import { Card, CardContent, Divider, Grid, Typography, Link, Tooltip } from "@mui/material";
import PriceElement from "../../../components/PriceElement";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { IVehicleDetailed } from "@Interface/IVehicleDetailed";
import { getListAttrVehicleDetailsQuickinformation, getVehicleType } from "@/tools/vehicleDataHandler";
import { getNettoPriceString } from "@/tools/tools";

const QuickInformation: React.FC<{
  data: IVehicleDetailed;
}> = ({ data }) => {
  const theme = useTheme();

  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);
  if (customerConfiguration?.demo) {
    // Daten nur zum testen
    data.Finanzierung = {} as IVehicleDetailed["Finanzierung"];
    data.Finanzierung.rate = "139 €";
    data.Leasing = {} as IVehicleDetailed["Leasing"];
    data.Leasing.rate = "153 €";
  }

  const finanzierung = data.Finanzierung?.disclaimer2 ? data.Finanzierung?.rate + "**" : data.Finanzierung?.rate;
  const leasing = data.Finanzierung?.disclaimer2 ? data.Leasing?.rate + "**" : data.Leasing?.rate;

  const attributeData = getListAttrVehicleDetailsQuickinformation(data);

  return (
    <Card sx={{ height: 1, overflow: "visible" }}>
      <CardContent sx={{ pb: 0 }}>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} position={"relative"}>
            <PriceElement fontWeight="fontWeightBold" title={getNettoPriceString(data?.PriceSale, 0, data?.vat)} price={data?.PriceSale} priceColor={theme.palette.secondary.main} />
            <Link
              onClick={() => {
                document.getElementById("Contact").scrollIntoView({ behavior: "smooth" });
              }}
              sx={{ position: "absolute", top: 0, right: "0", cursor: "pointer" }}
            >
              <Typography variant="body1">Kontakt aufnehmen</Typography>
            </Link>
          </Grid>
          {data.ty !== getVehicleType(data) && (
            <>
              {data.Finanzierung && (
                <Grid item xs={6}>
                  <PriceElement title="mtl. Finanzierungs­rate" price={finanzierung} priceColor={theme.palette.secondary.main} />
                </Grid>
              )}
              {data.Leasing && (
                <Grid item xs={6}>
                  <PriceElement title="mtl. Leasing­rate" price={leasing} priceColor={theme.palette.secondary.main} />
                </Grid>
              )}
            </>
          )}
          {!data.Finanzierung && (
            <Grid item xs={12} pt={"0 !important"} pb={1} mt={1}>
              <Typography variant="body2">Fragen Sie nach unseren Finanzierungs- / Leasingangeboten. Gerne nehmen wir Ihren Gebrauchten in Zahlung.</Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <Divider />

      <CardContent>
        <Grid container spacing={1} item sx={{ display: "flex", flexDirection: "row" }}>
          <Grid container sx={{ mt: 1.5 }}>
            {attributeData.map(
              (item, index) =>
                item.value &&
                item.icon && (
                  <Grid item xs={12} sm={6} md={6} key={item.value}>
                    <Tooltip title={item.tooltip}>
                      <Typography noWrap sx={{ display: "flex", p: 1 }}>
                        {item.icon}
                        <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{item.value}</span>
                      </Typography>
                    </Tooltip>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default QuickInformation;
