import { useNavigate } from "react-router-dom";
import { getBackend } from "@/tools/backendApis";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";
import { getDetailUrl } from "@/tools/vehicleDataHandler";
import useStore from "@store";

const useHandleCardClick = (vehicle: IVehicleListItem) => {
  const navigate = useNavigate();
  const { customerConfiguration, setSelectedCarLink } = useStore();

  const handleCardClick = () => {
    const selectedCar = getBackend(vehicle.li);
    const selectedLink = getDetailUrl(vehicle, customerConfiguration);

    setSelectedCarLink(selectedCar);

    navigate(selectedLink);
  };

  return handleCardClick;
};

export default useHandleCardClick;
