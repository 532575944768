import React, { useState } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MpiLabel from "../../components/MpiLabel";
import { FormControl, TextField } from "@mui/material";

const OfferNumberComponentTextBox: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const [, setOfferNumberValue] = useState("");

  const handleOfferNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOfferNumberValue(event.target.value);

    void searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      anr: event.target.value,
    }));
  };

  return (
    <FormControl fullWidth>
      <MpiLabel title="Angebotsnummer" />
      <TextField fullWidth id="outlined-textarea" size="small" placeholder="z.B. 4422403 ..." variant="outlined" value={searchParameters.searchObject?.anr || ""} onChange={handleOfferNumberChange} />
    </FormControl>
  );
};

export default OfferNumberComponentTextBox;
