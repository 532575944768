import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { Grid } from "@mui/material";
import { FILTER_VEHICLE_CONDITION } from "@/constants/index";
import FirstRegistrationYearComponentRangeSlider from "@/FormComponents/RangeSlider/FirstRegistrationYearComponentRangeSlider";
import MpiLabel from "@/components/Labels/MpiLabel";
import PerformanceComponentRangeSlider from "@/FormComponents/RangeSlider/PerformanceComponentRangeSlider";
import MileageComponentRangeSlider from "@/FormComponents/RangeSlider/MileageComponentRangeSlider";

const BasicData: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const hasNewVehicleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === FILTER_VEHICLE_CONDITION && Array.isArray(value) && value[0] === 1);

  return (
    <>
      <Grid container spacing={4}>
        {!hasNewVehicleValue && (
          <>
            <Grid item xs={12} md={4}>
              <MpiLabel title="Erstzulassung" />
              <FirstRegistrationYearComponentRangeSlider {...searchParameters} />
            </Grid>
            <Grid item xs={12} md={4}>
              <MpiLabel title="km-Stand" />
              <MileageComponentRangeSlider {...searchParameters} />
            </Grid>
          </>
        )}
        {/* ToDo: SwitchSliderInputElement muss noch neu gemacht werden */}
        <Grid item xs={12} md={4}>
          <MpiLabel title="Leistung" />
          <PerformanceComponentRangeSlider {...searchParameters} />
        </Grid>
      </Grid>
    </>
  );
};

export default BasicData;
