import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_SELECTED_CAR } from "@/redux/constants/index";
import { getBackend } from "@/tools/backendApis";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import { getDetailUrl } from "@/tools/vehicleDataHandler";

const useHandleCardClick = (vehicle: IVehicleListItem) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);

  const handleCardClick = () => {
    const selectedCar = getBackend(vehicle.li);
    const selectedLink = getDetailUrl(vehicle, customerConfiguration);

    dispatch({
      type: SET_SELECTED_CAR,
      payload: selectedCar,
    });

    navigate(selectedLink);
  };

  return handleCardClick;
};

export default useHandleCardClick;
