import React from "react";
import Slider from "@mui/material/Slider";
import { ISliderPropsNeu } from "@/interfaces/ISliderPropsNeu";

const SliderInputElement = ({ onChange, data, marks, marksToolTip }: ISliderPropsNeu) => {
  // anstelle des Indizees das Label anzeigen
  function formatValueLabel(value: number) {
    return value < 0 || value >= marksToolTip.length ? "" : marksToolTip[value]?.label;
  }

  // nur intern für den Slider, damit er optisch bedienbar ist
  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue as number[]);
  };

  // der Slider arbeitet nur mit Indizees
  return (
    <Slider
      sx={{ mx: 4, mt: 1 }}
      marks={marks}
      value={data} // Verwende direkt die `data`-Props
      min={0}
      max={marksToolTip.length - 1}
      onChange={handleChange}
      valueLabelFormat={formatValueLabel}
      valueLabelDisplay="auto"
    />
  );
};

export default SliderInputElement;
