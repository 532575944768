import React, { useState } from "react";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { TextField, Button, Typography, Container, Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";

interface ConfiguratorProps {
  customerConfig: ICustomerConfiguration;
}

export const Configurator: React.FC<ConfiguratorProps> = ({ customerConfig }) => {
  const [config, setConfig] = useState<ICustomerConfiguration>(customerConfig);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const navigate = useNavigate();

  const token = useSelector((state: RootState) => state.ConfigurationReducer.bearerToken);

  console.log("token: ", token);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    try {
      setConfig(JSON.parse(value));
      setSnackbarOpen(false);
      setIsError(false);
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setIsError(true);
    }
  };

  const handleSave = async () => {
    if (isError) {
      setSnackbarMessage("JSON ist noch fehlerhaft");
      setSnackbarOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch("https://www.testo.de/saveConfig", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(config),
      });

      if (!response.ok) {
        throw new Error("Fehler beim Speichern der Konfiguration.");
      }

      setSnackbarMessage("Konfiguration erfolgreich gespeichert!");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage(error.message);
      setSnackbarSeverity("error");

      // TODO: Delete token, when invalid
      // dispatch({
      //   type: SET_BEARER_TOKEN,
      //   payload: "",
      // });
      // navigate("/admin/login");
    } finally {
      setIsLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        CONFIGURATOR
      </Typography>
      <TextField label="customerConfig" multiline fullWidth rows={30} name="customerConfig" defaultValue={JSON.stringify(config, null, 2)} variant="outlined" margin="normal" InputProps={{ readOnly: false }} onChange={handleChange} />
      <Button variant="contained" color="primary" onClick={handleSave} disabled={isLoading} style={{ marginTop: "16px" }}>
        {isLoading ? "Speichern..." : "Speichern"}
      </Button>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Configurator;
