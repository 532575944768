import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";

const ScrollToTop = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(customerConfiguration.scrollToTop);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [customerConfiguration.scrollToTop]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {showScrollButton && customerConfiguration.scrollToTop && (
        <Fab color="primary" size="large" onClick={scrollToTop} style={{ position: "fixed", bottom: "20px", right: "20px" }}>
          <KeyboardArrowUpIcon />
        </Fab>
      )}
    </>
  );
};

export default ScrollToTop;
