import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";

import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

import { IconButton, MenuItem, Tooltip } from "@mui/material";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Sort: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const [selectedSort, setSelectedSort] = useState(localStorage.getItem("selectSort") || "new");
  const [sortOrder, setSortOrder] = useState<boolean>(() => {
    return localStorage.getItem("sortOrder") === "true";
  });

  const menuData = [
    { k: "new", n: "Preis (neu eingestellte zuerst)" },
    { k: "sp", n: "Preis" },
    { k: "ir", n: "Erstzulassung" },
    { k: "km", n: "Kilometerstand" },
    { k: "po", n: "Leistung" },
    { k: "ma", n: "Hersteller" },
  ];

  const createHandleMenuClick = (menuItem: string) => () => {
    setSelectedSort(menuItem);
    localStorage.setItem("selectSort", menuItem);
    if (menuItem === "new") {
      setSortOrder(false);
      localStorage.setItem("sortOrder", "false");
    }
    handleClose();
  };

  useEffect(() => {
    void searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      sort: selectedSort,
      sortd: sortOrder ? "ASC" : "DESC",
    }));
    // eslint-disable-next-line
  }, [selectedSort, sortOrder]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let sort = "sortiere nach ..";
  const menuList: React.ReactNode[] = [];

  menuData.forEach((item) => {
    menuList.push(
      <MenuItem key={item.k} onClick={createHandleMenuClick(item.k)} selected={item.k === selectedSort}>
        {item.n}
      </MenuItem>
    );
    if (item.k === selectedSort) sort = "sortiere nach " + item.n;
  });

  const buttonOnClick = (sortOrder: boolean) => {
    setSortOrder(sortOrder);
    localStorage.setItem("sortOrder", JSON.stringify(sortOrder));
  };

  return (
    <>
      <Tooltip title={sort}>
        <IconButton color="primary" onClick={handleClick} sx={{ p: 1 }}>
          <FilterListOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom", // Positioniert das Menü unterhalb des Buttons
          horizontal: "left", // Beginnt am linken Rand des Buttons
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menuList}
      </Menu>

      <Tooltip title={sortOrder ? "sortiere aufsteigend" : "sortiere absteigend"}>
        <IconButton color="primary" onClick={() => buttonOnClick(!sortOrder)} sx={{ p: 1 }}>
          {sortOrder ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Sort;
