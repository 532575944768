import { Grid, InputAdornment, TextField } from "@mui/material";
import React from "react";

interface SliderTextBoxElementsProps {
  textBoxValues: number[];
  locale?: string;
  unit: string;
  noformat?: boolean;
}

const SliderTextBoxElements: React.FC<SliderTextBoxElementsProps> = ({ textBoxValues, locale, unit, noformat = false }) => {
  const formatValue = (value: number) => {
    return locale ? value.toLocaleString(locale) : value.toString();
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
        {textBoxValues.map((textBoxValue, index) => (
          <TextField
            disabled
            size="small"
            variant="outlined"
            type="text"
            fullWidth
            key={index}
            label={index === 0 ? `Von` : `Bis`}
            value={`${noformat ? textBoxValue : formatValue(textBoxValue)}`}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
            }}
            sx={{
              '& input[type="text"]': {
                textAlign: "right",
              },
            }}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default SliderTextBoxElements;
