import React from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { Grid } from "@mui/material";
import { TYPE_MOTORCYCLE } from "@/constants/index";
import ManufacturerComponentSelect from "@/FormComponents/SelectBoxes/ManufacturerComponentSelect";
import ModelComponentSelect from "@/FormComponents/SelectBoxes/ModelComponentSelect";
import MpiLabel from "@/components/Labels/MpiLabel";
import VariantComponentTextBox from "@/FormComponents/TextBoxes/VariantComponentTextBox";

const MakeModelVariant: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const hasMotorcycleValue = Object.entries(searchParameters.searchObject).some(([key, value]) => key === "ty" && Array.isArray(value) && value[0] === TYPE_MOTORCYCLE);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <MpiLabel title="Marke" />
          <ManufacturerComponentSelect {...searchParameters} />
        </Grid>

        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <MpiLabel title="Modell" />
          <ModelComponentSelect {...searchParameters} />
        </Grid>
        {!hasMotorcycleValue && (
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <VariantComponentTextBox {...searchParameters} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MakeModelVariant;
