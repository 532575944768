import { Stack, Typography } from "@mui/material";
import React from "react";
import { formatPrice } from "@/tools/tools";

interface Props {
  title: string;
  price: string | number;
  sx?: {};
  priceColor?: string;
  fontSize?: string;
  fontWeight?: string;
}

const PriceElement: React.FC<Props> = ({ price, title, sx, priceColor = "", fontWeight }) => {
  return (
    <Stack sx={sx}>
      <Typography variant="h1" component="div" fontWeight={fontWeight} color={priceColor}>
        {formatPrice(price)}
      </Typography>
      <Typography variant="subtitle2" component="div">
        {title}
      </Typography>
    </Stack>
  );
};

export default PriceElement;
