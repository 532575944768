import React, { useEffect } from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_MANUFACTURER, FILTER_VEHICLE_MODEL } from "@/constants/index";
import { SelectBoxElement } from "@/components/Forms/SelectBoxElement";
import { useNavigate } from "react-router-dom";
import { checkUndefinedNullOrEmpty, handleChangeSelectBoxValue } from "@/tools/tools";
import useStore from "@store";

const ManufacturerComponentSelect: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { facets } = useStore();

  const navigate = useNavigate();

  const data = facets?.[FILTER_VEHICLE_MANUFACTURER];
  const searchParameter = searchParameters.searchObject[FILTER_VEHICLE_MANUFACTURER];

  // Überprüfe, ob die Daten für die Hersteller leer sind
  const isManufacturerDataEmpty = checkUndefinedNullOrEmpty(data);

  useEffect(() => {
    if (isManufacturerDataEmpty) {
      // Löschen Sie das Herstellerfeld aus dem Suchobjekt, wenn keine Daten vorhanden sind
      searchParameters.setSearchObject((prevSelectData) => {
        const newSelectData = { ...prevSelectData };
        delete newSelectData[FILTER_VEHICLE_MANUFACTURER];
        delete newSelectData[FILTER_VEHICLE_MODEL];
        return newSelectData;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManufacturerDataEmpty]);

  return <SelectBoxElement name={FILTER_VEHICLE_MANUFACTURER} placeholder="Hersteller wählen" data={data ?? {}} value={searchParameter !== undefined ? (Array.isArray(searchParameter) ? [...searchParameter] : searchParameter) : []} onChange={(id, name) => handleChangeSelectBoxValue(id, name, { ...searchParameters }, navigate)} />;
};

export default ManufacturerComponentSelect;
