import React, { useState } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import MpiLabel from "../../components/MpiLabel";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const CountryComponentSelect: React.FC<ISearchParametersProps> = () => {
  const [land, setLand] = useState("DE");

  const handleChangeCountry = (event: SelectChangeEvent) => {
    setLand(event.target.value as string);
  };

  return (
    <FormControl fullWidth>
      <MpiLabel title="Land" />
      <Select fullWidth size="small" value={land} labelId="select-label" onChange={handleChangeCountry}>
        <MenuItem value="DE">DE</MenuItem>
      </Select>
    </FormControl>
  );
};
export default CountryComponentSelect;
