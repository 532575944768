import React, { useEffect, useRef, useState } from "react";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import { Grid, Typography } from "@mui/material";
import VehicleListItem from "./VehicleListItem";

const MoreVehicles: React.FC<{ data: IVehicleListItem[] }> = ({ data }) => {
  const containerRef = useRef(null);
  const [maxVehicles, setMaxVehicles] = useState(3);

  const updateMaxVehiclesBasedOnWidth = () => {
    const containerWidth = containerRef.current?.offsetWidth;
    if (containerWidth) {
      if (containerWidth >= 2040) {
        setMaxVehicles(6);
      } else if (containerWidth >= 1700) {
        setMaxVehicles(5);
      } else if (containerWidth >= 1360) {
        setMaxVehicles(4);
      } else if (containerWidth >= 1020) {
        setMaxVehicles(3);
      } else if (containerWidth >= 680) {
        setMaxVehicles(2);
      } else {
        setMaxVehicles(1);
      }
    }
  };

  useEffect(() => {
    updateMaxVehiclesBasedOnWidth();
    window.addEventListener("resize", updateMaxVehiclesBasedOnWidth);
    return () => {
      window.removeEventListener("resize", updateMaxVehiclesBasedOnWidth);
    };
  }, []);

  console.log("DATA:", data);
  return (
    <Grid container ref={containerRef}>
      <Grid item xs={12}>
        <Typography variant="h3">Ähnliche Fahrzeuge</Typography>
      </Grid>

      <Grid container spacing={2}>
        {data?.slice(0, maxVehicles).map((vehicle) => (
          <VehicleListItem vehicle={vehicle} key={vehicle.na} />
        ))}
      </Grid>
    </Grid>
  );
};

export default MoreVehicles;
