import React from "react";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { IVehicleDetailed } from "@Interface/IVehicleDetailed";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const renderEquipmentList = (data: IVehicleDetailed) => {
  return (
    <Grid container className="equipment-list">
      {data?.equipment?.map((category: any, index: number) => (
        <React.Fragment key={category.name}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" component="div" sx={{ mt: 3 }}>
              {category.name}
            </Typography>
            <Divider />
          </Grid>

          {category.eq.map((item: string, index: number) => (
            <Grid item xs={12} sm={6} key={index}>
              <Typography variant="body1" sx={{ display: "flex", alignItems: "center", lineHeight: 1, mt: 1 }}>
                <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} /> {item}
              </Typography>
            </Grid>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  );
};

const EquipmentList: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  return (
    <>
      <Card>
        <CardContent>{renderEquipmentList(data)}</CardContent>
      </Card>
    </>
  );
};

export default EquipmentList;
