import React, { useEffect, useState, useMemo, useCallback } from "react";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { Box, Divider, Grid, Tab, Tabs, useTheme, Button, Typography } from "@mui/material";
import { IEquipmentCategory, IEquipment } from "@/interfaces/IEquipment";
import { CheckboxElement } from "@/components/Forms/CheckboxElement";
import { FILTER_VEHICLE_TYPE, TYPE_PKW, TYPE_LKW, TYPE_WOHNMOBIL, TYPE_MOTORCYCLE, TYPE_PKW_BIN, TYPE_WOHNMOBIL_BIN, TYPE_MOTORRAD_BIN, TYPE_LKW_BIN } from "@/constants/index";
import { ISelectData } from "@/interfaces/ISelectData";
import MpiLabel from "@/components/Labels/MpiLabel";
import useStore from "@store";
import EquipmentMostWantedComponentCheckbox from "./EquipmentMostWantedComponentCheckbox";
import EquipmentNormalComponentCheckbox from "./EquipmentNormalComponentCheckbox";

const EquipmentTabs: React.FC<ISearchParametersProps> = ({ ...searchParameters }, searchObject = {} as ISelectData) => {
  const { equipments } = useStore();
  const [equipmentCategories, setEquipments] = useState<IEquipmentCategory[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const [typeBin, setTypeBin] = useState(0);
  const [showNegative, setShowNegative] = useState(false);

  const columns = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 };

  useEffect(() => {
    if (equipments) {
      const updatedEquipments = equipments.filter((child: IEquipmentCategory) => child.id !== -1);
      setEquipments(updatedEquipments || []);
    }
  }, [equipments]);

  const handleEquipmentFilterClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, label: string, data: Record<string, number[]>) => {
      const { checked } = event.target;
      const name = "ai";
      const keyToAccess = label;
      const values = data[keyToAccess];

      searchParameters.setSearchObject((prevSelectData: ISelectData) => {
        const updatedSelectData = { ...prevSelectData };

        if (checked) {
          // add the selected value and remove the opposite value (positive / negative)
          updatedSelectData[name] = prevSelectData[name] ? [...prevSelectData[name].filter((value) => value !== -values[0]), values[0]] : [values[0]];
        } else {
          // remove the unselected value
          updatedSelectData[name] = prevSelectData[name].filter((value) => value !== values[0]);
        }

        // if the array is empty, remove the key
        if (updatedSelectData[name].length === 0) {
          delete updatedSelectData[name];
        }

        return updatedSelectData;
      });
    },
    [searchParameters]
  );

  const setValue = useCallback(
    (data: Record<string, number[]>) => {
      if (searchParameters.searchObject && searchParameters.searchObject.ai && searchParameters.searchObject.ai.length > 0 && Array.isArray(searchParameters.searchObject.ai)) {
        const keyToAccess = "ai";
        const values = data[keyToAccess];
        return searchParameters.searchObject.ai.includes(values[0]);
      }
      return false;
    },
    [searchParameters.searchObject]
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const searchObjectValue = useCallback((searchObject: ISelectData) => {
    for (const [key, value] of Object.entries(searchObject)) {
      if (key === FILTER_VEHICLE_TYPE && Array.isArray(value) && typeof value[0] === "number") {
        return value[0];
      }
    }
    return null;
  }, []);

  useEffect(() => {
    const containsValue = searchObjectValue(searchObject);
    switch (containsValue) {
      case TYPE_PKW:
        setTypeBin(TYPE_PKW_BIN);
        break;
      case TYPE_LKW:
        setTypeBin(TYPE_LKW_BIN);
        break;
      case TYPE_WOHNMOBIL:
        setTypeBin(TYPE_WOHNMOBIL_BIN);
        break;
      case TYPE_MOTORCYCLE:
        setTypeBin(TYPE_MOTORRAD_BIN);
        break;
      default:
        setTypeBin(TYPE_PKW_BIN + TYPE_LKW_BIN + TYPE_WOHNMOBIL_BIN + TYPE_MOTORRAD_BIN);
        break;
    }
  }, [searchObject, searchObjectValue]);

  const filterTyp = useCallback(
    (equipment: IEquipment) => {
      return 0 !== (equipment.class & typeBin);
    },
    [typeBin]
  );

  const filteredEquipmentCategories = useMemo(() => {
    return equipmentCategories
      .map((category) => {
        if (category && category.equipments) {
          const filteredEquipments = category.equipments.filter(filterTyp);
          if (filteredEquipments.length > 0) {
            return { ...category, equipments: filteredEquipments };
          }
        }
        return null;
      })
      .filter((category) => category !== null) as IEquipmentCategory[];
  }, [equipmentCategories, filterTyp]);

  return (
    <>
      <>
        {/* meistgesuchte Ausstattungen */}
        <MpiLabel title="meistgesuchte Ausstattungen" />
        <EquipmentMostWantedComponentCheckbox {...searchParameters} />
      </>

      <Divider sx={{ m: 2 }} />

      {/* Tabs Ausstattungen */}
      {filteredEquipmentCategories.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            className="equipment-tabs"
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              mb: 2
            }}
          >
            {filteredEquipmentCategories.map((category, index) => (
              <Tab
                label={category.name}
                key={index}
                sx={{
                  "&.Mui-selected": {
                    bgcolor: `${theme.palette.primary.main}`,
                    color: "secondary.main"
                  }
                }}
              />
            ))}
          </Tabs>
          {/* positive */}
          {filteredEquipmentCategories.map((category, index) => (
            <div key={category.id} hidden={currentTab !== index}>
              {currentTab === index && <EquipmentNormalComponentCheckbox category={category} {...searchParameters} />}
            </div>
          ))}
          {/* Toggle button for negative section */}
          {!showNegative && (
            <Box textAlign="center" my={2}>
              <Typography
                variant="h3"
                color="primary.dark"
                fontWeight="bold"
                component="div"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "primary.dark"
                  }
                }}
                onClick={() => setShowNegative((prev) => !prev)}
              >
                Weitere Filter: Ausstattungen ausschließen
              </Typography>
            </Box>
          )}
          {/* negative */}
          {showNegative && (
            <>
              <Divider sx={{ my: 2 }} />
              {filteredEquipmentCategories.map((category, index) => (
                <div key={category.id} hidden={currentTab !== index}>
                  {currentTab === index && (
                    <Grid
                      item
                      container
                      sx={{
                        padding: "3px"
                      }}
                    >
                      {category.equipments &&
                        category.equipments.map((equipment) => (
                          <Grid {...columns} flexDirection="row" item key={`${equipment.name}${equipment.id}`}>
                            <CheckboxElement
                              key={`${equipment.name}${-equipment.id}`}
                              name={equipment.name}
                              checked={setValue({ ai: [-equipment.id, 1] })}
                              onChange={(event) =>
                                handleEquipmentFilterClick(event, equipment.name, {
                                  [equipment.name]: [-equipment.id]
                                })
                              }
                              label={equipment.name}
                              data={{ ai: [equipment.id, 1] }}
                              bgColor={theme.palette.primary.main}
                              isNegative={true}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  )}
                </div>
              ))}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default EquipmentTabs;
