import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../redux/store";
import { Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import { getBackendApi } from "../tools/backendApis";
import { SET_SELECTED_CAR } from "../redux/constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getDetailUrl } from "@/tools/vehicleDataHandler";

const NewSearch: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cars = searchParameters.vehicleObject?.cars;
  const { vehicleId } = useParams<{ vehicleId: string }>();

  const [isDisablePrevButton, setDisablePrevButton] = useState(true);
  const [isDisableNextButton, setDisableNextButton] = useState(true);

  const getCurrentVehicleIndex = (cars: IVehicleListItem[]) => {
    return cars.findIndex((car) => car.li.split("/").pop() === vehicleId);
  };

  const findPrevVehicle = (cars: IVehicleListItem[]) => {
    const index = getCurrentVehicleIndex(cars);
    if (index > 0) {
      return cars[index - 1];
    }
  };

  const findNextVehicle = (cars: IVehicleListItem[]) => {
    const index = getCurrentVehicleIndex(cars);
    if (index >= 0 && index < cars.length - 1) {
      return cars[index + 1];
    }
  };

  const updateButtonStates = () => {
    if (cars) {
      const prevVehicle = findPrevVehicle(cars);
      const nextVehicle = findNextVehicle(cars);
      setDisablePrevButton(!prevVehicle);
      setDisableNextButton(!nextVehicle);
    }
  };

  useEffect(() => {
    updateButtonStates();
  }, [cars, vehicleId]);

  const previousVehicleClick = (cars: IVehicleListItem[]) => {
    const previousVehicle = findPrevVehicle(cars);

    if (previousVehicle) {
      const vehicleId = previousVehicle.li.split("/").pop();
      dispatch({
        type: SET_SELECTED_CAR,
        payload: getBackendApi(`/car/${vehicleId}`),
      });
      navigate(getDetailUrl(previousVehicle, customerConfiguration));
    }
  };

  const nextVehicleClick = (cars: IVehicleListItem[]) => {
    const nextVehicle = findNextVehicle(cars);

    if (nextVehicle) {
      const vehicleId = nextVehicle.li.split("/").pop();
      dispatch({
        type: SET_SELECTED_CAR,
        payload: getBackendApi(`/car/${vehicleId}`),
      });
      navigate(getDetailUrl(nextVehicle, customerConfiguration));
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 3, mb: 2 }}>
      <Grid item>
        <Tooltip title={isDisablePrevButton ? "" : "voriges Fahrzeug"}>
          <IconButton disabled={isDisablePrevButton} color="primary" onClick={() => previousVehicleClick(cars)} sx={{ p: 1 }}>
            <ArrowBackIosOutlinedIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Grid>

      <Grid item>
        <Link underline="hover" variant="h2" component="div" sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => navigate(customerConfiguration.vehicleList)}>
          <ArrowBackIcon sx={{ mr: 1, display: { xs: "none", md: "block" } }} />{" "}
          <Typography variant="subtitle1" color="primary">
            zurück zu den Suchergebnissen
          </Typography>
        </Link>
      </Grid>

      <Grid item>
        <Tooltip title={isDisableNextButton ? "" : "nächstes Fahrzeug"}>
          <IconButton disabled={isDisableNextButton} color="primary" onClick={() => nextVehicleClick(cars)} sx={{ p: 1, marginLeft: "auto" }}>
            <ArrowForwardIosOutlinedIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default NewSearch;
