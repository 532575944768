import React from "react";
import { Link } from "react-router-dom";
import car from "@/assets/img/placeholder-car.jpg";
import useStore from "@store";
import { Helmet } from "react-helmet";

const NotFoundPage: React.FC = () => {
  const { customerConfiguration } = useStore();

  const helmetCanonical = `${window.location.origin}/not-found-404`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={helmetCanonical} />
      </Helmet>
      <div style={{ textAlign: "center", padding: "20px" }}>
        <h1>404 - Seite nicht gefunden</h1>
        <p>Entschuldigung, die angeforderte Seite konnte nicht gefunden werden.</p>
        <p>Vielleicht suchen Sie ein spezielles Auto, das nicht auf unserer Website gelistet ist?</p>
        <p>
          Bitte überprüfen Sie die URL oder gehen Sie zurück zur <Link to={customerConfiguration.vehicleList}>zurück zu den Suchergebnissen</Link>.
        </p>
        <img src={car} alt="Car not found" style={{ width: "100%", maxWidth: "400px" }} />
      </div>
    </>
  );
};

export default NotFoundPage;
