import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Button, FormControlLabel, Radio, RadioGroup, FormControl, CardActions } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { useNavigate } from "react-router-dom";
import { FILTER_VEHICLE_CONDITION, TYPE_USED_VEHICLE, TYPE_NEW_VEHICLE, FILTER_VEHICLE_TYPE, TYPE_PKW, TYPE_LKW, TYPE_E_BIKE } from "@/constants/index";
import BranchComponentSelect from "@/FormComponents/SelectBoxes/BranchComponentSelect";
import MileageComponentRangeSlider from "@/FormComponents/RangeSlider/MileageComponentRangeSlider";
import FirstRegistrationYearComponentRangeSlider from "@/FormComponents/RangeSlider/FirstRegistrationYearComponentRangeSlider";
import PriceComponentRangeSlider from "@/FormComponents/RangeSlider//PriceComponentRangeSlider";
import ManufacturerComponentSelect from "@/FormComponents/SelectBoxes/ManufacturerComponentSelect";
import ModelComponentSelect from "@/FormComponents/SelectBoxes/ModelComponentSelect";
import FuelComponentSelect from "@/FormComponents/SelectBoxes/FuelComponentSelect";
import MpiLabel from "@/components/Labels/MpiLabel";
import { ISelectData } from "@/interfaces/ISelectData";
import useStore from "@store";

// das ist die QuickSearch
const Search: React.FC<ISearchParametersProps> = (searchParameters) => {
  const navigate = useNavigate();
  const [, setSearchclick] = useState(false);
  const [isNewVehicleOn, setNewVehicleOn] = useState(false);
  const [vehicleStatusType, setVehicleStatus] = useState<number>(searchParameters.searchObject.st?.includes(1) ? TYPE_NEW_VEHICLE : TYPE_USED_VEHICLE);
  useEffect(() => {
    searchParameters.setSearchObject((prevSelectData: ISelectData) => {
      const updatedData: ISelectData = {
        ...prevSelectData,
        // PKW am Start einstellen
        [FILTER_VEHICLE_TYPE]: TYPE_PKW
      };
      return updatedData;
    });
  }, []);

  useEffect(() => {
    const vehicleType = searchParameters.searchObject.ty ? searchParameters.searchObject.ty : "";

    searchParameters.setSearchObject((prevSelectData: ISelectData) => {
      const updatedData = { ...prevSelectData };

      if (vehicleType === TYPE_E_BIKE) {
        if (isNewVehicleOn) {
          updatedData[FILTER_VEHICLE_CONDITION] = [1];
        } else {
          updatedData[FILTER_VEHICLE_CONDITION] = [2];
        }
      } else if (vehicleType === TYPE_PKW) {
        if (isNewVehicleOn) {
          updatedData[FILTER_VEHICLE_CONDITION] = [1];
        } else {
          updatedData[FILTER_VEHICLE_CONDITION] = [2, 4, 6];
        }
      } else if (vehicleType === TYPE_LKW) {
        if (isNewVehicleOn) {
          updatedData[FILTER_VEHICLE_CONDITION] = [1];
        } else {
          updatedData[FILTER_VEHICLE_CONDITION] = [2];
        }
      } else {
        if (isNewVehicleOn) {
          updatedData[FILTER_VEHICLE_CONDITION] = [1];
        } else {
          updatedData[FILTER_VEHICLE_CONDITION] = [2];
        }
      }

      return updatedData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewVehicleOn, searchParameters.searchObject.ty]);

  const { customerConfiguration } = useStore();

  const { facets } = useStore();
  const searchhandleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setSearchclick(true);
    navigate(customerConfiguration.vehicleList);
  };

  const handleVehicleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newVehicleStatus = Number(event.target.value);
    setVehicleStatus(newVehicleStatus);

    const isVehicleNew = newVehicleStatus === TYPE_NEW_VEHICLE;
    setNewVehicleOn(isVehicleNew);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {facets && facets?.loA && Object.keys(facets?.loA).length > 1 && (
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MpiLabel title="Filiale" />
            <BranchComponentSelect {...searchParameters} />
          </Grid>
        )}

        {searchParameters.searchObject?.ty && (
          <Grid item lg={8}>
            <MpiLabel title="" />
            <FormControl component="fieldset">
              <RadioGroup row name="row-radio-buttons-group" value={vehicleStatusType} onChange={handleVehicleTypeChange}>
                <FormControlLabel checked={isNewVehicleOn} value={TYPE_NEW_VEHICLE} control={<Radio />} label="Neufahrzeug" />
                <FormControlLabel checked={!isNewVehicleOn} value={TYPE_USED_VEHICLE} control={<Radio />} label="Gebrauchtfahrzeug" />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} sx={{ flexDirection: "row", mb: 2 }}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <MpiLabel title="Hersteller" />
          <ManufacturerComponentSelect {...searchParameters} />
        </Grid>
        {searchParameters.searchObject?.ty && !(searchParameters.searchObject?.ty === TYPE_E_BIKE) && (
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MpiLabel title="Modell" />
            <ModelComponentSelect {...searchParameters} />
          </Grid>
        )}
        {facets && facets?.sp && (
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <MpiLabel title="Preis" />
            <PriceComponentRangeSlider {...searchParameters} locale="de-DE" />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {!isNewVehicleOn && searchParameters.searchObject.ty && !(searchParameters.searchObject.ty === TYPE_E_BIKE) && (
          <>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <MpiLabel title="Laufleistung" />
              <MileageComponentRangeSlider {...searchParameters} locale="de-DE" />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <MpiLabel title="Erstzulassung" />
              <FirstRegistrationYearComponentRangeSlider {...searchParameters} />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <MpiLabel title="Kraftstoff" />
              <FuelComponentSelect {...searchParameters} />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
        <Grid item>
          <Button size="large" startIcon={<SearchOutlinedIcon />} onClick={() => navigate(customerConfiguration.detailSearch)} sx={{ m: 0 }}>
            Detailsuche
          </Button>
        </Grid>
        <Grid item>
          <CardActions>
            <Button size="large" variant="contained" startIcon={<DirectionsCarFilledOutlinedIcon />} onClick={searchhandleClick} sx={{ m: 0.5 }}>
              {searchParameters.vehicleObject.count || 0} Fahrzeuge
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </>
  );
};

export default Search;
