import React, { useState, useEffect } from "react";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";
import EquipmentTabs from "./EquipmentComponentTabs";
import EquipmentAccordion from "./EquipmentComponentAccordion";

const Equipment: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const [isAccordionVisible, setIsAccordionVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsAccordionVisible(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div className="equipment-container">{isAccordionVisible ? <EquipmentAccordion {...searchParameters}></EquipmentAccordion> : <EquipmentTabs {...searchParameters}></EquipmentTabs>}</div>;
};

export default Equipment;
