import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";

interface ExposeeLoaderProps {
  cgvid: string;
}
function ExposeeLoader({ cgvid }: ExposeeLoaderProps) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await fetch(cgvid, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`Fehler beim Laden des PDF: ${response.statusText}`);
        }

        const blob = await response.blob();
        setPdfUrl(URL.createObjectURL(blob));
      } catch (err) {
        console.error("Fehler beim Laden des PDF", err);
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfData();
  }, [cgvid]);

  if (loading) {
    return (
      <div>
        <h2
          style={{
            textAlign: "center",
            verticalAlign: "center",
            width: "100%",
            marginTop: "3em",
          }}
        >
          Bitte warten, Exposé wird generiert und geladen...
        </h2>
      </div>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" component="div">
        Fehler beim Laden des Exposés
      </Typography>
    );
  }

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: "100vh" }}>
      <object type="application/pdf" style={{ width: "100%", height: "100%" }} data={pdfUrl}>
        PDF kann nicht angezeigt werden
      </object>
    </Stack>
  );
}

export default ExposeeLoader;
