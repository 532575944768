import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { CircularProgress, Grid, Switch, FormControlLabel } from "@mui/material";
import SliderInputElement from "../../components/SliderInputElement";
import SliderTextBoxElements from "../../components/SliderTextBoxElements";
import { IRg } from "@Interface/IRg";
import { ISearchParametersProps } from "@Interface/ISearchParametersProps";

const kWtoPS = (value: number) => Math.round(value * 1.35962); // Umrechnungsfunktion von kW in PS

const PerformanceComponentRangeSlider: React.FC<ISearchParametersProps> = ({ ...searchParameters }, locale) => {
  const rg = useSelector((state: RootState) => state.ConfigurationReducer.rg as IRg);

  const [unit, setUnit] = useState("kw");
  const [sliderValues, setSliderValues] = useState<number[]>([]);

  // Effekt zur Initialisierung der Slider-Werte basierend auf rg oder bei Reset von searchObject
  useEffect(() => {
    // Prüft, ob rg Daten vorhanden sind und der Slider noch nicht initialisiert wurde,
    // oder ob ein Reset von searchObject erfolgt ist.
    if (rg && rg.poA && rg.poA.length > 0) {
      let initialIndexes = [0, rg.poA.length - 1];

      // Überprüft, ob die Start- und Endwerte von searchObject innerhalb der gültigen rg.poA-Werte liegen
      if (searchParameters.searchObject.poF !== undefined && searchParameters.searchObject.poT !== undefined) {
        const startIndex = rg.poA.indexOf(searchParameters.searchObject.poF);
        const endIndex = rg.poA.indexOf(searchParameters.searchObject.poT);
        if (startIndex !== -1 && endIndex !== -1) {
          initialIndexes = [startIndex, endIndex];
        }
      }

      setSliderValues(initialIndexes);
    }
  }, [rg, searchParameters.searchObject]);

  // Handler für die Änderung der Slider-Positionen
  const handleChange = (newValue: number[]) => {
    setSliderValues(newValue);
    const newValues = newValue.map((index) => rg.poA[index]);
    // Aktualisierung der Suchparameter im globalen Zustand
    searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      poF: newValues[0],
      poT: newValues[1],
    }));
  };

  // Handler für die Änderung der Einheit (kW/PS)
  const handleUnitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnit(event.target.checked ? "ps" : "kw");
  };

  // Anzeige eines Ladeindikators, falls die Daten noch nicht geladen sind
  if (!rg || !rg.poA || rg.poA.length === 0) {
    return <CircularProgress />;
  }

  // Berechnung der Werte für die Textboxen basierend auf der aktuellen Einheit
  const textBoxValues = sliderValues.map((index) => {
    const value = rg.poA[index];
    return unit === "ps" ? kWtoPS(value) : value;
  });

  // Erstellung der Markierungen für den Slider basierend auf der aktuellen Einheit
  const marks = rg.poA
    .map((value, index) => ({
      value: index,
      label: `${unit === "kw" ? value : kWtoPS(value)} ${unit.toUpperCase()}`,
    }))
    .filter((_, index) => index === 0 || index === rg.poA.length - 1);

  // Erstellung der Tooltips für den Slider basierend auf der aktuellen Einheit
  const marksToolTip = rg.poA.map((value, index) => ({
    value: index,
    label: `${unit === "kw" ? value : kWtoPS(value)} ${unit.toUpperCase()}`,
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={9} sm={8}>
        <SliderTextBoxElements textBoxValues={textBoxValues} locale={locale} unit={unit} />
      </Grid>
      <Grid item xs={3} sm={2}>
        <FormControlLabel control={<Switch checked={unit === "ps"} onChange={handleUnitChange} />} label="KW/PS" />
      </Grid>
      <Grid container>
        <Grid item xs={10} sm={10}>
          <SliderInputElement
            onChange={handleChange}
            data={sliderValues}
            marks={marks}
            marksToolTip={marksToolTip} // Übergabe der Tooltips an das SliderInputElement
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PerformanceComponentRangeSlider;
