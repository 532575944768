import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, List, ListItem, Typography } from "@mui/material";
import { IVehicleDetailed } from "@Interface/IVehicleDetailed";
import { marked } from "marked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

// Funktion zum Parsen und Formatieren von Text
const parseAndFormatText = async (text: string): Promise<string[]> => {
  text = text.replace(/<\/?p>/g, "");
  text = text.replace(/<\/?em>/g, "");
  text = text.replace(/<\/?span>/g, "");
  text = text.replace(/<\/?div>/g, "");
  text = text.replace(/<\/?ul>/g, "");
  text = text.replace(/<\/?li>/g, "");
  text = replaceAsterisksWithStrong(text);
  text = decodeHtmlEntities(text);

  let items = text.split("\\ * ").filter((item: string) => item.trim() !== "");
  items = items.map((item) => {
    return item.replace(/\\/g, " ").replaceAll(" ----", ". ");
  });
  return items;
};

//Weg mit den HTML Entities
function decodeHtmlEntities(text: string): string {
  const entityMap: { [key: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#39;": "'",
    "&#x2F;": "/",
    "&#x60;": "`",
    "&#x3D;": "=",
  };

  return text.replace(/&[#0-9a-zA-Z]+;/g, (entity) => {
    return entityMap[entity] || entity;
  });
}

// Funktion zum Parsen von Überschriften
const parseHeadline = (text: string): string[] => {
  text = text.replace(/<\/?strong>/g, "");
  const items = text.split("\\ * ").filter((item: string) => item.trim() !== "");
  return items;
};

const replaceAsterisksWithStrong = (text: string): string => {
  let inStrong = false;
  return text.replace(/\*\*/g, () => {
    inStrong = !inStrong;
    return inStrong ? "<strong>" : "</strong>";
  });
};

const VehicleDescription: React.FC<{ data: IVehicleDetailed }> = ({ data }) => {
  const [formattedItems, setFormattedItems] = useState<string[]>([]);

  const descriptionText = data.descF ? data.descF : data.desc;

  useEffect(() => {
    const fetchFormattedItems = async () => {
      let text: string | Promise<string> = descriptionText ? marked.parse(descriptionText) : "";
      if (typeof text === "object" && typeof text.then === "function") {
        text = await text;
      }

      const items = await parseAndFormatText(text as string);
      setFormattedItems(items);
    };

    fetchFormattedItems();
  }, [descriptionText]);

  const isBigText = formattedItems.length > 2;

  return (
    <Card sx={{ width: "100%", pt: 1 }}>
      <CardContent>
        <List>
          {formattedItems.map((item: string, index: number) => (
            <ListItem key={index} sx={{ padding: 0 }}>
              {item.includes("<strong>") ? (
                <Typography variant="body1" component="strong" mt={2}>
                  {parseHeadline(item)}
                </Typography>
              ) : (
                <Typography variant="body2" component="span">
                  <Box sx={{ display: "flex" }}>
                    {isBigText && <CheckCircleOutlineIcon fontSize="small" color="primary" sx={{ mr: 1 }} />}
                    {item}
                  </Box>
                </Typography>
              )}
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default VehicleDescription;
