import React from "react";
import { ICheckboxProps } from "@/interfaces/ICheckboxProps";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

export function CheckboxElement({ label, onChange, name, data, checked, showAmountLabel = true, color, isNegative = false }: ICheckboxProps) {
  const handleLocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const amountLabel = data[label]?.[1] && showAmountLabel ? `(${data[label]?.[1]})` : "";
  const labelText = `${label} ${amountLabel}`;

  if (Object.keys(data).length >= 0) {
    return (
      <FormControlLabel
        control={<Checkbox disableRipple checked={checked} onChange={handleLocalChange} name={name} />}
        label={
          <div
            style={{
              maxWidth: "100%",
              color: color
            }}
          >
            {isNegative && <Typography fontWeight={"bold"}>{"Keine/e"}</Typography>}
            <Typography sx={{ lineHeight: 1.2, padding: "2px 0", whiteSpace: "nowrap" }}>{labelText}</Typography>
          </div>
        }
      />
    );
  } else {
    return null;
  }
}
