import React from "react";
import { ICheckboxProps } from "@Interface/ICheckboxProps";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";

export function CheckboxElement({ label, onChange, name, data, checked, showAmountLabel = false, color }: ICheckboxProps) {
  const handleLocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const labelText = label === "Mwst ausweisbar" ? label : `${label} ${data[label]?.[1] && showAmountLabel ? `(${data[label]?.[1]})` : ""}`;

  if (Object.keys(data).length >= 0) {
    return (
      <FormControlLabel
        control={<Checkbox disableRipple checked={checked} onChange={handleLocalChange} name={name} />}
        label={
          <div
            style={{
              maxWidth: "13em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: color,
            }}
          >
            <Typography noWrap>{labelText}</Typography>
          </div>
        }
      />
    );
  } else {
    return null;
  }
}
