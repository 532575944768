import React from "react";
import { IVehicleListItem } from "@Interface/IVehicleListItem";
import Iconify from "@/components/Iconify";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import DirectionsBikeOutlinedIcon from "@mui/icons-material/DirectionsBikeOutlined";
import Battery50OutlinedIcon from "@mui/icons-material/Battery50Outlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CategoryIcon from "@mui/icons-material/Category";
import CropLandscapeIcon from "@mui/icons-material/CropLandscape";
import SpeedIcon from "@mui/icons-material/SpeedOutlined";
import WaterDropOutlinedIcon from "@mui/icons-material/WaterDropOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import placeholderCar from "@/assets/img/placeholder-car.jpg";
import placeholderEbike from "@/assets/img/placeholder-ebike.jpg";
import { IVehicleDetailed } from "@/redux/api/interfaces/IVehicleDetailed";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { TYPE_E_BIKE, TYPE_LKW, TYPE_PKW } from "@/redux/constants";
import InvertColorsIcon from "@mui/icons-material/InvertColors";

const sxIcon = {
  width: "22px",
  mr: 1,
};

export const getVehicleType = (vehicle: IVehicleListItem | IVehicleDetailed) => {
  if (!vehicle) {
    return "unknown";
  } else if (vehicle.ty === "e-Bike") {
    return TYPE_E_BIKE;
  } else if (vehicle.ty === "PKW") {
    return TYPE_PKW;
  } else if (vehicle.ty === "LKW") {
    return TYPE_LKW;
  } else {
    return TYPE_PKW;
  }

  /**
   * future else: plain, zeplin, rocket...
   */
};
export const isBike = (vehicle: IVehicleListItem | IVehicleDetailed) => {
  return getVehicleType(vehicle) === TYPE_E_BIKE;
};

//no dots, no white spaces, no slashes, no upper case
const cleanVal = (val: string): string => (val ? val.toLowerCase().trim().replace(/\s+/g, "-").replace(/\./g, "-").replace(/\_/g, "-") : "-").replace(/\//g, "-");

export const getDetailUrl = (vehicle: IVehicleListItem, customerConfiguration: ICustomerConfiguration): string => {
  const { li, ty, h, m, na, en, tr } = vehicle;
  const vehicleId = cleanVal(li?.split("/").pop());
  const vehicleTy = cleanVal(ty);
  const vehicleH = cleanVal(h);
  const vehicleM = cleanVal(m);
  const vehicleNa = cleanVal(na);
  const vehicleEn = cleanVal(en?.en);
  const vehicleTr = cleanVal(tr);

  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return [customerConfiguration?.vehicleDetail, vehicleTy, vehicleId].join("/");

    default:
      return [customerConfiguration?.vehicleDetail, vehicleTy, vehicleId, vehicleH, vehicleM, vehicleNa, vehicleEn, vehicleTr].join("/");
  }
};

export const validateDetailUrl = (vehicle: IVehicleDetailed, params: any): boolean => {
  const { vehicleTy, vehicleH, vehicleM, vehicleNa, vehicleEn, vehicleTr } = params;
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return vehicleTy === cleanVal(vehicle.ty);

    default:
      return vehicleTy === cleanVal(vehicle.ty) && vehicleH === cleanVal(vehicle.ma) && vehicleM === cleanVal(vehicle.mo) && vehicleNa === cleanVal(vehicle.na) && vehicleEn === cleanVal(vehicle.engine?.engine) && vehicleTr === cleanVal(vehicle.tr?.tr);
  }
};

export const getPlaceholder = (vehicle?: IVehicleListItem | IVehicleDetailed) => {
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return placeholderEbike;
    case TYPE_PKW:
      return placeholderCar;
    default:
      return placeholderCar;
  }
};

interface RenderData {
  value: any;
  icon: JSX.Element;
  tooltip?: string;
}

export const getListAttrSmall = (vehicle: IVehicleListItem): RenderData[] => {
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return [
        { value: vehicle.st, icon: <DirectionsBikeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.rm, icon: <CropLandscapeIcon sx={sxIcon} />, tooltip: "Rahmenmaterial und Rahmenhöhe" },
        { value: vehicle.k, icon: <CategoryIcon sx={sxIcon} />, tooltip: "Kategorie" },
        { value: vehicle.ws, icon: <CircleOutlinedIcon sx={sxIcon} />, tooltip: "Radgrösse" },
        { value: vehicle.bc, icon: <Battery50OutlinedIcon sx={sxIcon} />, tooltip: "Baterie Hersteller und Baterie Kapazität" },
        { value: vehicle.co?.[0], icon: <InvertColorsIcon sx={sxIcon} />, tooltip: "Farbe" },
      ];

    case TYPE_PKW:
      return [
        { value: vehicle.st, icon: <AccessTimeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.en?.en, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle.en?.po, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];

    case TYPE_LKW:
      return [
        { value: vehicle.st, icon: <AccessTimeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.en?.en, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle.en?.po, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];
  }
};

export const getListAttrMedium = (vehicle: IVehicleListItem): RenderData[] => {
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return [
        { value: vehicle.st, icon: <DirectionsBikeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.rm, icon: <CropLandscapeIcon sx={sxIcon} />, tooltip: "Rahmenmaterial und Rahmenhöhe" },
        { value: vehicle.k, icon: <CategoryIcon sx={sxIcon} />, tooltip: "Kategorie" },
        { value: vehicle.ws, icon: <CircleOutlinedIcon sx={sxIcon} />, tooltip: "Radgrösse" },
        { value: vehicle.bc, icon: <Battery50OutlinedIcon sx={sxIcon} />, tooltip: "Baterie Hersteller und Baterie Kapazität" },
        { value: vehicle.co?.[0], icon: <InvertColorsIcon sx={sxIcon} />, tooltip: "Farbe" },
      ];

    case TYPE_PKW:
      return [
        { value: vehicle.st, icon: <DriveEtaOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.en?.en, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle.en?.po, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];

    case TYPE_LKW:
      return [
        { value: vehicle.st, icon: <DriveEtaOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.en?.en, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle.en?.po, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];
  }
};

export const getListAttrLarge = (vehicle: IVehicleListItem): RenderData[] => {
  console.log("vehicle.fr,: ", vehicle.fr);
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return [
        { value: vehicle.st, icon: <DirectionsBikeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.rm, icon: <CropLandscapeIcon sx={sxIcon} />, tooltip: "Rahmenmaterial und Rahmenhöhe" },
        { value: vehicle.k, icon: <CategoryIcon sx={sxIcon} />, tooltip: "Kategorie" },
        { value: vehicle.ws, icon: <CircleOutlinedIcon sx={sxIcon} />, tooltip: "Radgrösse" },
        { value: vehicle.bc, icon: <Battery50OutlinedIcon sx={sxIcon} />, tooltip: "Baterie Hersteller und Baterie Kapazität" },
        { value: vehicle.co?.[0], icon: <InvertColorsIcon sx={sxIcon} />, tooltip: "Farbe" },
      ];

    case TYPE_PKW:
      return [
        { value: vehicle.st, icon: <DriveEtaOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.en?.en, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle.en?.po, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];
    case TYPE_LKW:
      return [
        { value: vehicle.st, icon: <DriveEtaOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.en?.en, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle.en?.po, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];
  }
};

export const getListAttrVehicleDetailsQuickinformation = (vehicle: IVehicleDetailed): RenderData[] => {
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return [
        { value: vehicle.st, icon: <DirectionsBikeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.rm, icon: <CropLandscapeIcon sx={sxIcon} />, tooltip: "Rahmenmaterial und Rahmenhöhe" },
        { value: vehicle.k, icon: <CategoryIcon sx={sxIcon} />, tooltip: "Kategorie" },
        { value: vehicle.ws, icon: <CircleOutlinedIcon sx={sxIcon} />, tooltip: "Radgrösse" },
        { value: vehicle.color?.base, icon: <InvertColorsIcon sx={sxIcon} />, tooltip: "Farbe" },
        { value: vehicle.bc, icon: <Battery50OutlinedIcon sx={sxIcon} />, tooltip: "Baterie Hersteller und Baterie Kapazität" },
      ];

    case TYPE_PKW:
      return [
        { value: vehicle.st, icon: <AccessTimeOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle.bo, icon: <DriveEtaOutlinedIcon sx={sxIcon} />, tooltip: "Aufbau" },
        { value: vehicle?.engine?.engine, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle?.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle?.tr?.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle?.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle?.engine?.power, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];
    case TYPE_LKW:
      return [
        { value: vehicle.st, icon: <DriveEtaOutlinedIcon sx={sxIcon} />, tooltip: "Status" },
        { value: vehicle?.engine?.engine, icon: <WaterDropOutlinedIcon sx={sxIcon} />, tooltip: "Kraftstoff" },
        { value: vehicle?.km, icon: <SpeedIcon sx={sxIcon} />, tooltip: "Kilometerstand" },
        { value: vehicle?.tr?.tr, icon: <Iconify icon="tabler:manual-gearbox" sx={sxIcon} />, tooltip: "Getriebe" },
        { value: vehicle?.fr, icon: <CalendarMonthOutlinedIcon sx={sxIcon} />, tooltip: "Erstzulassung" },
        { value: vehicle?.engine?.power, icon: <Iconify icon="mdi:motor-outline" sx={sxIcon} />, tooltip: "Leistung" },
      ];
  }
};

interface RenderDataMoreInformation {
  label: string;
  value: any;
  tooltip?: string;
  isImg?: boolean;
}
export const getListAttrVehicleDetailsMoreinformation = (vehicle: IVehicleDetailed): RenderDataMoreInformation[] => {
  switch (getVehicleType(vehicle)) {
    case TYPE_E_BIKE:
      return [
        { value: vehicle.fh, label: "Rahmenhöhe:" },
        { value: vehicle.bm, label: "Batterie Hersteller:" },
        { value: vehicle.gt, label: "Gangschaltung:" },
        { value: vehicle.gn, label: "Anzahl der Gänge:" },
        { value: vehicle.sf, label: "geeignent für:" },
        { value: vehicle.mp, label: "Motor Position:" },
        { value: vehicle.bp, label: "Batterie Position:" },
        { value: vehicle.fs, label: "Rahmenform:" },
        { value: vehicle.color?.manfacturer, label: "Herstellerfarbname", tooltip: vehicle.color?.manfacturer },
        { value: vehicle.wg, label: "Gewicht:" },
        { value: vehicle.du, label: "unreparierter Schaden:" },
      ];

    case TYPE_PKW:
      return [
        { value: vehicle.engine.engine !== "Elektro" ? vehicle.engine.size : "", label: "Hubraum:" },
        { value: vehicle.PreviousOwners, label: "Anzahl Vorbesitzer:" },
        { value: vehicle.do, label: "Anzahl Türen:" },
        { value: vehicle.gn, label: "Anzahl der Gänge" },
        { value: vehicle.color?.color, label: "Außenfarbe:", tooltip: vehicle.color?.color },
        { value: vehicle.interieur?.Color, label: "Innenfarbe:", tooltip: vehicle.interieur?.Color },
        { value: vehicle.interieur?.Upholstery, label: "Polsterung:" },
        { value: vehicle.seal, label: "Qualitätssiegel:", isImg: true },
      ];
    case TYPE_LKW:
      return [
        { value: vehicle.engine.engine !== "Elektro" ? vehicle.engine.size : "", label: "Hubraum:" },
        { value: vehicle.PreviousOwners, label: "Anzahl Vorbesitzer:" },
        { value: vehicle.do, label: "Anzahl Türen:" },
        { value: vehicle.gn, label: "Anzahl der Gänge" },
        { value: vehicle.color?.color, label: "Außenfarbe:", tooltip: vehicle.color?.color },
        { value: vehicle.interieur?.Color, label: "Innenfarbe:", tooltip: vehicle.interieur?.Color },
        { value: vehicle.interieur?.Upholstery, label: "Polsterung:" },
        { value: vehicle.seal, label: "Qualitätssiegel:", isImg: true },
      ];
  }
};

interface IFinancing {
  PurchasePrice: number;
  Deposit: number;
  BalloonPayment: number;
  Duration: number;
  EffectiveRate: number;
  MonthlyPayment: number;
  NetCredit: number;
  GrossCredit: number;
  RatePA: number;
}
export const getFinancingData = (data: IVehicleDetailed): IFinancing => {
  function euroToNumber(euroString: string): number {
    const cleanedString = euroString.replace("€", "").trim();
    const numberString = cleanedString.replace(/\./g, "");
    const number = parseFloat(numberString);
    return number;
  }

  const percentToNumber = (percentString: string): number => {
    const cleanedString = percentString.replace("%", "").trim();
    const normalizedString = cleanedString.replace(",", ".");
    const number = parseFloat(normalizedString);
    return number;
  };

  return {
    PurchasePrice: data.PriceSale,
    Deposit: euroToNumber(data.Finanzierung.anzahlung),
    BalloonPayment: euroToNumber(data.Finanzierung.schlussrate),
    Duration: 48,
    EffectiveRate: percentToNumber(data.Finanzierung.jahreszins),
    MonthlyPayment: 0,
    NetCredit: 0,
    GrossCredit: 0,
    RatePA: percentToNumber(data.Finanzierung.sollzins),
  };
};
