import React from "react";
import { Typography, TypographyOwnProps, useTheme } from "@mui/material";

interface MpiLabelProps extends TypographyOwnProps {
  title: string;
}

const MpiLabel: React.FC<MpiLabelProps> = (props) => {
  const colorGray = useTheme().palette.grey[500];

  return (
    <Typography variant="body1" color={colorGray} sx={{ mb: 1, ...props.sx }}>
      {props.title}
    </Typography>
  );
};

export default MpiLabel;
