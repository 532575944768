import React from "react";
import { ICheckboxFilter } from "@/interfaces/ICheckboxFilter";
import { Grid } from "@mui/material";
import MpiLabel from "@/components/Labels/MpiLabel";
import InteriorColorComponentCheckbox from "@/FormComponents/CheckBoxes/InteriorColorComponentCheckbox";
import InteriorMaterialComponentCheckbox from "@/FormComponents/CheckBoxes/InteriorMaterialComponentCheckbox";

const InsideColorComponent: React.FC<ICheckboxFilter> = ({ showAllFilter, ...searchParameters }) => {
  return (
    <>
      <MpiLabel title="Farbe der Innenausstattung" />
      <Grid container sx={{ mb: 2 }}>
        <InteriorColorComponentCheckbox {...searchParameters} showToggleButton={false} showAllFilter={showAllFilter} />
      </Grid>

      <MpiLabel title="Material der Innenausstattung" />
      <Grid container>
        <InteriorMaterialComponentCheckbox {...searchParameters} showToggleButton={false} showAllFilter={showAllFilter} />
      </Grid>
    </>
  );
};

export default InsideColorComponent;
