// taken from dashboard project and modified
//much to improve here
/* eslint-disable */
// @ts-nocheck

import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Button } from "@mui/material";

const Exterior360View = (props) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const { images } = props;
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const intervalRef = useRef<NodeJS.Timeout | number | undefined>(undefined);
  const zoomFactor = 0.1;
  const maxZoomLevel = 2;
  const minZoomLevel = 1;
  const [clientX, setClientX] = useState(0);
  const [clientY, setClientY] = useState(0);
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX);
    document.body.style.cursor = "grabbing";
  };

  const handleMouseMove = (event) => {
    setClientX(event.clientX);
    setClientY(event.clientY);

    if (isDragging) {
      const dragDistance = event.clientX - dragStartX;
      const threshold = 50;
      if (dragDistance > threshold) {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
        setDragStartX(event.clientX);
        setIsMoving(true);
      } else if (dragDistance < -threshold) {
        setCurrentImageIndex(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
        setDragStartX(event.clientX);
        setIsMoving(true);
      } else {
        setIsMoving(false);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsMoving(false);
    clearInterval(intervalRef.current);
    document.body.style.cursor = "auto";
  };

  const handleMouseOut = () => {
    if (isDragging) {
      setIsDragging(false);
      setIsMoving(false);
      clearInterval(intervalRef.current);
      document.body.style.cursor = "auto";
    }
  };

  const handleContainerWheel = (event) => {
    setDragStartX(event.clientX);
    setIsMoving(true);
    const { deltaY } = event;
    if (deltaY < 0) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel + zoomFactor);
    } else if (deltaY > 0 && zoomLevel >= 1.1) {
      setZoomLevel((prevZoomLevel) => parseFloat(Math.max(prevZoomLevel - zoomFactor, 0.1).toFixed(1)));
    }
  };

  const handleContainerMouseEnter = () => {
    document.body.style.overflow = "hidden";
  };

  const handleContainerMouseLeave = () => {
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (isDragging && isMoving) {
      intervalRef.current = setInterval(() => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
      }, 100);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isDragging, isMoving, currentImageIndex]);

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();
    image.src = images[currentImageIndex];
    image.onload = () => {
      const canvasWidth = canvas.clientWidth;
      const canvasHeight = canvas.clientHeight;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      const imageWidth = canvasWidth * zoomLevel;
      const imageHeight = canvasHeight * zoomLevel;
      const mouseX = clientX - canvas.getBoundingClientRect().left;
      const mouseY = clientY - canvas.getBoundingClientRect().top;
      const imageX = -((mouseX * (imageWidth - canvasWidth)) / canvasWidth);
      const imageY = -((mouseY * (imageHeight - canvasHeight)) / canvasHeight);
      context.drawImage(image, imageX, imageY, imageWidth, imageHeight);
    };
  }, [currentImageIndex, images, zoomLevel]);

  const setCanvasHeight = () => {
    const canvasWidth = canvasRef.current.offsetWidth;
    const canvasHeight = Math.round((canvasWidth * 780) / 1170);
    canvasRef.current.style.height = `${canvasHeight}px`;
  };

  useEffect(() => {
    window.addEventListener("resize", setCanvasHeight);
    return () => {
      window.removeEventListener("resize", setCanvasHeight);
    };
  }, []);

  useEffect(() => {
    setCanvasHeight();
  }, []);

  useEffect(() => {
    if (props.changeZoomLevel) {
      props.changeZoomLevel(zoomLevel);
    }
  }, [zoomLevel]);

  useEffect(() => {
    setClientX(centerX);
    setClientY(centerY);
  }, [centerX]);

  const zoomIn = () => {
    const canvasWidth = canvasRef.current.clientWidth;
    const canvasHeight = canvasRef.current.clientHeight;

    if (centerX === 0 && centerY === 0) {
      setCenterX(clientX + canvasWidth / 2);
      setCenterY(clientY);
    }

    setClientX(centerX);
    setClientY(centerY);
    setZoomLevel((prevZoomLevel) => parseFloat(Math.min(prevZoomLevel + zoomFactor, maxZoomLevel).toFixed(1)));
  };

  const zoomOut = () => {
    setClientX(centerX);
    setClientY(centerY);
    setZoomLevel((prevZoomLevel) => parseFloat(Math.max(prevZoomLevel - zoomFactor, minZoomLevel).toFixed(1)));
  };

  const handleTouchStart = (event) => {
    event.preventDefault();
    setIsDragging(true);
    setDragStartX(event.touches[0].clientX);
    document.body.style.cursor = "grabbing";
  };

  const handleTouchMove = (event) => {
    event.preventDefault();
    setClientX(event.touches[0].clientX);
    setClientY(event.touches[0].clientY);
    if (isDragging) {
      const dragDistance = event.touches[0].clientX - dragStartX;
      const threshold = 50;
      if (dragDistance > threshold) {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
        setDragStartX(event.touches[0].clientX);
        setIsMoving(true);
      } else if (dragDistance < -threshold) {
        setCurrentImageIndex(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
        setDragStartX(event.touches[0].clientX);
        setIsMoving(true);
      } else {
        setIsMoving(false);
      }
    }
  };

  const handleTouchEnd = (event) => {
    event.preventDefault();
    setIsDragging(false);
    setIsMoving(false);
    clearInterval(intervalRef.current);
    document.body.style.cursor = "auto";
  };

  const handleTouchCancel = () => {
    if (isDragging) {
      setIsDragging(false);
      setIsMoving(false);
      clearInterval(intervalRef.current);
      document.body.style.cursor = "auto";
    }
  };

  return (
    <div ref={containerRef} id="Exterior360" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseOut={handleMouseOut} onBlur={handleMouseOut} onWheel={handleContainerWheel} onMouseEnter={handleContainerMouseEnter} onMouseLeave={handleContainerMouseLeave} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} onTouchCancel={handleTouchCancel} style={{ overflow: "hidden", position: "relative" }}>
      <canvas ref={canvasRef} style={{ width: "100%", height: "100%" }} data-testid="Exterior360View" />
      <Box
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          cursor: "pointer",
          zIndex: 99,
          background: "rgba(255, 255, 255, 0.9)",
          color: "white"
        }}
      >
        <Button id="zoomOut" type="button" className="exterior-360-buttons zoom-out-button" onClick={zoomOut}>
          <RemoveCircleOutlineIcon />
        </Button>
        <Button id="zoomIn" type="button" className="exterior-360-buttons zoom-in-button" onClick={zoomIn}>
          <AddCircleOutlineIcon />
        </Button>
      </Box>
    </div>
  );
};

Exterior360View.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeZoomLevel: PropTypes.func
};

export default Exterior360View;
