import { SET_FACETS_OBJECT, SET_SEARCHRESULTS_OBJECT, SET_SEARCH_OBJECT, SET_RANGES_OBJECT, SET_RG_OBJECT } from "../constants/index";
import { useState, useEffect, useCallback, Dispatch } from "react";
import { ISelectData } from "./interfaces/ISelectData.js";
import { IData } from "./interfaces/IData";
import { AnyAction } from "redux";

const facetsOR = ["ai", "st"];

export const GetFacettenFromApi = (searchObject: ISelectData, dispatch: Dispatch<AnyAction>, url: string, onlyFacetts: boolean) => {
  const [apiData, setApiData] = useState<IData>({} as IData);
  const serverUrl = url + (onlyFacetts ? "cars1" : "cars");

  const generateHTTPUrl = useCallback(
    (searchObject: ISelectData) => {
      const queryParams: Record<string, string> = {};
      const collectedValues: Record<string, string> = {};

      // Sammle alle Werte für jeden Key
      Object.entries(searchObject).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // Fügt alle Werte des Arrays hinzu, getrennt durch Komma für spezifische Keys oder durch Punkt für 'ai' und 'st'
          const joinedValues = value.join(facetsOR.includes(key) ? "." : ",");
          collectedValues[key] = joinedValues;
        } else {
          // Einzelwerte direkt hinzufügen
          collectedValues[key] = value.toString();
        }
      });

      // Konvertiere gesammelte Werte in Query-Parameter
      Object.entries(collectedValues).forEach(([key, value]) => {
        queryParams[key] = `${key}=${value}`;
      });

      const queryString = Object.values(queryParams).join("&");
      const finalUrl = serverUrl + "?" + queryString;

      // console.log("URL", finalUrl);

      return finalUrl;
    },
    [serverUrl]
  );

  useEffect(() => {
    // Funktion zum Löschen von Eigenschaften mit Wert "alle"
    const removePropertiesWithValueAlle = (obj: ISelectData) => {
      Object.keys(obj).forEach((key) => {
        // Lösche Eigenschaften mit dem Wert "alle"
        if (obj[key] === "alle") {
          delete obj[key];
        }
        // Lösche Eigenschaften mit leerem String als Wert
        if (obj[key] === "") {
          delete obj[key];
        }
        // Überprüfe, ob der Wert ein Array ist und ob es leer ist
        if (Array.isArray(obj[key]) && obj[key].length === 0) {
          delete obj[key];
        }
      });
    };

    // Erstelle eine Kopie von searchObject, um es zu modifizieren
    const updatedSearchObject = { ...searchObject };

    // Entfernen von Eigenschaften mit Wert "alle" vor der Aktualisierung
    removePropertiesWithValueAlle(updatedSearchObject);

    // Generieren Sie die URL mit dem aktualisierten searchObject und führen Sie den API-Aufruf durch
    const searchObjectUrl = generateHTTPUrl(updatedSearchObject);
    const apiCall = async (searchObjectUrl: string) => {
      try {
        const response = await fetch(searchObjectUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const arrayBuffer = await response.arrayBuffer();
        const data: IData = JSON.parse(new TextDecoder("UTF-8").decode(arrayBuffer));

        // ToDo: Temporere facets für Bike angepasst
        if (data.facets) {
          //  data.facets.ty = { BIKE: [5, 3] };
          //    data.facets.tyA = { ...data.facets.tyA, BIKE: [5, 3] };
          // console.log("Facets", data.facets);
        }
        // Todo: BikeMockdata
        // zur Info MockDataFacets oder MockDataBikeNeu.facets oder data.facets from Backend
        // und MockDataBikeRanges.ranges oder MockDataBikeNeu.ranges oder data.ranges
        // und MockDataBikeRanges.rg oder MockDataBikeNeu.rg oder data.rg

        dispatch({ type: SET_FACETS_OBJECT, payload: data.facets });
        dispatch({ type: SET_RANGES_OBJECT, payload: data.ranges });
        dispatch({ type: SET_RG_OBJECT, payload: data.rg });
        dispatch({
          type: SET_SEARCHRESULTS_OBJECT,
          payload: data.searchResult,
        });
        setApiData(data);
        dispatch({ type: SET_SEARCH_OBJECT, payload: updatedSearchObject });
      } catch (error) {
        console.log(error);
      }
    };

    apiCall(searchObjectUrl);
  }, [dispatch, searchObject, generateHTTPUrl]);

  return apiData;
};
