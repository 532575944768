import React, { FC, useEffect, useState } from "react";
import { Grid, Tab, Tabs } from "@mui/material";
import Search from "./Search";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_TYPE, FILTER_VEHICLE_TYPE_ALL } from "@/constants/index";
import useStore from "@store";

const QuickSearchWithTabs: FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { facets } = useStore();
  const [value, setValue] = useState(0);
  const filterTypes: Record<string, number[]> = facets[FILTER_VEHICLE_TYPE_ALL];
  let filterTypeValue = 0;

  // Sortiere filterTypes und speichere das Ergebnis als State, um es in handleChange zugänglich zu machen
  const [sortedEntriesArray, setSortedEntriesArray] = useState(() => {
    if (filterTypes && Object.keys(filterTypes).length > 0) {
      const data = Object?.entries(filterTypes).sort((a, b) => a[1][0] - b[1][0]);
      return;
    } else {
      return [];
    }
  });

  useEffect(() => {
    if (filterTypes) {
      // Aktualisiere sortedEntriesArray, wenn filterTypes sich ändert
      if (Object?.keys(filterTypes).length > 0) {
        const sorted = Object.entries(filterTypes).sort((a, b) => a[1][0] - b[1][0]);
        setSortedEntriesArray(sorted);
      }
    }
  }, [filterTypes]);

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    setValue(tabIndex);
    // Verwende den tabIndex, um auf das entsprechende sortierte Element zuzugreifen
    const selectedEntry = sortedEntriesArray[tabIndex];
    // const filterTypeKey = selectedEntry[0]; // Schlüssel des ausgewählten Objekts
    filterTypeValue = selectedEntry[1][0]; // Wert des ausgewählten Objekts

    // Anpassen des searchObject basierend auf dem ausgewählten Tab
    searchParameters.setSearchObject((prevSelectData) => ({
      ...prevSelectData,
      [FILTER_VEHICLE_TYPE]: Array.isArray(filterTypeValue) ? filterTypeValue : [filterTypeValue]
    }));
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={2}
        pr={1}
        sx={{
          borderRight: { sm: 1 },
          borderColor: { sm: "divider" },
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} aria-label="Fahrzeugtyp Tabs">
          {sortedEntriesArray?.map(([key], index) => <Tab key={key} label={key} value={index} />)}
        </Tabs>
      </Grid>

      <Grid item xs={12} sm={10} pl={1}>
        <Search {...searchParameters} />
      </Grid>
    </Grid>
  );
};

export default QuickSearchWithTabs;
