import React from "react";
import { ISelectProps } from "@/interfaces/ISelectProps";
import { FormControl, MenuItem, Select } from "@mui/material";

export const SelectBoxElement = ({ placeholder, data, value, onChange, name, disabled }: ISelectProps) => {
  const handleChange = (event: any) => {
    onChange(event.target.value, name);
  };

  // sicherstellen, dass currentValue immer einen gültigen Wert hat
  const currentValue = Array.isArray(value) ? (value.length > 0 ? value[value.length - 1] : "alle") : value !== undefined ? value : "alle";

  return (
    <FormControl fullWidth>
      <Select variant="outlined" size="small" id={`${name}-select`} value={currentValue} onChange={handleChange} name={name} fullWidth>
        <MenuItem key={-1} value="alle">
          <em>alle</em>
        </MenuItem>
        {Object.entries(data).map(([key, [id, _]]) => (
          <MenuItem key={key} value={id}>
            {key}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
