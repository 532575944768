import React, { FC, useEffect, useState } from "react";

import { Box, Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { IEnquiryMessage } from "@Interface/IEnquiryMessage";
import Notification from "../../../components/Notification";
import { useSelector } from "react-redux";
import { ICustomerConfiguration } from "@Interface/ICustomerConfiguration";
import { RootState } from "../../../redux/store";
import { getBackendApi } from "../../../tools/backendApis";

const VehicleRequestForm: FC<{ data: any }> = ({ data }) => {
  const [branchId, setBranchId] = useState(0);
  const [vehicleId, setVehicleId] = useState(0);
  const [url, setUrl] = useState("");

  const [notificationType, setNotificationType] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");

  // TODO: aus der Konfiguration muss noch der Link zur Datenschutzseite kommen

  useEffect(() => {
    if (data.Branch && data.Branch.BranchId) {
      setBranchId(data.Branch.BranchId);
      setVehicleId(data.id);
      setUrl(getBackendApi("/contact"));
    }
  }, [data.Branch, data.id, setUrl, url]);

  const initialFormState = {
    anrede: "",
    name: "",
    telefonnummer: "",
    email: "",
    nachricht: data?.Contacts && data.Contacts[0] && data.mo && data.ma && data.na && data.on ? `Hallo${data.Contacts[0].Salutation ? ` ${data.Contacts[0].Salutation}` : ""} ${data.Contacts[0].LastName || ""}, \n\nich bin interessiert an Ihrem Angebot „${data.mo || ""} ${data.ma || ""} ${data.na || ""} (${data.on || ""})“. Bitte nehmen Sie Kontakt mit mir auf. \n\nViele Grüße\n` : "",
    kopieEmail: false,
    datenschutz: false,
  };

  const AnredeOptions = ["Herr", "Frau", "Firma"];

  const [formData, setFormData] = useState(initialFormState);
  const [emailError, setEmailError] = useState("");
  const [nachrichtError, setNachrichtError] = useState("");
  const [datenschutzError, setDatenschutzError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const customerConfiguration = useSelector((state: RootState) => state.ConfigurationReducer.customerConfiguration as ICustomerConfiguration);

  const validateEmail = (email: string) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return pattern.test(email);
  };

  const validateNachricht = (nachricht: string) => {
    return nachricht.trim() !== "";
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const target = event.target as HTMLInputElement; // Dies passt den Typ an, sodass wir auf `checked` zugreifen können
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // Aktualisiere den State basierend auf dem Input-Typ
    setFormData({
      ...formData,
      [name]: value,
    });

    // Führe spezifische Validierungen basierend auf dem Feldnamen durch
    if (name === "email") {
      setEmailError(validateEmail(value as string) ? "" : "Ungültige E-Mail-Adresse");
    }

    if (name === "nachricht") {
      setNachrichtError(validateNachricht(value as string) ? "" : "Bitte geben Sie eine Nachricht ein");
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const isFormValid = () => {
    return validateEmail(formData.email) && validateNachricht(formData.nachricht) && formData.datenschutz;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitting(true);

    if (isFormValid()) {
      const enquiryMessage: IEnquiryMessage = {
        EnquiryMessage: {
          Application: 5,
          BranchId: branchId,
          VehicleId: vehicleId,
          VehicleUrl: window.location.href,
          FromSalutation: formData.anrede,
          FromName: formData.name,
          FromEmail: formData.email,
          Comment: formData.nachricht,
          SendCopy: formData.kopieEmail,
          SendToName: "-",
        },
      };

      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(enquiryMessage),
        });

        if (!response.ok) {
          throw new Error(`Fehler beim Senden! Status: ${response.status}`);
        }

        setNotificationType("success");
        setNotificationMessage("Erfolgreiche Nachricht! Code: " + response.status);
      } catch (error) {
        console.error("Fehler beim Senden:", error);

        setNotificationType("error");
        setNotificationMessage("Fehler beim Senden! Error: " + error.message);
      }
    } else {
      setEmailError(validateEmail(formData.email) ? "" : "Ungültige E-Mail-Adresse");
      setNachrichtError(validateNachricht(formData.nachricht) ? "" : "Bitte geben Sie eine Nachricht ein");
      setDatenschutzError(formData.datenschutz ? "" : "Bitte akzeptieren Sie die Datenschutzerklärung");
    }
  };

  return (
    <Card sx={{ p: 1, borderWidth: "2px", borderColor: "primary.main", position: "relative", overflow: "visible" }}>
      <CardContent>
        <Box id="offer-number" sx={{ display: "flex", gap: 1, position: "absolute", top: "-10px", right: "5px", background: "white" }}>
          <Typography sx={{ fontWeight: "bold" }}>Angebotsnummer:</Typography>
          <Typography>{data.on ? data.on : "-"}</Typography>
        </Box>
        {/** 
        {data?.Contacts[0] && (
          <Grid item sm={12} mt={2}>
            <ContactPanel contact={data.Contacts[0]} carData={data} showDetails={false} />
          </Grid>
        )}
          */}
        <form onSubmit={handleSubmit}>
          <TextField variant="filled" fullWidth required name="nachricht" label="Ihre Nachricht" multiline rows={9} value={formData.nachricht} onChange={handleInputChange} sx={{ marginTop: 1 }} />
          <TextField variant="filled" fullWidth required name="email" label="E-Mail-Adresse" value={formData.email} onChange={handleInputChange} />
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="anrede">Anrede</InputLabel>
            <Select variant="filled" fullWidth name="anrede" value={formData.anrede} onChange={handleSelectChange} label="Anrede">
              {AnredeOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField variant="filled" fullWidth name="name" label="Name" value={formData.name} onChange={handleInputChange} />
          <TextField variant="filled" fullWidth name="telefonnummer" label="Telefonnummer" value={formData.telefonnummer} onChange={handleInputChange} />

          <FormHelperText error>{emailError}</FormHelperText>

          <FormHelperText error>{nachrichtError}</FormHelperText>

          <FormControlLabel
            name="datenschutz"
            control={<Checkbox color="primary" checked={formData.datenschutz} onChange={handleInputChange} />}
            label={
              <Typography>
                Ich habe den oben genannten Hinweis und die{" "}
                <Link href={`${customerConfiguration?.dsUrl}`} underline="none" target="_blank">
                  Datenschutzerklärung
                </Link>{" "}
                zur Kenntnis genommen und bin mit Ihrer Geltung einverstanden.*
              </Typography>
            }
            sx={{ mt: 2 }}
          />

          <FormControlLabel name="kopieEmail" control={<Checkbox checked={formData.kopieEmail} onChange={handleInputChange} />} label={<Typography>Ich möchte eine Kopie dieser E-Mail erhalten.</Typography>} sx={{ mt: 2 }} />

          <FormHelperText error>{datenschutzError}</FormHelperText>

          <Grid mt={2}>
            <Typography variant="subtitle2" component="div" align="right">
              * notwendig
            </Typography>
          </Grid>
          <Grid sx={{ display: "flex", flexDirection: "row" }}>
            <Button variant="contained" fullWidth type="submit" title="Fahrzeuganfrage stellen" disabled={!isFormValid() || isSubmitting}>
              <Typography variant="body1">Fahrzeuganfrage stellen</Typography>
            </Button>
          </Grid>
        </form>
        {notificationType && notificationMessage && <Notification type={notificationType} message={notificationMessage} />}
      </CardContent>
    </Card>
  );
};

export default VehicleRequestForm;
