import React, { useId } from "react";
import { handleChangeCheckBoxValue, setValue, convertDataToCheckBoxArray } from "@/tools/tools";
import { CheckboxElement } from "@/components/Forms/CheckboxElement";
import { Grid, useTheme } from "@mui/material";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import { FILTER_VEHICLE_PAYMENT_METHOD } from "@/constants";
import { ICheckboxProps } from "@/interfaces/ICheckboxProps";
import useStore from "@store";

const PaymentMethodComponentCheckbox: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const id = useId();
  const { facets } = useStore();

  const data = facets?.[FILTER_VEHICLE_PAYMENT_METHOD];
  const CheckBoxDataArray: ICheckboxProps[] = convertDataToCheckBoxArray(data, FILTER_VEHICLE_PAYMENT_METHOD);

  // die Spaltenanzahl basierend auf der Bildschirmgröße
  const columns = { xs: 12, sm: 6, md: 4, lg: 3, xl: 3 };
  const flexDirection = "row";

  return (
    <Grid container flexDirection={flexDirection}>
      {CheckBoxDataArray.map((checkBox, index) => (
        <Grid item {...columns} key={`${id}-${index}-${checkBox.name}`}>
          <CheckboxElement
            name={checkBox.name}
            checked={setValue(checkBox.label ?? "", checkBox.name ?? "", checkBox.data, searchParameters.searchObject)}
            onChange={(event) => {
              handleChangeCheckBoxValue(event, checkBox.label, checkBox.data, searchParameters.setSearchObject);
            }}
            type={checkBox.type}
            label={checkBox.label}
            data={checkBox.data}
            searchObject={searchParameters.searchObject}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PaymentMethodComponentCheckbox;
