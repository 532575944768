import React, { useContext, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Drawer, Grid, SelectChangeEvent, Typography, Snackbar, Alert, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import VehicleSmall from "./VehicleSmall/VehicleSmall";
import PaginationSortingContainer from "./PaginationSortingContainer";
import ScrollToTop from "@/components/ScrollToTop";
import FilterReset from "@/components/Filter/FilterReset";
import useContainerWidth from "@/hooks/useContainerWidth";
import { IVehicleListItem } from "@/interfaces/IVehicleListItem";
import { MenuContext } from "@/contexts/menuContext";
import VehicleMedium from "./VehicleMedium/VehicleMedium";
import VehicleLarge from "./VehicleLarge/VehicleLarge";
import { usePagination } from "@/contexts/paginationContext";
import { getGridSize1920 } from "@/tools/tools";
import { ISearchParametersProps } from "@/interfaces/ISearchParametersProps";
import MileageComponentRangeSlider from "@/FormComponents/RangeSlider/MileageComponentRangeSlider";
import PriceComponentRangeSlider from "@/FormComponents/RangeSlider/PriceComponentRangeSlider";
import PerformanceComponentRangeSlider from "@/FormComponents/RangeSlider/PerformanceComponentRangeSlider";
import VehicleTypeComponentCheckbox from "@/FormComponents/CheckBoxes/VehicleTypeComponentCheckbox";
import VehicleConditionComponentCheckbox from "@/FormComponents/CheckBoxes/VehicleConditionComponentCheckbox";
import ManufacturerComponentCheckbox from "@/FormComponents/CheckBoxes/ManufacturerComponentCheckbox";
import ModelComponentCheckbox from "@/FormComponents/CheckBoxes/ModelComponentCheckbox";
import BranchComponentSelect from "@/FormComponents/SelectBoxes/BranchComponentSelect";
import FuelComponentCheckbox from "@/FormComponents/CheckBoxes/FuelComponentCheckbox";
import EngineGearboxComponentCheckbox from "@/FormComponents/CheckBoxes/EngineGearboxComponentCheckbox";
import EquipmentComponentCheckbox from "@/FormComponents/CheckBoxes/EquipmentComponentCheckbox";
import BikeCategoryComponentCheckbox from "@/FormComponents/CheckBoxes/BikeCategoryComponentCheckbox";
import BikeWheelSizeComponentCheckbox from "@/FormComponents/CheckBoxes/BikeWheelSizeComponentCheckbox";
import BikeGearTypeComponentCheckbox from "@/FormComponents/CheckBoxes/BikeGearTypeComponentCheckbox";
import BikeBatteryManufacturerComponentCheckbox from "@/FormComponents/CheckBoxes/BikeBatteryManufacturerComponentCheckbox";
import BikeFrameMaterialComponentCheckbox from "@/FormComponents/CheckBoxes/BikeFrameMaterialComponentCheckbox";
import BikeFrameShapeComponentCheckbox from "@/FormComponents/CheckBoxes/BikeFrameShapeComponentCheckbox";
import BikeMotorPositionComponentCheckbox from "@/FormComponents/CheckBoxes/BikeMotorPositionComponentCheckbox";
import BikeWeightComponentRangeSlider from "@/FormComponents/RangeSlider/BikeWeightComponentRangeSlider";
import BikeNumberOfGearsComponentRangeSlider from "@/FormComponents/RangeSlider/BikeNumberOfGearsComponentRangeSlider";
import BikeBatteryCapacityWhComponentRangeSlider from "@/FormComponents/RangeSlider/BikeBatteryCapacityWhComponentRangeSlider";
import BikeBatteryPositionComponentCheckbox from "@/FormComponents/CheckBoxes/BikeBatteryPositionComponentCheckbox ";
import ConsumptionNote from "@/components/ConsumptionNote";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BikeFrameHeightComponentRangeSlider from "@/FormComponents/RangeSlider/BikeFrameHeightComponentRangeSlider";
import ExteriorColorComponentCheckbox from "@/FormComponents/CheckBoxes/ExteriorColorComponentCheckbox";
import MetallicComponentCheckbox from "@/FormComponents/CheckBoxes/MetallicComponentCheckbox";
import useStore from "@store";
import FilterCard from "@/components/Filter/FilterCard";
import { Helmet } from "react-helmet";
import { FILTER_VEHICLE_CONDITION, FILTER_VEHICLE_MANUFACTURER, FILTER_VEHICLE_MODEL, FILTER_VEHICLE_METALLIC, FILTER_VEHICLE_EXTERIR_COLOR, FILTER_VEHICLE_FUEL, FILTER_VEHICLE_GEARBOX } from "@/constants";

const VehicleList: React.FC<ISearchParametersProps> = ({ ...searchParameters }) => {
  const { isMenuOpen, toggleMenu, isSmallOpen, isViewOpen } = useContext(MenuContext);
  const [vehicles, setVehicles] = useState<IVehicleListItem[]>([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const navigate = useNavigate();

  const { customerConfiguration } = useStore();
  const { facets } = useStore();
  const { page, setPage, perPage, setPerPage } = usePagination();
  const { containerRef, width } = useContainerWidth();

  const location = useLocation();
  const state = location.state as { from?: string; reason?: string };
  const { t } = useTranslation();

  useEffect(() => {
    if (state?.reason === "not-found") {
      setOpenSnackbar(true);
    }
  }, [state]);

  const gridSize = getGridSize1920(width, {
    xl: 2,
    lg: 3,
    md: 4,
    sm: 6,
    xs: 12
  });

  useEffect(() => {
    if (searchParameters.vehicleObject?.cars && typeof searchParameters.vehicleObject.count === "number") {
      const tempVehicles: IVehicleListItem[] = [];
      const startIndex = (page - 1) * Number(perPage);
      const endIndex = Math.min(startIndex + Number(perPage), searchParameters.vehicleObject.cars.length);
      for (let index = startIndex; index < endIndex; index++) {
        const element = searchParameters.vehicleObject.cars[index];
        tempVehicles.push(element);
      }
      setVehicles(tempVehicles);
    } else {
      setVehicles([]);
    }
  }, [searchParameters.searchObject, page, perPage, searchParameters.vehicleObject.cars, searchParameters.vehicleObject.count]);

  function searchHasFilter(obj: { [key: string]: any }): boolean {
    const keys = Object.keys(obj);
    const filteredKeys = keys.filter((key) => key !== "sort" && key !== "sortd");
    return filteredKeys.length > 0;
  }

  useEffect(() => {
    setHasFilter(searchHasFilter(searchParameters?.searchObject));
  }, [searchParameters]);

  const handleChangePerPage = (event: SelectChangeEvent<string>) => {
    setPerPage(parseInt(event.target.value as string, 10));
    setPage(1); // Zurücksetzen auf Seite 1 bei Änderung der Anzahl pro Seite
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => {
    window.scrollTo(0, 0);
    setPage(newPage);
  };

  const titel = () => {
    if (searchParameters.vehicleObject && typeof searchParameters.vehicleObject.count === "number") {
      const count = searchParameters.vehicleObject.count;
      return (
        <span>
          <strong>{count}</strong> {count === 1 ? "Fahrzeug gefunden" : "Fahrzeuge gefunden"}
        </span>
      );
    } else {
      return <span>Keine Fahrzeuge gefunden</span>;
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    navigate(customerConfiguration.vehicleList);
  };

  const isVehicleChosen = facets && facets?.ty && Object.keys(facets).length > 1;
  let hasBike, hasPKW, hasLKW;
  if (isVehicleChosen) {
    const types = Object.keys(facets.ty);
    hasBike = types.some((typ) => typ.includes("e-Bike"));
    hasPKW = types.some((typ) => typ.includes("PKW"));
    hasLKW = types.some((typ) => typ.includes("LKW"));
  }

  const helmetCanonical = `${window.location.origin}${customerConfiguration.vehicleList}`;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={helmetCanonical} />
      </Helmet>

      <Container sx={{ maxWidth: customerConfiguration.width, p: 0 }}>
        {/* Snackbar oben auf der Seite */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "rgba(0,0,0,0.75)",
              color: "white",
              boxShadow: 3
            }
          }}
        >
          <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: "100%" }}>
            <Typography>Das gewünschte Fahrzeug wurde bereits verkauft, aber Sie können sich gerne unsere anderen Angebote anschauen.</Typography>
          </Alert>
        </Snackbar>

        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 1,
            justifyContent: "center"
          }}
        >
          <PaginationSortingContainer perPage={perPage} page={page} handleChangePerPage={handleChangePerPage} handleChangePage={handleChangePage} {...searchParameters} />
        </Grid>
        <Typography
          sx={{
            bgcolor: "primary.main",
            color: "white",
            p: 1.3,
            my: 1,
            textTransform: "uppercase"
          }}
        >
          {titel()}
        </Typography>

        <Box mb={1}>{hasFilter && <FilterReset {...searchParameters} />}</Box>
        <Grid container spacing={2}>
          {vehicles.length > 0 &&
            vehicles.map((vehicle) => (
              <React.Fragment key={vehicle.li}>
                {1 === isViewOpen && customerConfiguration.small.visible && <VehicleSmall vehicle={vehicle} />}
                {2 === isViewOpen && customerConfiguration.large.visible && (
                  <Grid item sx={{ m: 0.5 }} ref={containerRef} xl={!isSmallOpen && containerRef?.current?.offsetWidth > 1920 ? 6 : 12} lg={isSmallOpen ? gridSize : 12} md={isSmallOpen ? gridSize : 12} sm={isSmallOpen ? gridSize : 12} xs={12} display="flex">
                    <VehicleLarge vehicle={vehicle} />
                  </Grid>
                )}
                {3 === isViewOpen && customerConfiguration.medium.visible && (
                  <Grid item sx={{ m: 0.5 }} ref={containerRef} xl={!isSmallOpen && containerRef?.current?.offsetWidth > 1920 ? 6 : 12} lg={isSmallOpen ? gridSize : 12} md={isSmallOpen ? gridSize : 12} sm={isSmallOpen ? gridSize : 12} xs={12} display="flex">
                    <VehicleMedium vehicle={vehicle} customerConfiguration={customerConfiguration} />
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </Grid>
        <ScrollToTop />
        <Drawer
          PaperProps={{
            sx: {
              minWidth: "290px",
              maxWidth: "85%"
            }
          }}
          anchor="left"
          open={isMenuOpen}
          onClose={toggleMenu}
        >
          <Grid
            item
            sx={{
              margin: 2,
              display: "flex",
              flexDirection: "column",
              maxWidth: "320px",
              "& > *": {
                marginBottom: "1em"
              },
              "& > :last-child": {
                mb: 0
              }
            }}
          >
            <FilterCard title="Fahrzeugtyp" Component={VehicleTypeComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} allFilter={false} />
            {isVehicleChosen && (
              <>
                <FilterCard title="Filiale" Component={BranchComponentSelect} searchParameters={searchParameters} showToggleButton={false} allFilter={false} />
                <FilterCard title="Fahrzeugzustand" Component={VehicleConditionComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} facetName={FILTER_VEHICLE_CONDITION} />
                <FilterCard title="Hersteller" Component={ManufacturerComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} facetName={FILTER_VEHICLE_MANUFACTURER} />
                <FilterCard title="Modell" Component={ModelComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={facets?.mo && Object.keys(facets?.mo).length >= 1} facetName={FILTER_VEHICLE_MODEL} />
                <FilterCard title="Preis" Component={PriceComponentRangeSlider} searchParameters={searchParameters} allFilter={false} />
                <FilterCard title="Kilometerstand in [km]" Component={MileageComponentRangeSlider} searchParameters={searchParameters} allFilter={false} />
                <FilterCard title="Leistung" Component={PerformanceComponentRangeSlider} searchParameters={searchParameters} allFilter={false} />
                <FilterCard title="Kraftstoffart" Component={FuelComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} facetName={FILTER_VEHICLE_FUEL} />
                <FilterCard title="Getriebeart" Component={EngineGearboxComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} facetName={FILTER_VEHICLE_GEARBOX} />
                <FilterCard title="Ausstattungen" Component={EquipmentComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} allFilter={false} />
                <FilterCard title="Außenfarbe" Component={ExteriorColorComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} facetName={FILTER_VEHICLE_EXTERIR_COLOR} />
                <FilterCard title="Lackart" Component={MetallicComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} facetName={FILTER_VEHICLE_METALLIC} />

                <FilterCard title={t(`common:filters.category` as any)} Component={BikeCategoryComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
                <FilterCard title={t(`common:filters.weight` as any)} Component={BikeWeightComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />
                <FilterCard title={t(`common:filters.wheelSize` as any)} Component={BikeWheelSizeComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
                <FilterCard title={t(`common:filters.frameMaterial` as any)} Component={BikeFrameMaterialComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
                <FilterCard title={t(`common:filters.frameShape` as any)} Component={BikeFrameShapeComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
                <FilterCard title={t(`common:filters.frameHeight` as any) + " (cm)"} Component={BikeFrameHeightComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />
                <FilterCard title={t(`common:filters.gearType` as any)} Component={BikeGearTypeComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
                <FilterCard title={t(`common:filters.numberOfGears` as any)} Component={BikeNumberOfGearsComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />
                <FilterCard title={t(`common:filters.batteryManufacturer` as any)} Component={BikeBatteryManufacturerComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
                <FilterCard title={t(`common:filters.batteryCapacity` as any)} Component={BikeBatteryCapacityWhComponentRangeSlider} searchParameters={searchParameters} condition={hasBike} />
                <FilterCard title={t(`common:filters.batteryPosition` as any)} Component={BikeBatteryPositionComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />

                <FilterCard title={t(`common:filters.motorPosition` as any)} Component={BikeMotorPositionComponentCheckbox} searchParameters={searchParameters} showToggleButton={true} condition={hasBike} />
              </>
            )}
          </Grid>
        </Drawer>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 1,
            justifyContent: "center"
          }}
        >
          {vehicles.length > 0 && <PaginationSortingContainer perPage={perPage} page={page} handleChangePerPage={handleChangePerPage} handleChangePage={handleChangePage} reduced={true} {...searchParameters} />}
        </Grid>
        {vehicles.length > 0 && <ConsumptionNote />}
      </Container>
    </>
  );
};

export default VehicleList;
