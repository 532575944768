import React from "react";
import { Grid, Button } from "@mui/material";
import { IVehicleListItem } from "@Interface/IVehicleListItem";

interface Props {
  vehicle: IVehicleListItem;
}

const ItemButtonSection: React.FC<Props> = ({ vehicle }) => {
  return (
    <Grid container justifyContent={"end"}>
      {/* <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", mt: 5 }}> */}
      {/* 22.08.2024: Big Mike möchte diese Informationen raus haben. Gesetzliche Gründe, oder so */}
      {/* <MpiLabel title="Anbieter:" sx={{ mb: 0 }} />
        <Typography>
          {vehicle.c.n}
          {", "}
          {vehicle.c.s}
          {", "}
          {vehicle.c.o}
        </Typography> */}
      {/* </Grid> */}
      <Grid item sx={{ mb: 1 }} xs={12} md={4}>
        <Button variant="contained" fullWidth sx={{ height: "40px" }}>
          zum Angebot
        </Button>
      </Grid>
    </Grid>
  );
};

export default ItemButtonSection;
