/**************************************
 *
 * Wichtig: Kenntnisse zum Rechtlichen Thema der Darstellung (Stand: 22.08.2024)
 * Allgemeine Mindestschriftgröße: Noch unbekannt, aber vermutlich ist 12px save.
 * Mindestschriftgröße des CO2-Klasse Wert: Mindestens so groß wie die größte Schrift in dem Item.
 * Sätze, die sich vor allem auf (allgemeine) Werte beziehen, dürfen nicht als Fußnote ausgelagert werden.
 *
 * Änderung durch Big Mike: Die Darstellung soll präsenter sein
 *
 **************************************/

import React from "react";
import { Card, CardContent, CardMedia, Grid, Typography, Box } from "@mui/material";
import { IConsumtion } from "@/interfaces/IVehicleDetailed";
import ConsumptionProperty from "./ConsumptionProperty";
import VehicleConsumption from "./VehicleConsumption";
import ConsumptionNote from "@/components/ConsumptionNote";
import ButtonPKWLabel from "@/components/Buttons/ButtonPKWLabel";

// Es wird im Detailsansicht rechts an der Seite Umwelt & Normen* verwendet

const Consumption: React.FC<{
  carData: any;
  inDetailView?: boolean;
  size?: string;
}> = ({ carData, inDetailView = false, size = "big" }) => {
  const data: IConsumtion = carData.en?.co || carData.engine?.consumtion;

  let imageLink = "";

  if (data?.clk) {
    imageLink = `https://mpi.carzilla.de/CO2Class.jpg?class=${data?.clk}`;
  }
  if (data?.clgk && data?.clB === undefined) {
    imageLink = `https://mpi.carzilla.de/CO2Class.jpg?class=${data?.clgk}`;
  }
  if (data?.clB && data?.clk === undefined) {
    imageLink = `https://mpi.carzilla.de/CO2Class.jpg?class=${data?.clB}`;
  }
  if (data?.clgk && data?.clB) {
    imageLink = `https://mpi.carzilla.de/CO2Class.jpg?class=${data?.clgk}${data?.clB}`;
  }

  const containerSize = size === "small" ? "600px" : "auto";

  const gridSetting = {
    xs: size === "small" ? 12 : 12,
    px: size === "small" ? 0 : 2,
    alignContent: "center"
  };

  const sxTitle = {
    maxWidth: {
      xs: size === "small" ? "100%" : "100%",
      md: size === "small" ? "310px" : "auto"
    }
  };

  return (
    <Card sx={{ width: "100%", py: 2, height: "100%" }}>
      <CardContent sx={{ py: "0 !important", px: 1 }}>
        <Grid container width={containerSize} maxWidth={"100%"} justifyContent="center">
          <Grid container sx={{ px: 0 }}>
            <Grid id="ConsumptionMain" container item {...gridSetting} md={size === "small" ? 12 : inDetailView ? 8 : 9} alignContent="center">
              {data?.vk?.c && <ConsumptionProperty title="Energieverbrauch (kombiniert)¹" property={data?.vk?.c} sxTitle={sxTitle} />}
              {data?.vsE?.c && <ConsumptionProperty title="Energieverbrauch (kombiniert)" property={data?.vsE?.c} sxTitle={sxTitle} />}
              {data?.vk?.wc && data?.vsH?.wc && <ConsumptionProperty title="Energieverbrauch (gewichtet, kombiniert)" property={`${data?.vsH?.wc} plus ${data?.vk?.wc}`} sxTitle={sxTitle} />}
              {data?.co2k && <ConsumptionProperty title="CO2-Emissionen (kombiniert)" property={data?.co2k} sxTitle={sxTitle} />}
              {data?.co2gk && <ConsumptionProperty title="CO2-Emissionen (gewichtet, kombiniert)" property={data?.co2gk} sxTitle={sxTitle} />}
              {data?.co2B && <ConsumptionProperty title="CO2-Emissionen (bei entladener Baterie)" property={data?.co2B} sxTitle={sxTitle} />}
              {data?.r && <ConsumptionProperty title="Elektrische Reichweite" property={data?.r} sxTitle={sxTitle} />}
              {data?.re && <ConsumptionProperty title="Elektrische Reichweite (EAER)" property={data?.re} sxTitle={sxTitle} />}
              {data?.clk && <ConsumptionProperty title="CO2-Klasse (kombiniert)" property={data?.clk} sxTitle={sxTitle} sxProperty={{ fontWeight: "bold" }} valueFontVariant="h1" />}
              {data?.clgk && <ConsumptionProperty title="CO2-Klasse (gewichtet, kombiniert)" property={data?.clgk} sxTitle={sxTitle} />}
              {data?.clB && <ConsumptionProperty title="CO2-Klasse (bei entladener Batterie)" property={data?.clB} sxTitle={sxTitle} />}
            </Grid>

            {inDetailView && (
              <Grid item xs={12} sm={inDetailView ? 4 : 3}>
                <CardMedia component="img" sx={{ p: 1, width: "100%", margin: "auto" }} image={imageLink} />
                {carData?.mm?.expose && (
                  <Box px={1}>
                    <ButtonPKWLabel carData={carData} />
                  </Box>
                )}
              </Grid>
            )}

            <Grid id="ConsumptionOthers" container item {...gridSetting} md={size === "small" ? 12 : 3}>
              {/* Kraftstoffverbrauch vk  */}
              {data?.vk?.c && data?.vk?.h && data?.vk?.i && data?.vk?.p && data?.vk?.r && <VehicleConsumption data={data?.vk} title={"Kraftstoffverbrauch"} />}
              {/* Stromverbrauch Hybrid: vsH  */}
              {data?.vsH?.c && data?.vsH?.h && data?.vsH?.i && data?.vsH?.p && data?.vsH?.r && <VehicleConsumption data={data?.vsH} title={"Stromverbrauch bei rein elektrischem Betrieb"} />}
              {/* Stromverbrauch Elektro: vsE  */}
              {data?.vsE?.c && data?.vsE?.h && data?.vsE?.i && data?.vsE?.p && data?.vsE?.r && <VehicleConsumption data={data?.vsE} title={"Stromverbrauch"} />}
              {/* Stromverbrauch Hybrid: vsH  */}
              {data?.vb?.c && data?.vb?.h && data?.vb?.i && data?.vb?.p && data?.vb?.r && <VehicleConsumption data={data?.vb} title={"Kraftstoffverbrauch bei entladener Batterie"} />}
            </Grid>

            <Grid id="ConsumptionCosts" container item {...gridSetting}>
              <Grid item>
                {data?.ec && <ConsumptionProperty title="Energiekosten bei 15.000 km Jahresfahrleistung" property={data?.ec} sxTitle={sxTitle} />}
                {data?.fp && <ConsumptionProperty title={`Kraftstoffpreis (Jahresdurchschnitt ${data?.cy})`} property={data?.fp} sxTitle={sxTitle} />}
              </Grid>
              {data?.fp && data?.ep && (
                <Grid item>
                  <Typography variant="h5" component="div">
                    (Kraftstoffpreis: {data.fp} (Jahresdurchschnitt {data?.cy}))
                  </Typography>
                </Grid>
              )}
              {data?.fp === undefined && data?.ep && (
                <Grid item xs={12}>
                  <Typography variant="h5" component="div">
                    (Strompreis: {data?.ep} (Jahresdurchschnitt {data?.cy}))
                  </Typography>
                </Grid>
              )}
              {data?.c1 && (
                <Grid item container direction="row" justifyContent="space-between" sx={{ mt: 3, mb: 1 }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="div">
                      Mögliche CO2-Kosten über die nächsten 10 Jahre (15.000 km/Jahr):
                    </Typography>
                    {data?.c0?.m && <ConsumptionProperty title={`bei einem angenommenen mittleren durchschnittlichen CO2-Preis von ${data?.c0?.m}`} property={data?.c1?.m} sxTitle={sxTitle} />}
                    {data?.c0?.l && <ConsumptionProperty title={`bei einem angenommenen niedrigen durchschnittlichen CO2-Preis von ${data?.c0?.l}`} property={data?.c1?.l} sxTitle={sxTitle} />}
                    {data?.c0?.h && <ConsumptionProperty title={`bei einem angenommenen hohen durchschnittlichen CO2-Preis von ${data?.c0?.h}`} property={data?.c1?.h} sxTitle={sxTitle} />}
                  </Grid>
                </Grid>
              )}

              {data?.yt && (
                <Grid item xs={12} sx={{ mt: 1 }}>
                  {data?.yt && <ConsumptionProperty title="Kraftfahrzeugsteuer" property={data?.yt} sxTitle={sxTitle} />}
                </Grid>
              )}
              {data?.yt1 && (
                <Grid item xs={12} sx={{ mt: 1 }}>
                  {data?.yt1 && <ConsumptionProperty title="Kraftfahrzeugsteuer" property={data?.yt1} sxTitle={sxTitle} />}
                </Grid>
              )}
            </Grid>
          </Grid>

          {inDetailView && <ConsumptionNote />}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Consumption;
